import React, { useEffect, useRef } from 'react'



export default function RegisterImage(valid) {


    const btnref = useRef();
    const cardref = useRef();
    
    
  useEffect(()=>{
         btnref.current.style.display ='block'
  },[valid])

 
  return {
    cardref,btnref,
  }
}

