import React from 'react';
import { useLocation } from 'react-router-dom';
import Img from '../../assets/Home/logo.png';
import Layout from '../Layout/Layout';

const ShowComment = () => {
	const location = useLocation();
	console.log('Location', location);
	const myComment = location?.state?.comment;

	return (
		<Layout>
			<div className='container text-center pt-5 '>
				<div className=' justify-content-center align-items-center mt-5'>
					<img src={Img} alt='image ' className='mt-5' />
					{/* <div className='mt-5'></div> */}
					<h5 className='mt-5'>اسم الطالب: {myComment?.student_name}</h5>
				</div>
				<h3 className='my-5'>التعليق: {myComment?.comment}</h3>
			</div>
		</Layout>
	);
};

export default ShowComment;
