

import axios from "axios";

export default async function  fetchUnActiveStudents ({queryKey})
{
        // check if the type of the tables is selected then search
        if(queryKey[3] == 2){
        //   console.log("process",process.env.REACT_APP_API_URL)
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_un_active_students?page=${ Number(queryKey[1])+1}&q=${queryKey[2]}` ,
          {params :{limit:30}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );
   
          return  data;
        }
} 
