import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import Layout from '../Layout/Layout';
import Pagination from '../Pagination/Pagination';
import StudentSubscribtionLogic from './StudentSubscribtionLogic';
import TableStudenSubscribtion from '../ConfirmRequest/TableStudenSubscribtion';
import fetchStudentSubscribtion from '../../redux-toolkit/api/fetchStudentSubscribtion';

import { QueryClient } from 'react-query';
import axios from 'axios';

const StudentSubscribtion = () => {
	const params = useParams();
	const id = params?.id;

	console.log('YESSSSSSSSSS');
	const {
		listRef,
		isLoading,
		isError,
		data,
		error,
		handlePageClick,
		page,
		setPage,
	} = StudentSubscribtionLogic(id);
	console.log('sections Data', data);
	console.log('current page', page);
	const dataSections = data?.data;
	const [status, setStatus] = useState(0);
	const [ErrorMessage, setErrorMessage] = useState();

	const [searchResult, setSearchResult] = useState('');
	const [selectedStudent, setSelectedStudent] = useState('');
	const inputSearchRef = useRef();

	const unOrderStudentsList = useRef();

	const [stuentId, setStduentId] = useState();

	const queryClient = new QueryClient();

	// const prefetchTodos = async () => {
	//   // The results of this query will be cached like a normal query
	//   await queryClient.prefetchQuery(['studentSubscrition' , page,id], fetchStudentSubscribtion)
	// }

	const handleSelectStudentFromDrobDownList = (student) => {
		// setValue('student_id',student.student_id);
		setStduentId(student.student_id);
		console.log('student which was selected by user => ', student);
		unOrderStudentsList.current.style.height = '0';
		inputSearchRef.current.value = student.name;
	};

	const handleSearchStudent = (e) => {
		unOrderStudentsList.current.style.height = 'auto';

		handleUnorderStudentsList(e.target.value);
		// setValue('student_id',e.target.value);

		axios
			.get(
				`${process.env.REACT_APP_API_URL}/students_search?q=${e.target.value}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((response) => {
				console.log('search response', response.data.data);
				setSearchResult(response.data.data);
			})
			.catch((error) => {
				console.log('error while fetching search students', error);
			});
	};
	const handleUnorderStudentsList = (searchQuery) => {
		if (searchQuery == '') {
			unOrderStudentsList.current.style.height = '0px';
		} else {
			unOrderStudentsList.current.style.height = 'auto';
		}
	};

	const handleAddStudent = (e) => {
		e.preventDefault();
		let formData = new FormData();
		// setImage(data.image[0]);
		formData.append('student_id', stuentId);
		formData.append('subscription_id', id);
		//  formData.append("subscription_id" ,id);
		const res = axios
			.post(
				`${process.env.REACT_APP_API_URL}/post_subscription_for_student`,
				formData,
				{
					headers: {
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data from add student', data);
					window.location.reload();
					//  prefetchTodos()
					//    SetValid(true);
					return res;
				} else {
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response.data.message);
				setErrorMessage(error.response.data.message);
			});
	};

	return (
		<>
			<Layout>
				<div className='container all'>
					{ErrorMessage && (
						<div
							className='alert alert-danger'
							dangerouslySetInnerHTML={{ __html: ErrorMessage }}
						/>
					)}

					<h1
						className='pt-0 mb-4 pb-4 pt-3'
						style={
							{
								// borderBottom: '1px solid green'
							}
						}>
						الطلاب
					</h1>
					<input
						type='text'
						ref={inputSearchRef}
						placeholder='اكتب هنا للبحث عن طالب'
						onChange={(e) => handleSearchStudent(e)}
						className='shadow w-100 main-input border-0'
					/>

					<div
						className='students_select mb-5'
						style={{ background: 'transparent  !important' }}>
						<ul className='unorder_students_list' ref={unOrderStudentsList}>
							{searchResult &&
								searchResult?.map((student, index) => {
									return (
										<li
											onClick={() =>
												handleSelectStudentFromDrobDownList(student)
											}
											value={student.student_id}
											key={index}>
											{student.name}
										</li>
									);
								})}
						</ul>

						{stuentId && (
							<div className='text-center my-5'>
								<button className='btn btn1' onClick={handleAddStudent}>
									اضف طالب
								</button>
							</div>
						)}
					</div>
					<div className='d-flex justify-content-around mb-5'></div>

					{
						<TableStudenSubscribtion
							data={dataSections?.data}
							isLoading={isLoading}
							page={page}
							setPage={setPage}
							setStatus={setStatus}
						/>
					}

					{/* <input type='number' {...register("student_id", { required: true })} className='shadow w-100 main-input border-0'/> */}

					{/* <select type='text' {...register("student_id", { required: true })} ref={studentsSelect}  className='shadow w-100 p-2 border-0'> */}

					{data?.total < 10 ? (
						<> </>
					) : (
						<Pagination
							data={data?.data}
							handlePageClick={handlePageClick}
							page={page}
						/>
					)}
				</div>
			</Layout>
		</>
	);
};

export default StudentSubscribtion;
