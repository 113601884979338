import React, { useEffect, useState } from 'react';
import './RegisterImage.css';
import RegisterImageLogic from './RegisterImageLogic';

export default function RegisterImage({
	valid,
	subscribtionPage,
	register,
	id,
	registerWhat,
	isUploadSuccessfuly,
	imageSource,
	isRequired = true,
	guardianImage,
	studentImageSource,
	teacherImage,
	Accept = undefined,
}) {
	const { cardref, btnref, onImageChange } = RegisterImageLogic(valid);

	const [link, setLink] = useState(null);

	const paramsId = id || 0;

	const [imgLink, setImgLink] = useState(null);

	useEffect(() => {
		if (paramsId) {
			setImgLink(subscribtionPage?.data?.subscription_image);
		}
	}, [subscribtionPage]);

	useEffect(() => {
		if (isUploadSuccessfuly) {
			setLink(null);
		} else {
			return;
		}
	}, [isUploadSuccessfuly]);

	let img = null;
	if (paramsId) {
		img = `$${process.env.REACT_APP_Video_URL + imgLink}`;
	}

	const handleImage = (e) => {
		const [file] = e.target.files;
		setLink(URL.createObjectURL(file));
		console.log('image url from device', URL.createObjectURL(file));
	};

	return (
		<>
			<div
				className='bg-white    shadow  mb-4 d-flex finals justify-content-center parent_btn'
				ref={cardref}>
				{!Accept && (
					<img
						src={
							link ||
							img ||
							imageSource ||
							guardianImage ||
							studentImageSource ||
							teacherImage
						}
						style={{ width: '100%', height: '100%', margin: 'auto' }}
					/>
				)}

				<div className='button m-auto add-div btn_child' ref={btnref}>
					<i class='fa fa-plus '></i>
					<div>
						<input
							type='file'
							className='ool'
							accept={Accept ? Accept : 'image/*'}
							{...register(`${registerWhat}`, { required: isRequired })}
							onChange={handleImage}
						/>
					</div>
				</div>
			</div>

			{Accept && link && <iframe src={link} height='400' width='100%'></iframe>}
		</>
	);
}
