import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styleTable.css';
import right from '../../assets/students/right.png';
import wrong from '../../assets/students/wrong.jpg';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

export default function ConfirmRequest({ data, isLoading }) {
	const navigate = useNavigate();
	const [currentTeachers, setCurrentTeachers] = useState([]);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [selectedStudentId, setSelectedStudent] = useState();
	const modalRef = useRef(null);
	const closeModalBtn = useRef();
	const [Isloading, setIsLoading] = useState(false);

	useEffect(() => {
		console.log('data from react query', data?.data);
		setCurrentTeachers(data?.data);
	}, [data]);

	const handleLoginAsStudent = (event, student) => {
		event.stopPropagation();
		setIsLoading(true);

		const dataToSend = {
			id: student.student_id,
		};

		console.log('student is', student);
		console.log('data to send is', dataToSend);

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/login_with_student_id`,
				dataToSend,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('token is data ', data?.data.data.token);

				setSuccessMessage('تمت العملية بنجاح .. سيتم توجيهك في غضون ثوان');

				setTimeout(() => {
					window.open(
						`https://mid-east.icrcompany.net/loginasstudent/${data?.data.data.token}/${student.is_active}`
					);
				}, 1500);
			})
			.catch((error) => {
				console.log('error login as teacher', error);
				setErrorMessage('حدثت مشكلة ما .. يرجى المحاولة مرة اخرى');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const deActiveStudentAccount = (event, studentId) => {
		event.stopPropagation();

		setIsLoading(true);

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/de_active_student_account_by_id/${studentId}`,
				{},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				// console.log('token is data ' , data?.data.data.token)

				setSuccessMessage('تم إلغاء تفعيل الحساب');

				setTimeout(() => {
					window.location.reload();
				}, 2000);
				// window.open(`https://myway.edu.sy/loginasteacher/${data?.data.data.token}`);
			})
			.catch((error) => {
				// console.log('error login as teacher', error);
				setErrorMessage('حدثت مشكلة ما .. يرجى المحاولة مرة اخرى');
			})
			.finally(() => {
				// modalRef.current.style.display = 'none';
				// closeModalBtn.current.click();
				setIsLoading(false);
			});
	};

	const handleselectStudent = (event, studentId) => {
		event.stopPropagation();
		setSelectedStudent(studentId);
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			<table
				className='table border mt-3 teacherTable table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='text-white fw-bold'>
						<th className='text-center text-white firdt_child'> الاسم</th>
						<th className='text-center text-white'> اسم المستخدم</th>
						<th className='text-center text-white'> النقاط</th>
						{/* <th className='text-center text-white'>المدرسة</th>
						<th className='text-center text-white'> الصف</th> */}
						<th className='text-center text-white'> الايميل</th>
						<th className='text-center text-white'> حالة الحساب</th>
						<th className='text-center text-white last_child'>
							{' '}
							العمليات المتاحة
						</th>
					</tr>
				</thead>

				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={8}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : (
						currentTeachers &&
						currentTeachers.map((student, index) => {
							return (
								<tr
									key={index}
									style={{
										height: '150px',

										cursor: 'pointer',
										borderBottom: '1px solid #1a1a1a1a',
									}}
									className='tableRowHover'>
									<td
										onClick={() => {
											navigate(`/student/${student.student_id}`);
										}}
										className='text-center pt-4'>
										{student.name}
									</td>
									<td
										onClick={() => {
											navigate(`/student/${student.student_id}`);
										}}
										className='text-center pt-4'>
										{student.username}
									</td>
									<td
										onClick={() => {
											navigate(`/student/${student.student_id}`);
										}}
										className='text-center pt-4'>
										{student.points}
									</td>
									{/* <td
										onClick={() => {
											navigate(`/student/${student.student_id}`);
										}}
										className='text-center pt-4'>
										{student.school}
									</td>
									<td
										onClick={() => {
											navigate(`/student/${student.student_id}`);
										}}
										className='text-center pt-4'>
										{student.class}
									</td> */}
									<td
										onClick={() => {
											navigate(`/student/${student.student_id}`);
										}}
										className='text-center pt-4'>
										{student.email}
									</td>
									{/* <td  className='text-center pt-4'>{student.phone_number}</td>    */}
									<td
										onClick={() => {
											navigate(`/student/${student.student_id}`);
										}}
										className='text-center pt-4 activatOrNot'>
										{student.is_active == 0 ? (
											<img width={'40'} height='40px' src={wrong} />
										) : (
											<img width={'40'} height='40px' src={right} />
										)}
									</td>

									<td className='text-center  text-success fs-5 '>
										<button
											className='btn btn1 shadow'
											onClick={() => {
												navigate(`/SubjectStudent/${student.student_id}`);
											}}>
											عرض المواد
										</button>

										<button
											className='btn btn-secondary shadow'
											onClick={() => {
												navigate(`/editstudentdetails/${student.student_id}`);
											}}>
											تعديل معلومات الطالب
										</button>

										<div className='d-flex flex-column' style={{ gap: '8px' }}>
											<button
												className='btn btn-success shadow'
												disabled={Isloading}
												onClick={(e) => handleLoginAsStudent(e, student)}>
												تسجيل الدخول بحساب الطالب
											</button>

											{student.is_active != 0 ? (
												<button
													type='button'
													className='btn btn2 shadow'
													data-bs-toggle='modal'
													data-bs-target='#exampleModal'
													onClick={(e) =>
														handleselectStudent(e, student?.student_id)
													}>
													إلغاء تفعيل الحساب
												</button>
											) : null}
										</div>
									</td>
								</tr>
							);
						})
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				id='exampleModal'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'
				ref={modalRef}>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title mx-auto' id='exampleModalLabel'>
								{Isloading ? 'جاري إلغاء تفعيل الحساب ...' : 'هل أنت متأكد ؟'}
							</h5>
						</div>

						{Isloading && (
							<div className='modal-body'>
								{Isloading && (
									<div className='mb-3 d-flex justify-content-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='visually-hidden'>Loading...</span>
										</div>
									</div>
								)}
							</div>
						)}

						{!Isloading && (
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									className='btn btn-secondary'
									data-bs-dismiss='modal'
									ref={closeModalBtn}>
									تجاهل
								</button>

								<button
									type='button'
									className='btn btn2'
									onClick={(e) => deActiveStudentAccount(e, selectedStudentId)}>
									إلغاء التفعيل
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
