//just subjectsssss

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function CommentsSubjects({ data, isLoading, subjectId }) {
	const [dataSubject, setDataSubject] = useState(data);
	const [flag, setFlag] = useState(0);
	const [itemDelete, setItem] = useState();
	const [idSubjectDelete, setIdSubject] = useState();

	const navigate = useNavigate();

	//  if(dataSubject.length===0 && flag===1){
	//     window.location.reload();
	// }

	const handleDeleteSubject = (commentId, subjectId) => {
		console.log('idddddd subject', commentId);
		const temp = [...dataSubject];
		const DataDelte = temp.filter((item) => item.id !== commentId.id);
		console.log('Data Deleteeed', DataDelte);
		setDataSubject(DataDelte);
		setFlag(1);
	};

	useEffect(() => {
		console.log('data from react query for Subjectssss', data);
		setDataSubject(data);
	}, [data]);

	async function deletePost(item, subjectId) {
		console.log('iddddd', item);

		const res = axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_subject_comment_by_subject_comment_id/${item.id}`,
				{
					headers: {
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					// data:{
					//   "comment":item.comment,
					//   "subject_id":subjectId,
					//   "student_id":1,

					// }
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data delete Subscribtion', data);
					handleDeleteSubject(item, subjectId);
					alert('تم الحذف بنجاح');
					window.location.reload();
					return res;
				} else {
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	}
	// setStatus('Delete successful');
	const handleDelete = (item, subjectId) => {
		// DELETE request using fetch with async/await
		console.log('no id', item);
		setItem(item);
		setIdSubject(subjectId);
	};

	const handleDeleteItem = () => {
		deletePost(itemDelete, idSubjectDelete);
	};

	const AddComments = (id) => {
		navigate('/addComments', { state: { subjectid: id } });
	};

	const handleEdit = (item, id) => {
		navigate(`/addComments/${id}`, { state: { comment: item } });
	};

	const handleShow = (item) => {
		navigate('/showComment', { state: { comment: item } });
	};

	return (
		<>
			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> التعليق</th>
						<th className='text-center text-white last_child'> العمليات</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={2}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : dataSubject ? (
						dataSubject.length > 0 &&
						dataSubject?.map((item, index) => {
							return (
								<tr key={index}>
									<td className='text-center'>{item?.comment} </td>
									<td className='text-center'>
										{/* <i className='fa fa-check yes fa-2x'> </i>  */}
										<button
											onClick={() => handleShow(item)}
											className='ms-3 btn  btn1'>
											عرض{' '}
										</button>
										<button
											onClick={() => handleEdit(item, subjectId)}
											className='ms-3 btn btn-warning'>
											تعديل{' '}
										</button>
										<button
											onClick={() => handleDelete(item, subjectId)}
											className='ms-3 btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#staticBackdrop'>
											حذف
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={2} className='text-center py-5 '>
							<div>
								<h5>لايوجد تعليقات</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>

			<div className='text-center my-5'>
				{/* <Link to={`/addComments/${subjectId}`}> */}
				<button className='btn  btn1' onClick={() => AddComments(subjectId)}>
					أضف{' '}
				</button>
				{/* </Link> */}
			</div>

			<div
				className='modal fade'
				id='staticBackdrop'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
				tabindex='-1'
				aria-labelledby='staticBackdropLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div class='modal-header'>
							<h5 className='modal-title' id='staticBackdropLabel'></h5>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div className='modal-body'>هل انت متاكد انك تريد الحذف</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn  btn1'
								data-bs-dismiss='modal'>
								لا
							</button>
							<button
								type='button'
								className='btn  btn2'
								data-bs-dismiss='modal'
								onClick={handleDeleteItem}>
								نعم
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
