

import axios from "axios";
export default async function  fetchSubSectionsApi ({queryKey})
{
    console.log("axiosssssssssdfsdfsf");
    console.log("process",process.env.REACT_APP_API_URL)
    console.log("id" , queryKey[2]);
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${queryKey[2]}?page=${queryKey[1]}` ,
    {params :{limit:5}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
    );

          return  data;
} 


