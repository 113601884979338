import React from 'react'
import { toast } from "react-toastify";
const ToastSuccessError = ({ successMessage , ErrorMessage  ,   setSuccessMessage ,  setErrorMessage}) => {

   console.log("SuccessMeaage",successMessage);
   console.log("ErrorMeaage",ErrorMessage);
    const showSuccess = ()=>{
        toast.success(successMessage, {
          position: "top-right",
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
         
        });
        setSuccessMessage('')
      }
      
      const showError = ()=>{
        toast.error(ErrorMessage, {
          position: "top-right",
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setErrorMessage('')
      }
  return (
    <div>
       {successMessage.length>0 && showSuccess()}
       {ErrorMessage.length>0 && showError()}
    </div>
  )
}

export default ToastSuccessError