
import axios from "axios";

export default async function  fetchLibrarySections({queryKey,id})
{
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_books_by_website_library_section_id/${queryKey[2]}?page=${queryKey[1]}` ,
          {params :{limit:10}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

          return  data;
} 


