import { useState , useEffect } from "react";
import { useParams } from "react-router-dom";


let number = [1]
export default function SubscribeLogic()
{
    
    const [num,setNum] =useState(1);
  
        const handleAdd = ()=>{
            number.push(1)
            setNum(num+1);
            console.log(number)
            
        }


        const params = useParams();
        const id = params?.id
         
        
        const [subscribtionPage,setsubscribtionPage] = useState()
        
          useEffect(() => {
            async function getData() {
              const actualData = await fetch(
              `${process.env.REACT_APP_API_URL}/get_subscription_by_id/${id}`
              ).then(response => response.json());
              setsubscribtionPage(actualData);
              console.log("actual subscribtion Data with iDddddddddddsf",actualData) 
            }
            getData()
          }, [])
    return{
        handleAdd,number,subscribtionPage,id,
    }
}