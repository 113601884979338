import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout from '../../components/Layout/Layout';

import profile from '../../assets/signinPage/profile.png';
import email from '../../assets/signinPage/email.png';
import phone from '../../assets/signinPage/phone-outgoing.png';
import lock from '../../assets/signinPage/Layer 2.png';
import SignupLogic from './SignupLogic';

import './style.css';
import axios from 'axios';
import ToastError from '../../components/ToastSuccessError/ToastError';
export default function Signup({ text }) {
	//***************************************************************************** */

	// const [number, setNumber] = useState();

	// const [Loading, setLoading] = useState(false);
	// const [isAnyLanguage, setIsAnyLanguage] = useState();
	// const [verification, setVerification] = useState(false);
	// const [veryficationValue, setVeryficationValue] = useState();
	// const [randomNumber, setRandomNumber] = useState();
	// const [value, setValue] = React.useState(null);
	// const [sectiones, setsectiones] = useState([]);
	// const [checedSectiones, setchecedSectiones] = useState([]);
	//***************************************************************************** */

	const {
		register,
		handleSubmit,
		errors,
		isDirty,
		isValid,
		loading,
		onSubmit,
		watch,
		errorMessage,
		setErrorMessage,
		successMessage,
		setSuccessMessage,
	} = SignupLogic();
	const [countries, setcountries] = useState([]);

	// const signUp = () => {
	// 	axios.post(`${process.env.REACT_APP_API_URL}/signup`, {
	// 		// formdata,
	// 	});
	// };
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_countries`)
			.then((res) => {
				console.log('countries is ', res.data.data);
				setcountries(res.data.data);
			});
	}, []);

	// const renderSignButtton = Loading ? (
	// 	<div className='spinner-grow text primary' role='status'>
	// 		<span className='visually-hidden'>Loading...</span>
	// 	</div>
	// ) : (
	// 	<button
	// 		className='login-button m-auto w-50 rounded text-white fw-bold py-2 my-3'
	// 		type='submit'>
	// 		تاكيد انشاء الحساب
	// 	</button>
	// );
	//***************************************************************************** */

	// function generateRandomNumber() {
	// 	var minm = 100000;
	// 	var maxm = 999999;
	// 	return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
	// }

	// const handeVeryfication = async (e) => {
	// 	const number = localStorage.getItem('number');

	// 	console.log('numberrrr', number);
	// 	console.log('numberrrr[0]', number[0]);

	// 	const mobileNumber = number.slice(1, number.length);
	// 	console.log('mobileNumber', mobileNumber);

	// 	e.preventDefault();
	// 	let output = generateRandomNumber();
	// 	console.log('outputttt', output);
	// 	setRandomNumber(output);
	// 	console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbb');
	// 	const actualData = await fetch(
	// 		`https://bms.syriatel.sy/API/SendSMS.aspx?user_name=MAYWAYEDU1&password=P@123456&msg=${output}&sender=MyWayEdu&to=963${mobileNumber}`,
	// 		{
	// 			headers: {
	// 				Accept: 'application/json',
	// 			},
	// 		}
	// 	).then((response) => response.json());

	// 	console.log('outputt', output);
	// 	console.log('dataaaaaaaaaa', actualData.status);
	// 	if (actualData.status == 200) {
	// 		setRandomNumber(output);
	// 		console.log('yesss');
	// 	}
	// };

	// const checkTheCode = (e) => {
	// 	e.preventDefault();
	// 	console.log('random number', randomNumber);
	// 	console.log('verivacation', veryficationValue);

	// 	if (randomNumber == veryficationValue) {
	// 		setVerification(true);
	// 		alert('الرقم صحيح');
	// 	} else {
	// 		alert('الرجاء ادخال الرقم بشكل صحيح');
	// 	}
	// };

	// console.log(successMsg);
	// console.log(errorMsg);

	return (
		<Layout>
			{/* <MainHeader /> */}
			{/* <ToastSuccessError
				successMessage={successMsg}
				ErrorMessage={errorMsg}
				clearMsg={clearMsg}
			/> */}

			<ToastError
				ErrorMessage={errorMessage}
				successMessage={successMessage}
				setErrorMessage={setErrorMessage}
				setSuccessMessage={setSuccessMessage}
			/>
			{/* {signupsuccess && (
				<div className='alert alert-success text-center' role='alert'>
					تهانينا تم إنشاء الحساب بنجاح
				</div>
			)} */}
			<div className='w-100 text-center ' style={{ marginTop: '150px' }}>
				<h5 className='mb-5  mx-auto py-1  title_login'>إنشاء حساب {text}</h5>
			</div>
			<div className='d-flex justify-content-center'>
				<div className='   py-4  mt-2 mb-5 login-card shadow'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5 text-right w-100 '>الاسم </label>
							</div>
							<div className='parent mb-5 '>
								<input
									placeholder=' الاسم '
									autoComplete
									{...register('name', { required: true })}
									type='text'
									className=' form-control    login_input'
								/>
								<img
									src={profile}
									className='sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{errors.name && (
								<label className='text-center w-100  text-danger'>
									الاسم مطلوب
								</label>
							)}
						</div>
						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5 text-right w-100'>
									اسم المستخدم
								</label>
							</div>
							<div className='parent mb-5 '>
								<input
									placeholder='ادخل اسم المستخدم'
									autoComplete
									{...register('username', { required: true })}
									type='text'
									className=' form-control    login_input'
								/>
								<img
									src={profile}
									className='sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{errors.username && (
								<label className='text-center w-100  text-danger'>
									اسم المستخدم مطلوب
								</label>
							)}
						</div>
						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5 text-right w-100'>
									البريد الإلكتروني{' '}
								</label>
							</div>
							<div className='parent mb-5 '>
								<input
									placeholder=' ادخل البريد الالكتروني  '
									autoComplete
									{...register('email', { required: true })}
									type='email'
									className=' form-control    login_input'
								/>
								<img
									src={email}
									className='sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{errors.email && (
								<label className='text-center w-100  text-danger'>
									البريد الالكتروني مطلوب
								</label>
							)}
						</div>
						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5 text-right w-100'>
									رقم الموبايل{' '}
								</label>
							</div>
							<div className='parent mb-5 '>
								<input
									placeholder='ادخل رقم الموبايل '
									autoComplete
									{...register('phone_number', { required: true })}
									type='text'
									className=' form-control    login_input'
								/>
								<img
									src={phone}
									className='sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{errors.phone_number && (
								<label className='text-center w-100  text-danger'>
									رقم الموبايل مطلوب
								</label>
							)}
						</div>
						<div className='mb-3 mt-5 '>
							<div className='mb-3 mt-2 '>
								<label className='fw-bold fs-5 text-right w-100'>
									كلمة السر
								</label>
							</div>
							<div className='parent mt-4 mb-5'>
								<input
									placeholder='ادخل كلمة المرور'
									autoComplete
									{...register('password', { required: true })}
									type='password'
									className=' form-control login_input'
								/>

								<img
									src={lock}
									className=' sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{errors.password && (
								<label className='text-center w-100 text-danger'>
									كلمة السر مطلوبة
								</label>
							)}
						</div>
						<div className='mb-3 mt-5 '>
							<div className='mb-3 mt-2 '>
								<label className='fw-bold fs-5 text-right w-100'>
									{' '}
									تأكيد كلمة السر
								</label>
							</div>
							<div className='parent mt-4 mb-5'>
								<input
									placeholder='تأكيد كلمة السر  '
									autoComplete
									{...register('confirmpassword', { required: true })}
									type='password'
									className=' form-control login_input'
								/>

								<img
									src={lock}
									className=' sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{errors.confirmpassword &&
								watch('confirmpassword') != watch('password') && (
									<label className='text-center w-100 text-danger'>
										كلمة السر غير مطابقة
									</label>
								)}
						</div>

						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5 text-right w-100'>صورتك </label>
							</div>
							<div className='parent mb-5 '>
								<input
									autoComplete
									{...register('image', { required: true })}
									type='file'
									className=' form-control    '
								/>
							</div>
							{errors.name && (
								<label className='text-center w-100  text-danger'>
									الصورة مطلوب
								</label>
							)}
						</div>
						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5 text-right w-100'>الجنس </label>
							</div>
							<div class='form-check'>
								<label
									class='form-check-label'
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: '60px',
									}}
									for='flexRadioDefault1'>
									<input
										value='FEMALE'
										type='radio'
										id='flexRadioDefault1'
										{...register('gender', { required: true })}
									/>
									انثى
								</label>
							</div>

							<div class='form-check'>
								<label
									class='form-check-label'
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: '60px',
									}}
									for='flexRadioDefault2'>
									<input
										value='MALE'
										type='radio'
										id='flexRadioDefault2'
										{...register('gender', { required: true })}
									/>
									ذكر
								</label>
							</div>
							{errors.name && (
								<label className='text-center w-100  text-danger'>
									الجنس مطلوب
								</label>
							)}
						</div>

						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5 text-right w-100'>البلد </label>
							</div>
							<div className='parent mb-5 '>
								<select
									{...register('country_id', { required: true })}
									class='form-select'
									aria-label='Default select example'>
									<option selected>Open this select menu</option>
									{countries.map((country) => (
										<option value={country.id}>{country.name}</option>
									))}
								</select>
							</div>
							{errors.name && (
								<label className='text-center w-100  text-danger'>
									البلد مطلوب
								</label>
							)}
						</div>
						<div className='form-check mb-4'>
							<label
								className='form-check-label '
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '40px',
									width: 'auto',
								}}
								for='flexCheckDefault'>
								<input
									type='checkbox'
									value=''
									id='flexCheckDefault'
									{...register('is_accept', { required: true })}
								/>
								أوافق على سياسة الخصوصية
							</label>

							{errors.is_accept && (
								<label className='text-center w-100  text-danger'>
									يجب الموافقة على سياسة الخصوصية
								</label>
							)}
						</div>
						{loading ? (
							<div className='d-flex mb-3'>
								<div class='spinner-border m-auto' role='status'>
									<span class='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							<div className='d-flex'>
								<button
									disabled={!isDirty || !isValid}
									type='submit'
									className='login-button m-auto  rounded text-white fw-bold py-2'>
									انشاء
								</button>
							</div>
						)}
					</form>
				</div>
			</div>
		</Layout>
	);
}
