
import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import StudentsTable from '../../components/ConfirmRequest/StudentsTable';
import fetchStudents from '../../redux-toolkit/api/fetchStudents';
import fetchUnActiveStudents from '../../redux-toolkit/api/fetchUnActiveStudents';
import group from'../../assets/AboutUs/Group 408.png'
import { useQuery } from 'react-query';
import UnActiveStudentsTable from '../../components/ConfirmRequest/UnActiveStudentsTable';
import Layout from '../../components/Layout/Layout';
import axios from 'axios';
import record from'../../assets/Home/record.png'
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';

export default function Student() {
 
  const [page,setPage] =useState(1);
  const [pageUnActive,setPageUnActive] = useState(1);
  const {isLoading, isError, data, error} = useQuery(['students',page], fetchStudents);
  const [guardianData,setGuardianData] = useState();
  const [guardianStudents,setGuardianStudents] = useState();
  const location  = useParams();
  const [searchResult,setSearchResult] = useState('');
  const [selectedStudent,setSelectedStudent] = useState('');
  const inputSearchRef = useRef();
  const unOrderStudentsList = useRef();
  const [selectedStudentId,setSelectedStudentId] = useState(undefined);
    const [isLinking,setIsLinking] = useState(false);
    const [isLoadingStudents,setIsLoadingStudents] = useState(true);
    const [Isloading,setIsLoading] = useState(false);
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');
    const [studentForDeletion,setStudentForDeletion] = useState('');

  console.log('location params is ', location)

  const {isLoading: loadingUn, isError: isErrorUn, data: dataUn, error: errorUn} = useQuery(['unactivestudents',pageUnActive], fetchUnActiveStudents);
    const {state} = useLocation();
    const [isDeletingStudent,setIsDeletingStudent] = useState(false);
    const deletionModalRef = useRef();

    console.log('guardian information', state.guardianData);

      useEffect(() => {
        console.log('data dodo',data);

        axios.get(`${process.env.REACT_APP_API_URL}/get_students_by_guardian_id/${location.guardianID}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('guardian data' , data.data.data);
            setGuardianData(data.data.data)
            setIsLoadingStudents(false);
        })
        .catch(error => {
            console.log(error);
        });


      },[]) 

      const handleSelectStudentFromDrobDownList = (student) => {
        // setValue('student_id',student.student_id);
        console.log('student which was selected by user => ' , student)
        setSelectedStudentId(student.student_id);
        unOrderStudentsList.current.style.height = '0';
        inputSearchRef.current.value = student.name;
    }

    const handleSearchStudent = (e) => {
        
        unOrderStudentsList.current.style.height = 'auto';

        handleUnorderStudentsList(e.target.value);
        // setValue('student_id',e.target.value);
        
        axios.get(`${process.env.REACT_APP_API_URL}/students_search?q=${e.target.value}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(response => {
            console.log('search response',response.data.data);
            setSearchResult(response.data.data)
        })
        .catch(error => {
            console.log('error while fetching search students', error)
        });

    }

    const handleUnorderStudentsList = (searchQuery) => {
        if(searchQuery == '') {
            unOrderStudentsList.current.style.height = '0px';
        }else {
            unOrderStudentsList.current.style.height = 'auto';
        }
    }

    const handleLinkStudentWithGuardian = (e) => {
        setIsLinking(true);
        const data = {student_id: Number(selectedStudentId),guardian_id: Number(location.guardianID)};

        axios.post(`${process.env.REACT_APP_API_URL}/link_student_with_guardian`,
        data,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('data link student with guardian', data);
            setSuccessMessage("تمت  عملية الاضافة بنجاح")

            inputSearchRef.current.value = '';

            setTimeout(() => {
                window.location.reload();
            }, 1200);
        })
        .catch(error => {
            console.log('eroor while linking student with guardian',error);
            setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')
        })
        .finally(() => {
            setIsLinking(false);


        })
    }

    const handleDeleteStudentFromGuardian = () => {
        setIsDeletingStudent(true);

               // /unlink_student_with_guardian
        const data = {
            student_id: studentForDeletion.student_id,
            guardian_id: state.guardianData.guardian_id 
        }
        console.log('selected data to post it', data);

        axios.post(`${process.env.REACT_APP_API_URL}/unlink_student_with_guardian`,
        data,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('data link student with guardian', data);
            setSuccessMessage("تمت  عملية الازالة بنجاح")
            setSelectedStudentId(undefined);

            
            setTimeout(() => {
                window.location.reload();
            }, 1200);

        })
        .catch(error => {
            console.log('eroor while linking student with guardian',error);
            setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')
        })
        .finally(() => {
            setIsDeletingStudent(false);
            deletionModalRef.current.click();

            
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1200);
        })

    }

    const handleSelectStudentForDeletion = (student) => {
        setStudentForDeletion(student);

        
    }

    return <Layout>
          <div className='container all mb-5'>
          <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />

          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >أبناء {state.guardianData?.name}
          </h1>

          <div className="mb-3"/>
          
          <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'>لاضافة ابن للاب {state.guardianData?.name}  ...  يمكنك البحث عن الطالب من خلال الحقل ادناه عن طريق الاسم</label>
            </div>

            <input type='text' ref={inputSearchRef} placeholder='اكتب هنا للبحث عن طالب عن طريق الاسم' onChange={(e) => handleSearchStudent(e)} className='shadow w-100 main-input border rounded'/>
           {/* <input type='number' {...register("student_id", { required: true })} className='shadow w-100 main-input border-0'/> */}

           {/* <select type='text' {...register("student_id", { required: true })} ref={studentsSelect}  className='shadow w-100 p-2 border-0'> */}
            <div className='students_select mb-5'>   
                <ul className='unorder_students_list' ref={unOrderStudentsList} >
                        {
                            searchResult && searchResult?.map((student,index) => {
                                return   <li 
                                        onClick={() => handleSelectStudentFromDrobDownList(student)} 
                                        value={student.student_id} 
                                        key={index}
                                        style={{textAlign: 'center'}}
                                        >
                                            {student.name}
                                        </li>
                            })
                        }
                    </ul>
            </div>

        <div className='d-flex justify-content-around mb-5'>

        {/* <StudentsTable data={data?.data} isLoading={isLoading}/>  */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: '30px',
            flexWrap: 'wrap'
          }}>

            {
                isLoadingStudents && 
                <div>
                    <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        {
            guardianData && guardianData?.map((student,index) => {
                // console.log('student ',index+1 , student)
                return <div class="card" style={{width: "17rem"}}>
                <img src={process.env.REACT_APP_Video_URL + student.student_image } class="card-img-top" alt="صورة الطالب" style={{maxHeight: '180px'}}  />
                <div className="card-body">
                  <h3 className="text-center">{student.student_name}</h3>
                  <hr/>
                  <h6 className='mb-3'>المدينة: {student.student_city}</h6>
                  {/* <h6 className='mb-3'>الايميل: {student.student_email}</h6> */}
                  <Link className='btn btn-success mb-2 w-100' to={`/student/${student.student_id}`}>عرض تفاصيل الطالب</Link>
                  {/* <button className='btn btn-danger mt-2 w-100' style={{marginRight: 'auto !important'}}>حذف من سجل الاب</button> */}
                    
                <button 
                type="button" 
                className="btn btn-danger w-100" 
                data-bs-toggle="modal" 
                data-bs-target="#exampleModal"
                onClick={() => handleSelectStudentForDeletion(student)}
                >
                حذف الطالب من سجل الاب
                </button>
                </div>
              </div>
            
            })
        }
        </div>
          </div>

          {/* { (data?.total < 2  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />)  }   */}

            <div className='mt-4 text-center'>

            {selectedStudentId && (

            !isLinking ? 

            <button 
            className='btn btn-primary' 
            // disabled={isLinking}
            onClick={() => handleLinkStudentWithGuardian()}
            >اضافة الطالب للأب {guardianData?.name}</button> 
            
            : 
            <div className='w-100 text-center'>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>)
        }
            </div>
        </div>



<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title text-center" id="exampleModalLabel"> { isDeletingStudent ? "تتم الان عملية الازالة" : "تأكيد عملية الحذف"} </h5>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div class="modal-body">

        {isDeletingStudent ? 
                     <div className='w-100 text-center'>
                     <div class="spinner-border" role="status">
                     <span class="visually-hidden">Loading...</span>
                     </div>
                 </div>
                 : 
                 <> {"أنت على وشك ازالة الطالب " + studentForDeletion.student_name+ " من أبناء ولي الأمر" + state.guardianData.name }</>
        }
      
      
      </div>
      <div class="modal-footer">
        <div className='d-flex justify-content-between w-100'>
            <button 
            type="button" 
            className="btn btn-success" 
            style={{marginLeft: 'auto !important'}} 
            data-bs-dismiss="modal"
            ref={deletionModalRef}
            >إلغاء</button>
            
            <button 
            type="button" 
            className="btn btn-danger" 
            onClick={() => handleDeleteStudentFromGuardian()}>تأكيد</button>
        </div>
      </div>
    </div>
  </div>
</div>
    </Layout>
}