import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './SubjectSubsecribtion.css';
import ConfirmRequest from '../../components/ConfirmRequest/ConfirmRequest';
import Pagination from '../../components/Pagination/Pagination';
import { useQuery } from 'react-query';
import axios from 'axios';
import fetchSubjectSubscribtionApi from '../../redux-toolkit/api/fetchSubjectsSubscribtion';
import { useParams } from 'react-router-dom';
import TableSubjectSubscribtion from '../../components/ConfirmRequest/TableSubjectSubscribtion';

export default function SubjectSubsecribtion() {
	// const  {listRef,isLoading, isError, data, error,handlePageClick,page } = SectionsLogic();
	const params = useParams();
	const id = params?.id;
	console.log('id subsss', id);

	const [page, setPage] = useState(1);
	const [sections, setSections] = useState([]);
	const [subSections, setSubSetSections] = useState([]);
	const [subjects, setSubject] = useState([]);

	const [selectedSection, setSelectedSection] = useState(null);
	const [selectedSubSection, setSelectedSubSection] = useState(null);
	const [selectedSubject, setSelectedSubject] = useState(null);

	const { isLoading, isError, data, error } = useQuery(
		['SubjectsSubscribtion', page, id],
		fetchSubjectSubscribtionApi
	);

	// const listRef = useRef();
	function handlePageClick(data) {
		//  listRef.current.scrollIntoView()
		setPage(data.selected + 1);
	}

	useEffect(() => {
		const sectionsFromAPI = axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_sections?limit=19`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				console.log('log', data.data.data);
				setSections(data.data.data);
			});

		// console.log('sections from api',sectionsFromAPi);
	}, []);

	useEffect(() => {
		// console.log('section changed')
		// console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
		const subSectionsAPI = axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}?limit=19`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('subsection ', data.data.data);
				setSubSetSections(data.data.data.data);
			});
	}, [selectedSection]);

	useEffect(() => {
		// console.log('section changed')
		// console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
		const subSectionsAPI = axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_subjects_by_sub_section?sub_section_id=${selectedSubSection}&limit=19`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('subjects ', data.data.data);
				setSubject(data.data.data);
			});
	}, [selectedSubSection]);

	const handleSectionChange = (e) => {
		// console.log(e.target.value);
		setSelectedSection(e.target.value);
		setSubSetSections([]);
	};

	const handleSubSectionChange = (e) => {
		// console.log(e.target.value);
		setSelectedSubSection(e.target.value);
		setSubject([]);
	};
	const handleSubjectChange = (e) => {
		// console.log(e.target.value);
		setSelectedSubject(e.target.value);
	};
	const onHandleSubmit = (data) => {
		console.log('form data', data);
	};

	const handleAddSubject = (e) => {
		e.preventDefault();
		console.log('selectedSubject', selectedSubject);
		console.log('id subscribtion ', id);

		const Data = {
			subject_id: selectedSubject,
			subscription_id: id,
		};
		const res = axios
			.post(
				`${process.env.REACT_APP_API_URL}/add_subject_to_subscription`,
				Data,
				{
					headers: {
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data subject post to Subscribtion', data);
					window.location.reload();
					return res;
				} else {
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	return (
		<>
			<Layout>
				<div className='container all'>
					<h1
						className=' py-3'
						style={{
							color: '#044364',
							// borderBottom: '1px solid green'
						}}>
						مواد الاشتراك
					</h1>
					<div className='d-flex justify-content-around mb-5'>
						{
							<TableSubjectSubscribtion
								data={data?.data}
								isLoading={isLoading}
							/>
						}
					</div>
					{/* {data?.total < 2  ? <> </> : <Pagination  data={data} handlePageClick={handlePageClick} page={page} />  }   */}
				</div>

				<form>
					<div className='selects__container'>
						<div className='d-flex flex-column align-items-center justify-centent-center'>
							<p>اختر القسم</p>
							<select
								onChange={(e) => handleSectionChange(e)}
								className='form-select customSelect'
								aria-label='Default select example'>
								<option selected>اختر القسم</option>
								{sections &&
									sections?.map((section, index) => (
										<option key={index} value={section.section_id}>
											{section.section_name}
										</option>
									))}
							</select>
						</div>

						<div className='d-flex flex-column align-items-center justify-centent-center'>
							<p>اخترالبرنامج</p>
							<select
								onChange={(e) => handleSubSectionChange(e)}
								className='form-select  customSelect'
								aria-label='Default select example'>
								<option selected>Open this select menu</option>
								{subSections &&
									subSections?.map((subsection, index) => (
										<option key={index} value={subsection.sub_section_id}>
											{subsection.sub_section_name}
										</option>
									))}
							</select>
						</div>

						<div className='d-flex flex-column align-items-center justify-centent-center'>
							<p>اختر الماده</p>

							<select
								onChange={(e) => handleSubjectChange(e)}
								className='form-select  customSelect'
								aria-label='Default select example'>
								<option selected>Open this select menu</option>
								{subjects &&
									subjects?.map((subject, index) => (
										<option key={index} value={subject.subject_id}>
											{subject.subject_name}
										</option>
									))}
							</select>
						</div>
					</div>

					<button
						type='submit'
						className='btn infos px-3 py- btn1'
						onClick={handleAddSubject}>
						إضافة
					</button>
				</form>
			</Layout>
		</>
	);
}
