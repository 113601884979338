import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
// import Image from '../Image/Image'
import record from '../../assets/Home/record.png';
import { useForm } from 'react-hook-form';
import Layout from '../Layout/Layout';
import './FaqsSubject.css';

import axios from 'axios';
import FaqsSubjectLogic from './FaqsSubjectLogic';

export default function FaqsSubjects({ handleAdd, subscribtionPage }) {
	const parmas = useParams();
	const id = parmas?.id;
	console.log('subjectsss for Faqs id ', id);

	const location = useLocation();
	const subjectId = location?.state?.subjectid;
	console.log('location idd', subjectId);

	const EditAnswer = location?.state?.faqs?.answer;
	console.log('Faqsss', EditAnswer);

	const EditQuestion = location?.state?.faqs?.question;
	console.log('Faqsss', EditQuestion);

	const commentId = location?.state?.faqs?.id;

	const [idTeacher, setIdTeacher] = useState(null);
	const [subSectionId, setSubSectionId] = useState(null);
	const [selectedSection, setSelectedSection] = useState(null);

	const [subjectData, setSubjectData] = useState([]);

	const [teachers, setTeachers] = useState([]);
	const [subSections, setSubSetSections] = useState([]);
	const [SubSelectedSection, setSelectedSubSection] = useState([]);
	const [sections, setSections] = useState([]);

	const {
		reset,
		getValues,
		formState,
		watch,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			question: '',
			answer: '',
			idComment: null,
		},
	});

	const { valid, handleSubmitApi, handleUpdateApi, AddSubscribtion } =
		FaqsSubjectLogic(
			subscribtionPage,
			id,
			reset,
			idTeacher,
			subSectionId,
			subjectId
		);

	// const [selectedTeacher , setSelectedTeacher]= useState(null)

	useEffect(() => {
		console.log('iddd', id);
		if (EditQuestion && EditQuestion.length) {
			// const sectionsFromAPI =  axios.get(`${process.env.REACT_APP_API_URL}/get_all_Faqs_by_subject_id/${id}?limit=5`)
			// .then(data => {
			//   console.log('log Faqs',data.data.data?.data)
			//   setSubjectData(data.data.data?.data);
			reset({
				question: EditQuestion,
				answer: EditAnswer,
				idComment: commentId,
			});
		} else console.log('idddElse', id);
	}, []);

	const tempId = id || 0;

	console.log('Subscribtionnnnnn nameeeeeeeee', getValues().subscription_name);

	// useEffect(()=>{
	//   console.log("dataaaaaaaaaaaaa",subscribtionPage)
	//    if(tempId)reset(subscribtionPage?.data);
	// },[subscribtionPage])

	return (
		<Layout>
			<div className='container '>
				<h1
					style={{
						color: '#044364',
					}}>
					{!subjectId ? 'تعديل السؤال الشائع' : 'أضف سؤال شائع'}
				</h1>
				<form onSubmit={handleSubmit(handleSubmitApi)} className='pt-5 py-5 P'>
					<div className='mb-3'>
						<div className='mb-3 d-flex align-items-center'>
							<img src={record} className='record mx-2' />
							<label className='fw-bold'> السؤال</label>
						</div>
						<div className='d-flex '>
							<textarea
								col={50}
								type='text'
								className='shadow w-100 py-2 border-0'
								{...register('question', { required: true })}
							/>
							{errors.question && <p className='text-danger'>*required</p>}
						</div>
					</div>

					<div className='mb-3'>
						<div className='mb-3 d-flex align-items-center'>
							<img src={record} className='record mx-2' />
							<label className='fw-bold'> الجواب</label>
						</div>
						<div className='d-flex '>
							<textarea
								col={50}
								type='text'
								className='shadow w-100 py-2 border-0'
								{...register('answer', { required: true })}
							/>
							{errors.answer && <p className='text-danger'>*required</p>}
						</div>
					</div>

					<div className='d-flex justify-content-center mb-5'>
						{tempId ? (
							<button
								className=' btn btn1'
								// onClick={()=>handleUpdateApi(getValues())}
							>
								<h6>تعديل </h6>
							</button>
						) : (
							<button className=' info  py-2 px-5 border-0 btn1'>
								<h6>إضافة </h6>
							</button>
						)}
					</div>

					{/* <h1>{formState.errors?.content }</h1> */}
				</form>
			</div>
		</Layout>
	);
}
