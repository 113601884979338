
import axios from "axios";

export default async function  fetchLibrarySections ({queryKey}){
// {           console.log('query key '.repeat(20),queryKey)
          console.log("process",process.env.REACT_APP_API_URL)
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_students_in_library_section?library_section_id=${queryKey[2]}&limit=15` ,
          {params :{limit:10}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );
            // console.log('data '.repeat(20),data);
          return  data;
} 


