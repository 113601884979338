import React, { useEffect, useState } from 'react';
import Footer from '../../Footer';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';

const Layout = (props) => {
	//   const [appear,setAppear]=useState(false)
	// useEffect(()=>{
	// setAppear(true)
	// },[])

	return (
		<div className=''>
			{/* <div className='col-12'> */}
			<Header />
			{/* </div> */}
			<div className=''>
				<SideBar />
			</div>
			<div className='col-12 '>{props.children}</div>

			{/* {
          appear?<SideBar/>:''
        } */}

			<Footer />
		</div>
	);
};

export default Layout;
