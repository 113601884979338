import React,{ useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchSubjectApi from "../../redux-toolkit/api/fetchSubject";

const ShowSubjectsLogic = (id) => {
    const [page,setPage] =useState(1);
    console.log("nooooooo");
    const {isLoading, isError, data, error} = useQuery(['subjects',page ,id], fetchSubjectApi);   
    
    function handlePageClick (data)
    {  
     setPage(data.selected+1);
    }
    console.log("oGGGGGGG"); 
    return {
        isLoading, isError, data, error,handlePageClick,page
    }
}

export default  ShowSubjectsLogic