import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import SubscribtionMainLogic from './SubscribtionMainLogic';
import './SubscribtionMain.css';
import Layout from '../Layout/Layout';
import ConfirmRequest from '../ConfirmRequest/ConfirmRequest';
import Pagination from '../Pagination/Pagination';

const SubscribtionMain = () => {
	console.log('YESSSSSSSSSS');
	const { listRef, isLoading, isError, data, error, handlePageClick, page } =
		SubscribtionMainLogic();
	console.log('subscribtion Data', data?.data?.data);
	const dataSubscribtion = data?.data?.data;
	const [status, setStatus] = useState();

	async function deletePost(id) {
		console.log('iddddd', id);
		await fetch(
			`${process.env.REACT_APP_API_URL}/delete_subject_from_subscription/${id}`,
			{
				method: 'DELETE',
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			}
		);
		setStatus('Delete successful');
	}
	const handleDelete = (id) => {
		// DELETE request using fetch with async/await
		console.log('no id', id);
		deletePost(id);
	};

	return (
		<>
			<Layout>
				<div className='container all'>
					<h1
						className='pt-0 mb-4 pb-4 pt-3'
						style={{
							color: '#044364',
							// borderBottom: '1px solid green'
						}}>
						الاشتراكات
					</h1>

					<div className='d-flex justify-content-around mb-5'></div>

					{<ConfirmRequest data={dataSubscribtion} isLoading={isLoading} />}

					{/* {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }   */}
				</div>
			</Layout>
		</>
	);
};

export default SubscribtionMain;

{
	/* <div key={subscribtion?.subscription_id} className='col-12 col-sm-12 col-md-6 col-lg-4'>
          <div className="card" >
          <img src={subscribtion?.subscription_image} class="card-img-top" alt={subscribtion?.subscription_name} />
          <div className="card-body">
            <h5 className="card-title">{subscribtion?.subscription_name}</h5>
            <p className="card-text">{subscribtion?.subscription_description}</p>
            <Link to={`/mainsubscribtion/${subscribtion?.subscription_id}`} className="ms-3 btn btn-primary">show</Link>
            <Link to={`/subscribe/${subscribtion?.subscription_id}`} className="ms-3 btn btn-success">edit</Link>
            <button onClick={()=>handleDelete(subscribtion?.subscription_id)} className="ms-3 btn btn-danger">Delete</button> 
     </div>
  </div> */
}
