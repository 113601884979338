import { createSlice} from "@reduxjs/toolkit";



const initialState={
    image:null
}

const GetImageSlice = createSlice({
    name:'image',
    initialState:initialState,
   reducers:{
         getImage:(state,action)=>{
              console.log("my Image umumummmuu" , action.payload);
                state.image = action.payload;
         }
   }
})

export const {getImage}  = GetImageSlice.actions;

export default GetImageSlice.reducer;




