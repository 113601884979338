import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ConfirmRequest from '../../components/ConfirmRequest/ConfirmRequest';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
// import fetchTeachers from '../../redux-toolkit/api/fetchTransfers';
import TaachersTable from '../../components/ConfirmRequest/TeachersTable';
// import './Teachers.css';
import { NavLink } from 'react-router-dom';
import group from '../../assets/AboutUs/Group 408.png';
import fetchTransfers from '../../redux-toolkit/api/fetchTransfers';
import TransfersTable from '../../components/ConfirmRequest/TransfersTable';

const Transfers = (props) => {
	const [page, setPage] = useState(1);
	const [filter,setFilter] = useState('COURSE');

	const { isLoading, isError, data, error ,refetch} = useQuery(
		['transfers', page , filter],
		fetchTransfers
	);

	const [isCourse,setIsCouse] = useState(true);

	function handlePageClick(data) {
		setPage(data.selected + 1);
	}

	const [isLibrary,setIsLibrary] = useState(false);

	const changeFilter = (e) => {
		// console.log(e.target.value);
		const value = e.target.value;
		if(value = 'LIBRARY'){
			setIsLibrary(true)
		}else {
			setIsLibrary(false);
		}

		setFilter(value);
	}

	const handleChangeCourse = (e) => {
		setIsCouse(true);
		setIsLibrary(false);

		setFilter("COURSE");
	}

	const handleChangeLibrary = (e) => {
		setIsLibrary(true);
		setIsCouse(false);
	
		setFilter("LIBRARY");
	}


	useEffect(() => {
		setPage(1);
	},[filter])

	return (
		<Layout>
			<div className='container all'>
				<h1
					className='pt-0 mb-1 pb-4 pt-3'
					style={{
						color: '#044364',
						// borderBottom: '1px solid green'
					}}>
					الحوالات
				</h1>

				<div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
					<h5 className='gre'> أضف حوالة</h5>
					<NavLink to='/transferType'>
						<img src={group} className='me' />
					</NavLink>
				</div>

				<div className="form-check">
					<input className="form-check-input " value={isCourse} type="checkbox" onChange={(e) => handleChangeCourse(e)} id="flexCheckDefault" checked={!isLibrary} />
					<label className="form-check-label d-inline-block mr-4" for="flexCheckDefault">
						حوالات الكورسات
					</label>
					</div>
					<div className="form-check">
					<input className="form-check-input" value={isLibrary} type="checkbox" onChange={(e) => handleChangeLibrary(e)} id="flexCheckChecked" checked={isLibrary} />
					<label className="form-check-label d-inline-block mr-4"  for="flexCheckChecked">
						حوالات المكتبة
					</label>
				</div>

				<div className='d-flex justify-content-around mb-5'>
					{<TransfersTable data={data?.data} refetch={refetch} isLoading={isLoading} />}
				</div>
				{data?.data.total < 1 ? (
					<> </>
				) : (
					<Pagination
						data={data?.data}
						handlePageClick={handlePageClick}
						page={page}
					/>
				)}
			</div>
		</Layout>
	);
};

export default Transfers;
