//just subjectsssss

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function FaqsSubjects({ data, isLoading, subjectId }) {
	const [dataSubject, setDataSubject] = useState(data);
	const [flag, setFlag] = useState(0);

	const navigate = useNavigate();

	//  if(dataSubject.length===0 && flag===1){
	//     window.location.reload();
	// }

	const handleDeleteSubject = (commentId, subjectId) => {
		console.log('idddddd subject', commentId);
		const temp = [...dataSubject];
		const DataDelte = temp.filter((item) => item.id !== commentId.id);
		console.log('Data Deleteeed', DataDelte);
		setDataSubject(DataDelte);
		setFlag(1);
	};

	useEffect(() => {
		console.log('data from react query for Subjectssss', data);
		setDataSubject(data);
	}, [data]);

	async function deletePost(item, subjectId) {
		console.log('iddddd', item);

		const res = axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_faqs/${item.id}`, {
				headers: {
					'content-type': 'text/json',
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json',
					'Content-Encoding': 'gzip , deflate, br',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				// data:{
				//   "comment":item.comment,
				//   "subject_id":subjectId,
				//   "student_id":1,

				// }
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data delete Subscribtion', data);
					handleDeleteSubject(item, subjectId);
					alert('تم الحذف بنجاح');
					window.location.reload();
					return res;
				} else {
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	}
	// setStatus('Delete successful');
	const handleDelete = (item, subjectId) => {
		// DELETE request using fetch with async/await
		console.log('no id', item);
		deletePost(item, subjectId);
	};

	const AddFaqs = (id) => {
		navigate('/addFaqs', { state: { subjectid: id } });
	};

	const handleEdit = (item, id) => {
		navigate(`/addFaqs/${id}`, { state: { faqs: item } });
	};

	const handleShow = (item) => {
		navigate('/showFaqs', { state: { faqs: item } });
	};

	return (
		<>
			<div className='text-center my-5'>
				{/* <Link to={`/addComments/${subjectId}`}> */}
				<button className='btn  btn1' onClick={() => AddFaqs(subjectId)}>
					اضف سؤال شائع
				</button>
				{/* </Link> */}
			</div>

			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'>السوال</th>
						<th className='text-center text-white'>الجواب</th>
						<th className='text-center text-white last_child'> العمليات</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={3}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : dataSubject ? (
						dataSubject.length > 0 &&
						dataSubject?.map((item, index) => {
							return (
								<tr key={index}>
									<td className='text-center'>{item?.question} </td>
									<td className='text-center'>{item?.answer} </td>
									<td className='text-center'>
										{/* <i className='fa fa-check yes fa-2x'> </i>  */}
										{/* <button onClick={()=>handleShow(item)} className="ms-3 btn  btn1">show </button> */}
										<button
											onClick={() => handleEdit(item, subjectId)}
											className='ms-3 btn btn-warning'>
											تعديل{' '}
										</button>
										<button
											onClick={() => handleDelete(item, subjectId)}
											className='ms-3 btn  btn2'>
											حذف
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={3} className='text-center py-5 '>
							<div>
								<h5>لايوجد اسئله شائعه</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>
		</>
	);
}
