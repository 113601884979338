import { useEffect, useRef, useState } from "react";

export default function(setvideo,getvalues,setImagesURL){

    const[images,setImages]=useState([]);
    const[imagesURLs,setImagesURLs]=useState([]);
 
    const btnref = useRef();
    const cardref = useRef();

    useEffect(()=>{
      
      if(images.length<1) return;
      const newImagesURLs=[];
    
      images.forEach(image=>newImagesURLs.push(URL.createObjectURL(image)));
      setImagesURLs(newImagesURLs)
    },[images])

    function onImageChange(e){
      setImages([...e.target.files])
      // console.log('video file',e.target.files[0][0][0]);
      setvideo(e.target.files[0]);
      setImagesURL(URL.createObjectURL(e.target.files[0]));
      // console.log("ImageURL",e.target.files[0])
      his()
    }
   

  const his = (e) => {
      if(imagesURLs){
        // btnref.current.style.display = 'none';
        cardref.current.style.width = '100%';
      }
  }
    return{

        cardref,btnref,imagesURLs,onImageChange
    }
}