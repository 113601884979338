import React, { useEffect, useReducer, useRef, useState } from 'react';
import Layout from '../Layout/Layout';
import img from '../../assets/Home/chalks (1).png';
import record from '../../assets/Home/record.png';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Circle } from 'rc-progress';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import { Editor } from '@tinymce/tinymce-react';

const WebsiteTexts = () => {
	const [allSiteInfo, setAllSiteInfo] = useState(null);
	const [progress, setProgress] = useState(0);
	const [IsLoading, setIsLoading] = useState(false);

	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [isReal, setIsReal] = useState(false);

	const noReal = useRef();
	const yesReal = useRef();

	const descrbitionLeft = useRef(null);
	const privacy = useRef(null);
	const theVision = useRef(null);
	const theGoal = useRef(null);
	const aboutUs = useRef(null);
	const webSiteLibraryDesc = useRef(null);
	const juniorCertificate = useRef(null);
	const highSchoolScientific = useRef(null);
	const highSchoolLiterary = useRef(null);
	const langDescription1 = useRef(null);

	const {
		reset,
		getValues,
		watch,
		setValue,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			the_vision: '',
			the_goal: '',
			aboutUs: '',
			number_of_students: '',
			number_of_lessons: '',
			number_of_subjects: '',
			facebook: '',
			youtube: '',
			whatsapp: '',
			instgram: '',
			terms_and_laws: '',
			junior_certificate: '',
			highschool_scientific_certificate: '',
			highschool_literary_certificate: '',
			lang_description1: '',
			lang_description2: '',
			transable: '0',
			is_real: isReal ? 1 : 0,
			subscribe_to_the_library_url: '',
			website_library_description: '',
			subject_subsription_url: '',
		},
	});

	const handleOnSubmit = (data) => {
		//  setValue('lang_description2' , descrbitionLeft.current.getContent() )
		setValue('lang_description2', '');

		const ourFormData = new FormData();

		ourFormData.append('number_of_students', data.number_of_students);
		ourFormData.append('number_of_lessons', data.number_of_lessons);
		ourFormData.append('number_of_subjects', data.number_of_subjects);

		ourFormData.append('facebook', data.facebook);
		ourFormData.append('youtube', data.youtube);
		ourFormData.append('whatsapp', data.whatsapp);
		ourFormData.append('instgram', data.instgram);

		// tiny mce fields
		ourFormData.append('terms_and_laws', privacy.current.getContent());
		ourFormData.append('the_vision', theVision.current.getContent());
		ourFormData.append('the_goal', theGoal.current.getContent());
		ourFormData.append('aboutUs', aboutUs.current.getContent());
		ourFormData.append(
			'website_library_description',
			webSiteLibraryDesc.current.getContent()
		);
		// ourFormData.append("junior_certificate", juniorCertificate.current.getContent());
		ourFormData.append('junior_certificate', 'ss');
		// ourFormData.append(
		//   "highschool_scientific_certificate",
		//   highSchoolScientific.current.getContent()
		// );
		ourFormData.append('highschool_scientific_certificate', 'ss');
		// ourFormData.append(
		//   "highschool_literary_certificate",
		//   highSchoolLiterary.current.getContent()
		// );
		ourFormData.append('highschool_literary_certificate', 'ss');
		// ourFormData.append("lang_description1", langDescription1.current.getContent());
		ourFormData.append('lang_description1', 'ss');
		// ourFormData.append("lang_description2", descrbitionLeft.current.getContent());
		ourFormData.append('lang_description2', 'ss');

		ourFormData.append('transable', 0);
		ourFormData.append('is_real', isReal ? 1 : 0);

		ourFormData.append(
			'subscribe_to_the_library_url',
			data.subscribe_to_the_library_url
		);

		ourFormData.append('subject_subsription_url', data.subject_subsription_url);

		for (var pair of ourFormData.entries()) {
			console.log('dataaaaaaaaaaaaaaaaa', pair[0] + ', ' + pair[1]);
			// console.log('pair',pair);
		}

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/edit_text_site_infos`,
				ourFormData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},

					onUploadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded / progressEvent.total) * 100
						);
						console.log(progress);
						setProgress(progress);
						setIsLoading(true);
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					// console.log("data from post api", data);
					setIsLoading(false);
					setSuccessMessage('تمت عملية التعديل بنجاح');

					setTimeout(() => {
						// window.location.reload();
					}, 1000);
					return res;
				} else {
					console.log(res);
					//   throw new Error('there is an error')
				}
			})
			.catch((error) => {
				console.log(error.response);
				setErrorMessage(error.response.data.message);
				//  setIsError(error.response.data)
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_site_infos`)
			.then((data) => {
				setAllSiteInfo(data.data.data);
				reset(data.data.data);
				// console.log("get values", getValues());
				// console.log("all site infor", data);
			})
			.catch((error) => console.log(error));
	}, []);

	useEffect(() => {
		if (progress == 100) {
			setIsLoading(false);
		}
	}, [progress]);

	useEffect(() => {
		console.log(getValues());
	}, [watch()]);

	const changeColor = (progress) => {
		if (progress <= 25) {
			return '#ff0707';
		} else if (progress <= 50) {
			return '#ff6907';
		} else if (progress <= 75) {
			return '#ffdb07';
		} else {
			return '#77ff07';
		}
	};

	const handleIsRealChange = (e) => {
		// console.log("target is => ", e.target.getAttribute("id"));
		// console.log("checked => ", e.target.value);
		// noReal
		//yesReal

		if (e.target.getAttribute('id') == 'noReal') {
			yesReal.current.checked = false;
			// setValue('is_real',0);
			console.log('noReal checked');
			setIsReal(false);
		} else {
			// setValue('is_real',1);
			console.log('yesReal checked');
			setIsReal(true);

			axios
				.get(`${process.env.REACT_APP_API_URL}/get_all_site_infos`)
				.then((data) => {
					// setValue('number_of_students',data.data.data.number_of_students)
					// console.log('number_of_studentsRef =>',number_of_studentsRef.current)
					// number_of_studentsRef.current.value = data.data.data.number_of_students;
					// number_of_subjectsRef.current.value = data.data.data.number_of_subjects;
					// number_of_lessonsRef.current.value = data.data.data.number_of_lessons;
					reset(data.data.data);
				})
				.catch((error) => console.log(error));

			noReal.current.checked = false;
		}
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			{IsLoading && (
				<div className={`uploadPrgressbar`}>
					<h2>جار رفع الملفات الرجاء الانتظار ... </h2>

					<div className='content'>
						{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
						<p>{progress}%</p>
						<Circle
							percent={progress}
							strokeWidth={4}
							strokeColor={changeColor(progress)}
						/>
					</div>
				</div>
			)}
			<Layout>
				<form onSubmit={handleSubmit(handleOnSubmit)}>
					<div className='container all'>
						<h1
							className=' pt-0 mb-4 pb-4 '
							style={{
								color: '#044364',
							}}>
							نصوص الموقع
						</h1>

						{/* // رؤيتنا  */}
						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'> رؤيتنا</label>
							</div>

							<Editor
								apiKey='krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1'
								onInit={(evt, editor) => (theVision.current = editor)}
								initialValue={getValues('the_vision')}
								init={{
									height: 500,
									menubar: false,
									plugins: [
										'advlist',
										'autolink',
										'lists',
										'link',
										'charmap',
										'preview',
										'anchor',
										'searchreplace',
										'visualblocks',
										'code',
										'fullscreen',
										'insertdatetime',
										'table',
										'code',
										'help',
										'wordcount',
									],
									toolbar:
										'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style:
										'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
								}}
							/>
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'> هدفنا</label>
							</div>

							<Editor
								apiKey='krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1'
								onInit={(evt, editor) => (theGoal.current = editor)}
								initialValue={getValues('the_goal')}
								init={{
									height: 500,
									menubar: false,
									plugins: [
										'advlist',
										'autolink',
										'lists',
										'link',
										'charmap',
										'preview',
										'anchor',
										'searchreplace',
										'visualblocks',
										'code',
										'fullscreen',
										'insertdatetime',
										'table',
										'code',
										'help',
										'wordcount',
									],
									toolbar:
										'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style:
										'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
								}}
							/>
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>نبذة عنا</label>
							</div>

							<Editor
								apiKey='krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1'
								onInit={(evt, editor) => (aboutUs.current = editor)}
								initialValue={getValues('aboutUs')}
								init={{
									height: 500,
									menubar: false,
									plugins: [
										'advlist',
										'autolink',
										'lists',
										'link',
										'charmap',
										'preview',
										'anchor',
										'searchreplace',
										'visualblocks',
										'code',
										'fullscreen',
										'insertdatetime',
										'table',
										'code',
										'help',
										'wordcount',
									],
									toolbar:
										'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style:
										'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
								}}
							/>
						</div>
					</div>
					<div className='tab text-white px-3 d-flex py-3 fw-bold w-100 mb-3'>
						<h6> أرقام المنصة</h6>
					</div>

					<div className='container  mt-5 mb-4'>
						<div className=' row mx-0'>
							<div className='row mx-0 '>
								<div className='mb-3 d-flex align-items-center gap-5 mb-5 flex-wrap'>
									<img src={record} className='record mx-2' />
									<label className='fw-bold'>
										{' '}
										هل تريد ان تكون هذه الارقام حقيقة ؟
									</label>

									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-evenly',
											flex: '1',
											minWidth: '200px',
											maxWidth: '400px',
										}}>
										<label>
											نعم
											<input
												className='form-check-input mx-2'
												type='checkbox'
												id='yesReal'
												ref={yesReal}
												defaultChecked={getValues('is_real') == 1}
												style={{ width: '20px', height: '20px' }}
												onChange={(e) => handleIsRealChange(e)}
											/>
										</label>

										<label>
											لا
											<input
												className='form-check-input mx-2'
												type='checkbox'
												id='noReal'
												ref={noReal}
												defaultChecked={getValues('is_real') == 0}
												style={{ width: '20px', height: '20px' }}
												onChange={(e) => handleIsRealChange(e)}
											/>
										</label>
									</div>
								</div>

								<div className='col-md-6 mb-4 d-flex justify-content-around'>
									<div className='d-flex'>
										<img src={record} className='record mx-2' />
										<h6>عدد الطلاب</h6>
									</div>
									<input
										min={0}
										type={'text'}
										className='w-50 shadow py-2'
										{...register('number_of_students', {
											required: true,
										})}></input>
									{errors.number_of_students && (
										<p className='text-danger text-right'>*required</p>
									)}
								</div>

								<div className='col-md-6 mb-4  d-flex justify-content-around'>
									<div className='d-flex'>
										<img src={record} className='record mx-2' />
										<h6>عدد المواد</h6>
									</div>
									<input
										min={0}
										type={'text'}
										className='w-50 shadow  py-2'
										{...register('number_of_subjects', {
											required: true,
										})}></input>
									{errors.number_of_subjects && (
										<p className='text-danger text-right'>*required</p>
									)}
								</div>

								<div className='col-md-6 mb-4 d-flex justify-content-around'>
									<div className='d-flex'>
										<img src={record} className='record mx-2' />
										<h6>عدد الدروس</h6>
									</div>

									<input
										min={0}
										type={'text'}
										className=' w-50 shadow  py-2'
										{...register('number_of_lessons', {
											required: true,
										})}></input>
									{errors.number_of_lessons && (
										<p className='text-danger text-right'>*required</p>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className='container all'>
						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'> صفحة المنصة على فيسبوك</label>
							</div>
							<input
								type='text'
								{...register('facebook', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.facebook && (
								<p className='text-danger text-right'>*required</p>
							)}
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>قناة اليوتيوب</label>
							</div>
							<input
								type='text'
								{...register('youtube', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.youtube && (
								<p className='text-danger text-right'>*required</p>
							)}
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>رقم الواتساب</label>
							</div>
							<input
								type='text'
								{...register('whatsapp', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.whatsapp && (
								<p className='text-danger text-right'>*required</p>
							)}
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>حساب المنصة على انستقرام</label>
							</div>
							<input
								type='text'
								{...register('instgram', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.instgram && (
								<p className='text-danger text-right'>*required</p>
							)}
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>
									{' '}
									رابط الاشتراك بمكتبة المنصة{' '}
								</label>
							</div>
							<input
								type='text'
								{...register('subscribe_to_the_library_url', {
									required: true,
								})}
								className='shadow w-100 main-input border-0'
							/>
							{errors.subscribe_to_the_library_url && (
								<p className='text-danger text-right'>هذا الحقل مطلوب</p>
							)}
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'> رابط الاشتراك بالمواد</label>
							</div>
							<input
								type='text'
								{...register('subject_subsription_url', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.subject_subsription_url && (
								<p className='text-danger text-right'>هذا الحقل مطلوب</p>
							)}
						</div>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>الوصف الخاص بمكتبة المنصة</label>
							</div>

							<Editor
								apiKey='krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1'
								onInit={(evt, editor) => (webSiteLibraryDesc.current = editor)}
								initialValue={getValues('website_library_description')}
								init={{
									height: 500,
									menubar: false,
									plugins: [
										'advlist',
										'autolink',
										'lists',
										'link',
										'charmap',
										'preview',
										'anchor',
										'searchreplace',
										'visualblocks',
										'code',
										'fullscreen',
										'insertdatetime',
										'table',
										'code',
										'help',
										'wordcount',
									],
									toolbar:
										'undo redo | blocks | ' +
										'bold italic forecolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | help',
									content_style:
										'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
								}}
							/>
						</div>

						<div className='mb-3 mt-5 d-flex align-items-center'>
							<img src={record} className='record mx-2' />
							<label className='fw-bold h3'>السياسة والخصوصية</label>
						</div>

						<Editor
							apiKey='krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1'
							onInit={(evt, editor) => (privacy.current = editor)}
							initialValue={getValues('terms_and_laws')}
							init={{
								height: 500,
								menubar: false,
								plugins: [
									'advlist',
									'autolink',
									'lists',
									'link',
									'charmap',
									'preview',
									'anchor',
									'searchreplace',
									'visualblocks',
									'code',
									'fullscreen',
									'insertdatetime',
									'table',
									'code',
									'help',
									'wordcount',
								],
								toolbar:
									'undo redo | blocks | ' +
									'bold italic forecolor | alignleft aligncenter ' +
									'alignright alignjustify | bullist numlist outdent indent | ' +
									'removeformat | help',
								content_style:
									'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
							}}
						/>

						{/* <div className="mb-3 mt-5">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold h3"> الشهادة الاعدادية</label>
              </div> */}

						{/* <Editor
            apiKey="krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1"
            onInit={(evt, editor) => (juniorCertificate.current = editor)}
            initialValue={getValues('junior_certificate')}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          /> */}

						{/* </div> */}

						{/* <div className="mb-3 mt-5">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold h3">
                  {" "}
                  الشهادة الثانوية الفرع العملي
                </label>
              </div> */}

						{/* <Editor
            apiKey="krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1"
            onInit={(evt, editor) => (highSchoolScientific.current = editor)}
            initialValue={getValues('highschool_scientific_certificate')}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          /> */}

						{/* <textarea
                style={{ minHeight: "150px" }}
                type="text"
                {...register("highschool_scientific_certificate", {
                  required: true,
                })}
                className="shadow w-100 main-input border-0"
              />
              {errors.highschool_scientific_certificate && (
                <p className="text-danger text-right">*required</p>
              )} */}
						{/* </div> */}
						{/* 
            <div className="mb-3 mt-5">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold h3"> الشهادة الثانية الفرع الادبي</label>
              </div> */}

						{/* <Editor
            apiKey="krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1"
            onInit={(evt, editor) => (highSchoolLiterary.current = editor)}
            initialValue={getValues('highschool_literary_certificate')}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          /> */}

						{/* <textarea
                style={{ minHeight: "150px" }}
                type="text"
                {...register("highschool_literary_certificate", {
                  required: true,
                })}
                className="shadow w-100 main-input border-0"
              />
              {errors.highschool_literary_certificate && (
                <p className="text-danger text-right">*required</p>
              )} */}
					</div>

					{/* <div className="mb-3 mt-5">
              <div className="mb-3 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold h3"> وصف اللغات القسم اليميني</label>
              </div> */}

					{/* <Editor
            apiKey="krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1"
            onInit={(evt, editor) => (langDescription1.current = editor)}
            initialValue={getValues('lang_description1')}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          /> */}
					{/* 
            </div> */}

					{/* <div className="mb-3 mt-5 d-flex align-items-center">
                <img src={record} className="record mx-2" />
                <label className="fw-bold h3"> وصف اللغات القسم اليساري</label>
              </div> */}

					{/* <Editor
            apiKey="krgvqcy81g7u9si5m2lw0avgwpj63ijbaixoxi38r34tcpq1"
            onInit={(evt, editor) => (descrbitionLeft.current = editor)}
            initialValue={getValues('lang_description2')}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          /> */}

					{/* </div> */}

					<div className='d-flex justify-content-center'>
						<button
							className=' btn  btn1 mt-5 mb-5 px-3 py-2 btn1'
							type='submit'>
							<h6>حفظ التعديلات</h6>{' '}
						</button>
					</div>
				</form>
			</Layout>
		</>
	);
};

export default WebsiteTexts;
