

import axios from "axios";
  export default async function  fetchSubscribtionApi ({queryKey})
 {
            console.log("axiosssssssssdfsdfsf");
            console.log("process",process.env.REACT_APP_API_URL)
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_subscriptions?page=${queryKey[1]}` ,
            {params :{limit:5}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
            );

     
            return  data;
 } 


