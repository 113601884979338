import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../../components/Layout/Layout';
import './LoginAsAdmin.css';
import record from '../../assets/Home/record.png';
import ToastNotify from '../../components/ToastNotify/ToastNotify';
import { useNavigate, Link, useLocation } from 'react-router-dom';
// import useAuth from '../../hooks/useAuth';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux-toolkit/reducers/Login/Login';

const LoginAsAdmin = (props) => {
	//  const setAuth  = useAuth();
	const userInfos = useSelector((state) => state.Login);
	const dispatch = useDispatch();

	useEffect(() => {
		console.log('user', userInfos);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const from = location?.state?.from?.pathname || '/';

	const [mainVideoFile, setMainVideoFile] = useState('');
	const [aboutUsImage, setAboutUsImage] = useState('');
	const [allSiteInfo, setAllSiteInfo] = useState(null);

	const [progress, setProgress] = useState(0);

	const [IsLoading, setIsLoading] = useState(false);
	const [isUploadSuccessfuly, setIsUploadSuccessfuly] = useState(false);

	const [isThereAnError, setIsThereAnError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(undefined);

	const [isSuccess, setIsSuccess] = useState(false);

	const [defauluseFormVlaues, setDefauluseFormValues] = useState({
		username: '',
		password: '',
	});

	const {
		reset,
		getValues,
		watch,
		setValue,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({ mode: 'onSubmit', defaultValues: defauluseFormVlaues });

	const handleOnSubmit = (data) => {
		// e.preventDefault();
		console.log('data', data);
		console.log('submitting data to server');

		const ourFormData = new FormData();

		setErrorMessage(undefined);
		setIsSuccess(undefined);

		ourFormData.append('username', data.username);
		ourFormData.append('password', data.password);

		for (var pair of ourFormData.entries()) {
			console.log(pair[0] + ', ' + pair[1]);
			// console.log('pair',pair);
		}

		axios
			.post(`${process.env.REACT_APP_API_URL}/login_as_admin`, ourFormData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json',
					'Content-Encoding': 'gzip , deflate, br',
					'content-type': 'text/json',
					'Content-Type': 'multipart/form-data',
				},

				onUploadProgress: (progressEvent) => {
					const progress = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					console.log(progress);
					setProgress(progress);
					setIsLoading(true);
				},

				onDownloadProgress: (progressEvent) => {
					const progress =
						50 + (progressEvent.loaded / progressEvent.total) * 100;
					console.log(progress);
					setProgress(progress);
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;

					// console.log('data from post api' , data.data.admin);
					// console.log('tocken come back from api' , data.data.token);

					localStorage.setItem('role_id', data.data.admin.role_id);
					localStorage.setItem('token', data.data.token);

					dispatch(
						setUser({
							role_id: data.data.admin.role_id,
							token: data.data.token,
						})
					);

					setIsLoading(false);
					setIsUploadSuccessfuly(true);
					setIsSuccess(true);

					// navigate(from , {replace: true});

					setTimeout(() => {
						// Hide the success alert
						setIsSuccess(false);
						// // Redirect user to the home page
						navigate('/welcomAdmin');
					}, 3000);
					return res;
				} else {
					setIsLoading(false);
					console.log('res', res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log('eerr', error);

				console.log(error.response);
				if (error.response.statusText == 'Not Found') {
					setIsThereAnError(true);

					setErrorMessage('ليس هنالك أدمن بهذه المعلومات !!!');
					setTimeout(() => {
						setIsThereAnError(false);
					}, 3000);
				}
			});
	};

	return (
		<>
			<div className='loginasAdmin_container container pt-5 mt-5'>
				<div className='loginAsAdmin__content'>
					<h3>تسجيل الدخول بحساب أدمن</h3>

					{errorMessage && (
						<div
							class='alert alert-success d-flex gap-2 align-items-center'
							role='alert'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill='currentColor'
								class='bi bi-check2-all'
								viewBox='0 0 16 16'>
								<path d='M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z' />
								<path d='m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z' />
							</svg>
							<div>هذه المعلومات لا تتطابق مع سجلاتنا</div>
						</div>
					)}
					{isThereAnError && (
						<div
							class='alert alert-danger d-flex gap-2 align-items-center'
							role='alert1'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								fill='currentColor'
								class='bi bi-exclamation-triangle-fill flex-shrink-0 me-2'
								viewBox='0 0 16 16'
								role='img'
								aria-label='Warning:'>
								<path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
							</svg>
							<div>{errorMessage}</div>
						</div>
					)}

					{isSuccess && (
						<div
							class='alert alert-success d-flex gap-2 align-items-center'
							role='alert'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill='currentColor'
								class='bi bi-check2-all'
								viewBox='0 0 16 16'>
								<path d='M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z' />
								<path d='m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z' />
							</svg>
							<div>
								تمت عملية تسجيل الدخول بنجاح , سيتم توجيهك خلال بضع ثوان
							</div>
						</div>
					)}

					{IsLoading && (
						<div className='mb-3 d-flex justify-content-center'>
							<div class='spinner-border mx-auto' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}

					<form onSubmit={handleSubmit(handleOnSubmit)}>
						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اسم المستخدم</label>
							</div>
							<input
								type='text'
								{...register('username', { required: true })}
								className='shadow w-100 px-3 py-2 border main-input'
							/>
							{errors.username && (
								<p className='text-danger text-end p-2 fw-bold'>
									*هذا الحقل مطلوب
								</p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> كلمة السر</label>
							</div>
							<input
								type='password'
								{...register('password', { required: true, minLength: 8 })}
								className='shadow w-100 px-3 py-2 border main-input'
							/>
							{errors.password && (
								<p className='text-danger text-end p-2 fw-bold'>
									يحب أن تكون كلمة السر أكبر من 8 خانات*
								</p>
							)}
						</div>

						<div className='d-flex justify-content-center mt-5'>
							<button
								className=' btn infos px-3 py-2 border-0 bg-primary'
								type='submit'>
								<h6>تسجيل الدخول </h6>{' '}
							</button>
						</div>
					</form>
				</div>
				{/* <ToastNotify showMessage={isThereAnError} setIsError={setIsThereAnError} message={errorMessage} /> */}
			</div>
		</>
	);
};

export default LoginAsAdmin;
