
import axios from "axios";

export default async function  fetchLibrarySections ({queryKey})
{
          console.log("process",process.env.REACT_APP_API_URL)
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_website_library_sections?page=${queryKey[1]}` ,
          {params :{limit:10}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );

          return  data;
} 


