import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import Layout from '../Layout/Layout';

import TableTeacherSubjects from '../ConfirmRequest/TableTeacherSubject';
import './TeacherSubject.css';
import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

const TeacherSubjects = () => {
	const params = useParams();
	const id = params?.id;

	//   console.log("YESSSSSSSSSS");
	//     console.log("subjectssss Dataaaa" ,data);
	//     const dataSubject = data?.data
	const [status, setStatus] = useState();

	const { state } = useLocation();

	// const [successMessage , setSuccessMessage] = useState('');
	// const [ErrorMessage , setErrorMessage] = useState('');

	const [idTeacher, setIdTeacher] = useState(null);
	const [teachers, setTeachers] = useState([]);
	const [teacher, setTeahcer] = useState();
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		async function getData() {
			const actualData = await fetch(
				`${process.env.REACT_APP_API_URL}/get_teachers_by_subject_id/${id}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			).then((response) => response.json());
			setTeahcer(actualData);
			setIsLoading(false);
			console.log('actual Dataaaaaaaaaaaaaaaaaaaa teachers', actualData);
		}
		getData();
	}, []);

	const handleTeacherChange = (e) => {
		console.log('selected teacer  id sections', e.target.value);
		setIdTeacher(e.target.value);
	};

	useEffect(() => {
		// console.log('section changed')
		// console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
		const teachers = axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_teachers?limit=19`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				console.log('teachers DATA', data?.data?.data);
				//   setIdTeacher(data?.data?.data?.data);
				setTeachers(data?.data?.data?.data);
			});
	}, []);

	const handleAddTeahcer = (e) => {
		e.preventDefault();
		let Data = {
			subject_id: id,
			teacher_id: idTeacher,
		};
		console.log('dataaaa', Data);

		const res = axios
			.post(
				`${process.env.REACT_APP_API_URL}/link_subject_with_a_teacher`,
				Data,
				{
					headers: {
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data added teahce', data);
					window.location.reload();
					return res;
				} else {
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	return (
		<>
			<Layout>
				<div className='container '>
					<h1
						style={{
							color: '#044364',
						}}>
						الاساتذة
					</h1>
					{/* <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    /> */}
					<div className='d-flex justify-content-around mb-5'></div>

					{
						<TableTeacherSubjects
							data={teacher?.data}
							isLoading={isLoading}
							subjectId={id}
						/>
					}

					{/* {data?.total < 10  ? <> </> : <Pagination  data={data} handlePageClick={handlePageClick} page={page} />  }   */}
				</div>

				<form className='subject'>
					<div className='selects__container'>
						<select
							onChange={(e) => handleTeacherChange(e)}
							className='form-select  customSelect'
							aria-label='Default select example'>
							<option selected>اختر استاذ</option>
							{teachers &&
								teachers.length > 0 &&
								teachers?.map((teacher, index) => (
									<option key={index} value={teacher.teacher_id}>
										{teacher.teacher_name}
									</option>
								))}
						</select>

						<div className='text-center'>
							<button onClick={handleAddTeahcer} className='btn btn1'>
								اضف استاذ للماده
							</button>
						</div>
					</div>
					{/* <button type='submit' className='btn infos px-3 py-2' onClick={handleAddSubject}>إضافة</button> */}
				</form>
			</Layout>
		</>
	);
};

export default TeacherSubjects;
