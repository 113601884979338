import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../components/Layout/Layout';
import './TestViemeoPlayer.css';
import ReactPlayer from 'react-player';
// import GoogleLogin from 'react-google-login';
// or
import { GoogleLogin, GoogleLogout , useGoogleLogin } from 'react-google-login';
// import fullscreen from 'screenfull'
import YouTube from '@u-wave/react-youtube';

const TestViemeoPlayer = () => {

    // const {signIn} = useGoogleLogin();
    const [controlsVar,setControlsVar] = useState(false);
    const [isFullScreen,setIsFullScreen] = useState(false);
    const reactPlayerRef = useRef();
    const responseGoogle = (response) => {
        console.log(response);
        // console.log('Trying it out',signIn,loaded);
        // console.log(signIn());
}

const handleFailure = (e) => {
    
    console.log(e)
}

  useEffect(() => {
    window.addEventListener('resize', function(){
      var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement ||
      document.webkitFullscreenElement || document.msFullscreenElement;

      if(fullscreenElement  != null){
       // this is full screen
       setControlsVar(false);
       setIsFullScreen(true)
       console.log('full screen from event listner on resize event ')
      }else {
        setControlsVar(true);
        setIsFullScreen(false)
      }
    });

    console.log('react player reference',reactPlayerRef.current.getInternalPlayer())
  },[])

// const logout = (res) => {
//     console.log('logout',res);
// }
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
    useEffect(() => {
        // const responseGoogle = (response) => {
        //         console.log(response);
        // }
        responseGoogle();
        const loginInfo = getCookie('LOGIN_INFO');     
        console.log('login info',loginInfo);
    },[]);

    // Removing video title
    useEffect(() => {
      setTimeout(() => {
        // let videoTitle =   document.getElementsByClassName('reactplayer')[0];

        // console.log('Out title elememt =>', videoTitle);
        // console.log('childrens =>', videoTitle.children.children);

        
      const frame = document.getElementsByTagName('iframe')[0];

      const youtube = frame.contentWindow;

      console.log('xyz xyz =>', frame)

      youtube.addEventListener('contextmenu', e => e.preventDefault());


      },4000);
    },[])

    const handleUnstarted = (e) => {
      console.log('unstarted video', e);
    }

    // useEffect(() => {

    //   const handleContextEvent = (event) => {
    //     event.preventDefault();
    //   } 


    //   document.addEventListener('contextmenu', handleContextEvent);

    //   return() => document.removeEventListener('contextmenu',handleContextEvent);

    // },[])

    return (
        <Layout>

        <h1>Test Vimeo Player and how it works with React.js</h1>

    {/* <GoogleLogout
      clientId="730662435667-h33crf50tgdad21cjpo79vsgsu2rk2j6.apps.googleusercontent.com"
      buttonText="Logout"
      onLogoutSuccess={logout}
      
    >
    </GoogleLogout> */}

        <div className='mx-5'></div>
        <div className='mx-5'></div>

            <GoogleLogin
                clientId="730662435667-h33crf50tgdad21cjpo79vsgsu2rk2j6.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={handleFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true} // keep user signed in
            />
{/* 
        <div
        style={{width: '80%',minWidth: '300px', maxWidth: '800px', margin: '0px auto'}} 
        ><iframe 
        src="https://player.vimeo.com/video/724548342?h=2c33cfe496&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
         style={{width: '100%',height: '500px'}}
        title="video_2022-06-22_01-01-00"></iframe></div> */}

        <div className='googlelogin_container'>





        </div>

          <button onClick={() => reactPlayerRef.current.getInternalPlayer().playVideo()}>Play the video </button>
            {/* <Vimeo
            video="723900970?h=5beb2189d8"
            autoplay
            /> */}

          <div className='container d-flex justify-content-center relativeCon' style={{pointerEvents: isFullScreen ? 'none' : 'auto'}}>
          <div className='titletopcontainer'>
            <div className='absolutetitle'></div>
            <div className='fullscreenCoverTitle'></div>
            {/* <div className="absoluteFSBtn"></div> */}

         <ReactPlayer 
        //  wrapper={<div className="wrapperElement"></div>}
          controls={true}
          ref={reactPlayerRef}
          playing
          className="reactplayer"  
          url='https://youtu.be/XUxInCvQqyo' 
          pip={'false'}     
          config={{
            youtube: {

              playerVars: { 
                showinfo: 0 ,
                modestbranding: 1 ,
                fs: 1 ,
                rel: 0 ,// related videos 
                modestbranding: 1 ,
                enablejsapi: 1 ,
                cc_load_policy: 1,
                disablekb: 1
              },

              embedOptions: {
                 events: (e) => e.preventDefault()
              },
              
              onUnstarted: (e) => handleUnstarted(e),

            },
            file: {
              attributes: {
                controlsList: 'nodownload',  
                onContextMenu: e => e.preventDefault(),
                disablepictureinpicture: 'true'
              },
              tracks: [
                {kind: 'subtitles', src: 'subs/subtitles.en.vtt', srcLang: 'ar', default: true},
                {kind: 'subtitles', src: 'subs/subtitles.ja.vtt', srcLang: 'ja'},
                {kind: 'subtitles', src: 'subs/subtitles.de.vtt', srcLang: 'de'}
              ]
            },
          }}
         >
          <h1 className='testTest'>Test Test Test </h1>
          {/* <div className='fullscreenCoverTitle'></div> */}

         </ReactPlayer>
         {/* <div className='fullscreenCoverTitle'></div> */}

</div>
</div>
{/* <div className='fullscreenCoverTitle'></div> */}

         {/* <video controls width={400} src="https://youtu.be/XUxInCvQqyo" /> */}
       

            <div className='bt-5'></div>
            <div className='bt-5'></div>
            <div className='bt-5'></div>
            <div className='bt-5'></div>
            <div className='bt-5'></div>
            <div className='bt-5'></div>
            <div className='bt-5'></div>
            <div className='bt-5'></div>
            {/* <div className='fullscreenCoverTitle'></div> */}



            {/* <div className="d-flex justify-content-center">
            <iframe 
            src="https://player.vimeo.com/video/723900970?h=5beb2189d8" 
            width="640" 
            height="564" 
            frameborder="0" 
            allow="autoplay; fullscreen" 
            allowfullscreen
            className="shadow"
            >
        </iframe> */}

{/* <div className='fullscreenCoverTitle'></div> */}

            {/* </div> */}

            {/* <div className='container'>
            <video  controls src='https://youtu.be/XUxInCvQqyo' style={{'width':'100%','height':'100%','margin':'auto'}}/>
            </div> */}
{/* 
            <div className='container mt-5'>
            <YouTube
              video="XUxInCvQqyo"
              showInfo={false}
              modestBranding={true}
              annotations={false}
              showRelatedVideos={false}
              width={'100%'}
              height={'500px'}
            />
            </div> */}

            {/* <div className='container'>
            <iframe height="480" width="500"  
            src="https://www.youtube.com/embed/XUxInCvQqyo">
            </iframe>
            </div> */}

            <ReactPlayer
            url={'https://drive.google.com/file/d/1BzeHL_4lRCz-WezV26UFb4WJuercIKGE'}
            controls={true}
            />
        </Layout>
    )
}

export default TestViemeoPlayer;