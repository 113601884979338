

import axios from "axios";

export default async function  fetchSubjectForStudent ({queryKey})
{
          console.log("process",process.env.REACT_APP_API_URL)
          console.log("queryNOwww" , queryKey)
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_student_subjects_by_id/${queryKey[2]}` ,
          {params :{limit:10}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );
   
          return  data;
} 


