import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';

export default function ConfirmRequest({ data, isLoading }) {
	const navigate = useNavigate();
	const [currentBooks, setCurrentBooks] = useState([]);
	const [successMessage, setSuccessMessage] = useState('');
	const [Isloading, setIsLoading] = useState(false);
	const [selectedBookId, setSelectedBookId] = useState();
	const [ErrorMessage, setErrorMessage] = useState('');
	const modalRef = useRef(null);
	const closeModalBtn = useRef(null);

	useEffect(() => {
		console.log('data from react query', data?.data);
		setCurrentBooks(data?.data);
	}, [data]);
	const handleViewLibrarySection = (e, id) => {
		e.stopPropagation();

		navigate(`/librarySection/${id}`);
	};
	const handleDeleteBook = (e, id) => {
		e.stopPropagation();
		setIsLoading(true);

		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_website_library_section_book_by_id/${id}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				if (data.status == 200) {
					setSuccessMessage('تمت  عملية الحذف بنجاح');
					console.log(data);
					const newCurrentBooks = currentBooks.filter(
						(currentBook) => currentBook.id != id
					);
					setCurrentBooks([...newCurrentBooks]);

					setIsLoading(false);
				} else {
					setErrorMessage('حدث خطأ ما');
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setErrorMessage('حدث خطأ ما');
				setIsLoading(false);
			})
			.finally(() => {
				modalRef.current.style.display = 'none';
				closeModalBtn.current.click();
			});
	};

	const handleEditLibraryBook = (e, id) => {
		e.stopPropagation();

		navigate(`/editlibrarybook/${id}`);
	};

	const selectedBookid = (e, id) => {
		e.stopPropagation();

		setSelectedBookId(id);
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> اسم الكتاب</th>
						{/* <th className='text-center text-white'> اسم المستخدم</th> 
                <th className='text-center text-white'> الايميل</th> 
                <th className='text-center text-white'> رقم الهاتف</th> */}
						<th className='text-center text-white last_child'>
							العمليات المتاحة
						</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={2}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : (
						currentBooks &&
						currentBooks.map((book, index) => {
							return (
								<tr
									key={index}
									onClick={() => {
										window.open(
											`${
												process.env.REACT_APP_Video_URL + '/' + book.pdf_link
											}`,
											'_blank'
										);
									}}
									style={{
										cursor: 'pointer',
										borderBottom: '1px solid #1a1a1a1a',
									}}
									className='tableRowHover'
									title='اضغط لاظهار معلومات الكتاب'>
									<td className='text-center'>{book.title}</td>
									{/* <td  className='text-center'>{teacher.username}</td>                       
                        <td  className='text-center'>{teacher.email}</td>                       
                        <td  className='text-center'>{teacher.phone_number}</td>    */}
									<td className='text-center'>
										<button
											onClick={() => {
												window.open(
													`${
														process.env.REACT_APP_Video_URL +
														'/' +
														book.pdf_link
													}`,
													'_blank'
												);
											}}
											className='btn  btn1'>
											عرض
										</button>
										<button
											type='button'
											className='btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={(e) => selectedBookid(e, book.id)}>
											حذف
										</button>

										<button
											className='btn bg-primary'
											onClick={(e) => handleEditLibraryBook(e, book.id)}
											title='تعديل معلومات الكتاب'>
											تعديل
										</button>
										{/* 
                            <Link 
                            to={`/librarySectionStudents/${section.id}`} 
                            className='btn btn-success'>
                                الطلاب  
                            </Link> */}
									</td>
								</tr>
							);
						})
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				ref={modalRef}
				id='exampleModal'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title mx-auto' id='exampleModalLabel'>
								{Isloading ? 'جاري الحذف ...' : 'هل أنت متأكد ؟'}
							</h5>
						</div>
						{Isloading && (
							<div className='modal-body'>
								{Isloading && (
									<div className='mb-3 d-flex justify-content-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='visually-hidden'>Loading...</span>
										</div>
									</div>
								)}
							</div>
						)}
						{!Isloading && (
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									className='btn btn-secondary'
									data-bs-dismiss='modal'
									ref={closeModalBtn}>
									تجاهل
								</button>

								<button
									type='button'
									className='btn  btn2'
									onClick={(e) => handleDeleteBook(e, selectedBookId)}>
									حذف
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
