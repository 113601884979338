
import { useLocation , Navigate , Outlet, useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const RequireSideLink = (props) => {

    const location = useLocation();
    const userData = useSelector(state => state.Login)
    const navigate = useNavigate();

    useEffect(() => {
        console.log('check if user is allowed to access this page' , props.AllowedRoles.some(allowRole => allowRole == userData.role_id));
    },[])

    return (
        props.AllowedRoles.some(allowRole => allowRole == userData.role_id) ? props.children : null
        )
}

export default RequireSideLink;
