import React, {useEffect, useState} from 'react';
import { useQuery } from 'react-query';
import ConfirmRequest from '../../components/ConfirmRequest/ConfirmRequest';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import fetchGuirdians from '../../redux-toolkit/api/fetchGuirdians';
import GuardiansTable from '../../components/ConfirmRequest/GuardiansTable';
import './Guirdians.css';
import { NavLink } from 'react-router-dom';
import group from'../../assets/AboutUs/Group 408.png'

const Guirdians = (props)=> {

  const [page,setPage] =useState(1);
  const {isLoading, isError, data, error} = useQuery(['guardians',page], fetchGuirdians);

    function handlePageClick (data)
    {
    setPage(data.selected+1);
    }

    return <Layout>
          <div className='container all'>  

          <h1 
          className='pt-0 mb-1 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >أولياء الأمور
          </h1>

          <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
              <h5 className='gre'> أضف ولي أمر</h5> 
              <NavLink to='/addguardian'>
              <img src={group} className="me" />
              </NavLink>
          </div>

        <div className='d-flex justify-content-around mb-5'>


        {
          <GuardiansTable data={data?.data} isLoading={isLoading}/>
        }

          </div>
          {data?.total < 2  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
          
            


        </div>

  
    </Layout>
}

export default Guirdians;