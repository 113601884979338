import React, {useState,useEffect} from 'react';
import Layout from '../../components/Layout/Layout';
import './TeacherDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const TeacherDetails = () => {
    const params = useParams();
    const [teacherDetails,setTeacherDetails] = useState(null);
    const navigate = useNavigate();

    const [editPassword,setEditPassword] = useState(false);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get_teacher_by_id/${params.id}`,{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('teacher detais are',data.data.data)
            setTeacherDetails(data.data.data);
        })
        .catch(error => {
            console.log(error);
        })
    },[]);


    return <>
        <Layout>
        <div className='profileScreen'>
        <div className='profileScreen__content'>
            <div className='topcontent'>
                <div className='userImage'>
                    <img src={`${process.env.REACT_APP_Video_URL}/${teacherDetails?.image}`} style={{borderRadius: '50%',objectFit: 'cover'}} alt="" />
                </div>
                <h3 className='userName'>{teacherDetails?.username}</h3>
            </div>

            
            <div className={`bottomContent`}>

                <div className='leftContent'>
                    <ul>
                        <li><div className='descTeacherTitle'>اسم الاستاذ</div><div className='descTeacherContent'>{teacherDetails?.teacher_name}</div></li>
                        <li><div className='descTeacherTitle'>الايميل</div><div className='descTeacherContent'>{teacherDetails?.email}</div></li>
                        <li><div className='descTeacherTitle'>رقم الهاتف</div><div className='descTeacherContent'>{teacherDetails?.phone_number}</div></li>
                        <li><div className='descTeacherTitle'>الوصف</div><div className='descTeacherContent'>{teacherDetails?.description}</div></li>
                        <li><div className='descTeacherTitle'>حساب التيليجرام</div><div className='descTeacherContent'>{teacherDetails?.telegram_url}</div></li>
                        {/* <li><div>تاريخ الميلاد</div><div>Lorem ipsum</div></li>
                        <li><div>العنوان</div><div>Lorem ipsum</div></li>
                        <li><div>المحافظة</div><div>Lorem ipsum</div></li> */}
                    </ul>
                    <div className='title'>
                        معلومات الاستاذ
                    </div>
                </div>

                

        </div>
        <div className='editBtn__containener'>
            <button className="btn  btn1" onClick={() => navigate(`/editteacherdetails/${teacherDetails?.teacher_id}`)}>تعديل معلومات الاستاذ</button>
        </div>
    </div>
    </div>

        </Layout>
    </>
}

export default TeacherDetails;