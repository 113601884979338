import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './SideBar.css';

import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import SideBarLogic from './SideBarLogic';
import { useSelector } from 'react-redux';
import RequireSideLink from '../RequireSideLink/RequireSideLink';
import axios from 'axios';
import logo from '../../assets/Home/logo.png';

export default function SideBar() {
	const { setOpen, open } = SideBarLogic();
	const userData = useSelector((state) => state.Login);

	const navigate = useNavigate();

	function backendLoggingOut(token) {
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/logout`,
				{},
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((data) => {
				console.log('logout data', data);
			})
			.catch((error) => {
				console.log('logout error', error);
			})
			.finally();
	}

	const handleLogout = (e) => {
		e.preventDefault();
		const token = localStorage.getItem('token');
		localStorage.removeItem('token');
		localStorage.removeItem('role_id');

		backendLoggingOut(token);
		alert('تم تسجيل الخروج بنجاح');
		navigate('/loginasadmin');
		// window.location.reload();
	};

	return (
		<>
			{!open && (
				<GiHamburgerMenu
					onClick={() => setOpen(!open)}
					className='open__close__icon '
				/>
			)}
			<div
				className={open ? 'SideBar aa shadow' : 'SideBar_TY shadow'}
				id='sidebar'>
				{open && (
					<>
						<Link to='/' className='sideBar__user'>
							<img src={logo} style={{ backgroundColor: 'white' }} />
							{/* <span className="brand-text  fw-bold my-2" style={{color:'#1bd3c5'}}><h4>طريقي</h4> </span> */}
						</Link>

						<nav className='mynav-x '>
							<div
								className='nav nav-pills nav-sidebar flex-column'
								data-widget='treeview'
								role='menu'
								data-accordion='false'>
								<li className='nav-item has-treeview menu-open'>
									<div className='nav nav-treeview'>
										<li className='nav-item'></li>

										<RequireSideLink AllowedRoles={[4, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/homepage'
													className='text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-home  nav-icon' />
													</div>
													<p className=' paragraph m-0'>معلومات الموقع</p>
												</NavLink>
											</li>
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[4, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/showSections'
													className='text-white  d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-qrcode  nav-icon' />
													</div>
													<p className=' paragraph m-0'> الأقسام</p>
												</NavLink>
											</li>
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[6, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/students'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-user-circle nav-icon' />
													</div>
													<p className=' paragraph m-0'> الطلاب</p>
												</NavLink>
											</li>
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[6, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/transfers'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														{/* <i className='fa fa-user-circle nav-icon' /> */}
														<i className="fa fa-money nav-icon"></i>
													</div>
													<p className=' paragraph m-0'> الحوالات</p>
												</NavLink>
											</li>
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[4, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/userOpinions'
													className='text-white d-flex align-items-center py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-comments-o nav-icon' />
													</div>
													<p className='paragraph m-0'> أراء الطلاب</p>
												</NavLink>
											</li>
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[6, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/teachers'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-male nav-icon' />
													</div>
													<p className='paragraph m-0'> الأساتذة</p>
												</NavLink>
											</li>
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[6, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/admins'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-male nav-icon' />
													</div>
													<p className='paragraph m-0'> الأدمنز</p>
												</NavLink>
											</li>
										</RequireSideLink>
										{/* 
                <RequireSideLink AllowedRoles={[6,7]}>
                <li className="nav-item text-back" style={{borderBottom:'1px solid grey'}} >
                  
                  
                  <NavLink to='/guirdians' className="text-white  d-flex py-2">
                  <div className='d-flex align-items-center'> <i className="fa fa-male nav-icon" /></div>
                    <p className='paragraph m-0'>  أولياء الأمور</p>
                 </NavLink>


                </li>
                </RequireSideLink> */}

										<RequireSideLink AllowedRoles={[4, 7]}>
											<li
												className='nav-item text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/library'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-book nav-icon' />
													</div>

													<p className=' paragraph m-0'> مكتبة الموقع</p>
												</NavLink>
											</li>
										</RequireSideLink>

										{/* <RequireSideLink AllowedRoles={[4,7]}>
                  <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >
                  
                    <NavLink to='/magazine' className="  d-flex">
                    <div className='d-flex align-items-center'> <i className="fa fa-clipboard nav-icon text-white" /></div>
                      <p className='text-white paragraph'>   مجلة الموقع</p>
                  </NavLink>

                  </li>
                </RequireSideLink> */}

										{/* <RequireSideLink AllowedRoles={[6,7]}>
                <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >
                  
                  
                  <NavLink to='/addstar' className="  d-flex">
                  <div className='d-flex align-items-center'> <i className="fa fa-star nav-icon text-white" /></div>
                    <p className='text-white paragraph'>   نجوم الموقع</p>
                 </NavLink>

                </li>
                </RequireSideLink> */}
										{/* 

                <RequireSideLink AllowedRoles={[4,7]}>
                <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >
                 
                  <NavLink to='/aboutus' className="  d-flex">
                  <div className='d-flex align-items-center'>  <i className="fa fa-exclamation-circle nav-icon text-white" /></div>
                    <p className='text-white paragraph'>   لمحة عنا </p>
                 </NavLink>

                </li>
                </RequireSideLink> */}

										{/*                 
                <RequireSideLink AllowedRoles={[4,7]}>
                  <li className=" my-2 text-white" style={{borderBottom:'1px solid grey'}} >
                    
                    
                    <NavLink to='/offers' className="  d-flex">
                    <div className='d-flex align-items-center'>  <i className="fa fa-calendar-o nav-icon text-white" /></div>
                      <p className='text-white paragraph'>    العروض   </p>
                  </NavLink>

                  </li>
                </RequireSideLink> */}

										{/* <RequireSideLink AllowedRoles={[4,7]}>
                  <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}}  >
                      
                    <NavLink to='/program' className="  d-flex">
                    <div className='d-flex align-items-center'>  <i className="fa fa-table nav-icon text-white" /></div>
                      <p className='text-white paragraph'>   البرنامج  </p>
                  </NavLink>

                  </li>
                </RequireSideLink> */}

										{/* <RequireSideLink AllowedRoles={[5,7]}>
                  <li className="nav-item my-2 text-white" style={{borderBottom:'1px solid grey'}} >
                  
                    <NavLink to='/pay' className="  d-flex">
                    <div className='d-flex align-items-center'> <i className="fa fa-money nav-icon text-white" /></div>
                      <p className='text-white'> طلبات الدفع </p>
                  </NavLink>

                  </li>    
                </RequireSideLink> */}

										<RequireSideLink AllowedRoles={[5, 7]}>
											<li
												className='text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/mainSubscribtions'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-calendar-o nav-icon' />
													</div>
													<p className='paragraph m-0'> الاشتراكات </p>
												</NavLink>
											</li>

											<li
												className='text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/showOffers'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-calendar-o nav-icon' />
													</div>
													<p className='paragraph m-0'> العروض </p>
												</NavLink>
											</li>

											{/* <li className="text-white" style={{borderBottom:'1px solid grey'}}>
                  <NavLink to='/addOffers' className="text-white  d-flex py-2">
                  <div className='d-flex align-items-center'> <i className="fa fa-calendar-o nav-icon" /></div>
                    <p className='paragraph m-0'>   اضف عرض </p>
                 </NavLink>

                </li> */}
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[5, 7]}>
											<li
												className='text-white'
												style={{ borderBottom: '1px solid grey' }}>
												<NavLink
													to='/subscribe'
													className=' text-white d-flex py-2'>
													<div className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-calendar-o nav-icon' />
													</div>
													<p className=' paragraph m-0'> اضف اشتراك </p>
												</NavLink>
											</li>
										</RequireSideLink>

										<RequireSideLink AllowedRoles={[4, 5, 6, 7]}>
											<li
												className='text-white'
												style={{
													borderBottom: '1px solid grey',
													cursor: 'pointer',
												}}
												onClick={handleLogout}>
												<div className='  d-flex py-2'>
													<a className='d-flex align-items-center'>
														{' '}
														<i className='fa fa-user-circle nav-icon' />
													</a>
													<p className=' paragraph m-0'> تسجيل الخروج </p>
												</div>
											</li>
										</RequireSideLink>
									</div>
								</li>
							</div>
						</nav>
					</>
				)}
			</div>
		</>
	);
}
