import React from 'react'
import ReactPaginate from 'react-paginate'
import './Pagination.css'


export default function Pagination({data,page,handlePageClick,total}) 
{

  console.log("pagination Dataaaaaaaaaa" , data);
  console.log('total from pagination componenent',total , 'type of total', typeof total)
  return (
    <>
      {
        Number(total) <= 2 ?
        null :
        <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        pageCount={data?.last_page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={"pagination "}
        activeClassName={"activeitem"}
      />

      }

    </>
  )
}
