import React, { useRef, useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import record from '../../assets/Home/record.png';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Circle } from 'rc-progress';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import { useParams } from 'react-router-dom';
import './EditStudentDetails.css';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';

export default function EditStudentDetails() {
	const [mainVideoFile, setMainVideoFile] = useState('');
	const [aboutUsImage, setAboutUsImage] = useState('');
	const [allSiteInfo, setAllSiteInfo] = useState(null);
	const [progress, setProgress] = useState(0);
	const [IsLoading, setIsLoading] = useState(false);
	const [isUploadSuccessfuly, setIsUploadSuccessfuly] = useState(false);
	const [certificates, setCertificates] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [userName, setUserName] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [studentImageSource, setStudentImageSource] = useState(null);

	const [defauluseFormVlaues, setDefauluseFormValues] = useState({
		image: '',
		name: '',
		username: '',
		// father_name: '',
		// mother_name: '',
		gender: '',
		gender: '',
		// birthday: '',
		// address: '',
		// city: '',
		// school: '',
		// class: '',
		phone_number: '',
		email: '',
		// strong_subjects: '',
		// weaknesses_subjects: '',
		// student_certificates: '',
		// student_languages: '',
		password: '',
	});

	const params = useParams();

	const {
		reset,
		getValues,
		watch,
		setValue,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({ mode: 'onChange', defaultValues: defauluseFormVlaues });

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_student_by_id/${params.id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				console.log('student data', data.data.data);

				const studentDetails = data.data.data;

				setStudentImageSource(data.data.data.image);

				// admin can edit these data
				setValue('image', studentDetails.image);
				// setValue('father_name', studentDetails.father_name);
				// setValue('mother_name', studentDetails.mother_name);
				setValue('gender', studentDetails.gender);
				// setValue('birthday', studentDetails.birthday);
				// setValue('address', studentDetails.address);
				// setValue('city', studentDetails.city);
				// setValue('school', studentDetails.school);
				// setValue('class', studentDetails.class);
				setValue('phone_number', studentDetails.phone_number);

				// setValue('strong_subjects', studentDetails.strong_subjects);
				// setValue('weaknesses_subjects', studentDetails.weaknesses_subjects);

				// admin can not edit these data
				setValue('email', studentDetails.email);
				setEmail(studentDetails.email);

				setValue('username', studentDetails.username);
				setUserName(studentDetails.username);
				setValue('name', studentDetails.name);
				setName(studentDetails.name);

				// setValue('student_certificates', studentDetails.student_certificates);
				// setCertificates(studentDetails.student_certificates);

				// setValue('student_languages', studentDetails.student_languages);
				// setLanguages(studentDetails.student_languages);

				console.log('hook form values', getValues());
			})
			.catch((error) => console.log(error));
	}, []);

	const handleOnSubmit = (data) => {
		// e.preventDefault();
		console.log('data', data);
		console.log('submitting data to server');

		const ourFormData = new FormData();

		ourFormData.append('gender', data.gender);
		ourFormData.append('phone_number', data.phone_number);
		ourFormData.append('name', data.name);
		ourFormData.append('username', data.username);
		// ourFormData.append('class', data.class);
		// ourFormData.append('school', data.school);
		// ourFormData.append('weaknesses_subjects', data.weaknesses_subjects);
		// ourFormData.append('father_name', data.father_name);
		// ourFormData.append('strong_subjects', data.strong_subjects);
		// ourFormData.append('birthday', data.birthday);
		// ourFormData.append('mother_name', data.mother_name);
		// ourFormData.append('address', data.address);
		// ourFormData.append('city', data.city);
		if (typeof data.image[0] != 'string') {
			ourFormData.append('image', data.image[0]);
		}

		if (data.password != '') {
			ourFormData.append('password', data.password);
		}

		if (data.email != '') {
			ourFormData.append('email', data.email);
		}

		for (var pair of ourFormData.entries()) {
			console.log(pair[0] + ', ' + pair[1]);
		}

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/update_student_by_id/${params.id}`,
				ourFormData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},

					onUploadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded / progressEvent.total) * 100
						);
						console.log(progress);
						setProgress(progress);
						setIsLoading(true);
					},
					// onDownloadProgress: (progressEvent) => {
					//   const progress = 50 + (progressEvent.loaded / progressEvent.total) * 100;
					//   console.log(progress);
					//   setProgress(progress);
					// },
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data from post api', data);
					setIsLoading(false);
					setIsUploadSuccessfuly(true);
					setIsSuccess(true);

					setSuccessMessage('تمت عملية التعديل بنجاح');
					setTimeout(() => {
						setIsSuccess(false);
					}, 4000);

					return res;
				} else {
					setIsLoading(false);
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				setIsLoading(false);
				setErrorMessage(error.response.data.message);
				console.log(error.response);
				//  setIsError(error.response.data)
			});
	};

	useEffect(() => {
		if (progress == 100) {
			setIsLoading(false);
		}
	}, [progress]);

	const changeColor = (progress) => {
		if (progress <= 25) {
			return '#ff0707';
		} else if (progress <= 50) {
			return '#ff6907';
		} else if (progress <= 75) {
			return '#ffdb07';
		} else {
			return '#77ff07';
		}
	};

	return (
		<>
			{IsLoading && (
				<div className={`uploadPrgressbar`}>
					<h2>جار رفع الملفات الرجاء الانتظار ... </h2>

					<div
						className='content'
						style={{ dispaly: typeof progress != 'number' ? 'none' : 'flex' }}>
						{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
						<p>{progress}%</p>
						<Circle
							percent={progress}
							strokeWidth={4}
							strokeColor={changeColor(progress)}
						/>
					</div>
				</div>
			)}
			<Layout>
				<div className='container all subject'>
					<ToastSuccessError
						successMessage={successMessage}
						ErrorMessage={ErrorMessage}
						setSuccessMessage={setSuccessMessage}
						setErrorMessage={setErrorMessage}
					/>
					<h1
						className='pt-0 mb-4 pb-4 pt-3'
						style={{
							color: '#044364',
							// borderBottom: '1px solid green'
						}}>
						معلومات الطالب
					</h1>
					<form onSubmit={handleSubmit(handleOnSubmit)}>
						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> صورة الطالب</label>
							</div>
							<RegisterImage
								register={register}
								isUploadSuccessfuly={isUploadSuccessfuly}
								isRequired={false}
								registerWhat={'image'}
								studentImageSource={
									process.env.REACT_APP_Video_URL + studentImageSource
								}
							/>
						</div>

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اسم الطالب</label>
							</div>
							<input
								type='text'
								{...register('name', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>
						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اسم المستخدم</label>
							</div>
							<input
								type='text'
								{...register('username', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>
						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> الايميل</label>
							</div>
							<input
								type='text'
								{...register('email', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						{/* <div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اسم الاب</label>
							</div>
							<input
								type='text'
								{...register('father_name', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اسم الام</label>
							</div>
							<input
								type='text'
								{...register('mother_name', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div> */}

						<div className='m-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> الجنس</label>
							</div>
							<select
								type='text'
								{...register('gender', { required: true })}
								className='shadow w-100 border-0'>
								<option value='MALE'>ذكر</option>
								<option value='FEMALE'>أنثى</option>
							</select>
						</div>

						{/* <div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> تاريخ الميلاد</label>
							</div>
							<input
								type='date'
								{...register('birthday', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> العنوان</label>
							</div>
							<input
								type='text'
								{...register('address', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> المحافظة</label>
							</div>
							<input
								type='text'
								{...register('city', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> المدرسة</label>
							</div>
							<input
								type='text'
								{...register('school', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> الصف</label>
							</div>
							<input
								type='text'
								{...register('class', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div> */}

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> رقم الهاتف</label>
							</div>
							<input
								type='text'
								{...register('phone_number', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						{/* <div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> المواد المميز فيها</label>
							</div>
							<input
								type='text'
								{...register('strong_subjects', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>*/}

						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'>كلمة السر</label>
							</div>
							<input
								type='text'
								{...register("password", { 
								required: false , 
								minLength: 8,
								pattern: {
								value: new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})")
								}
								})} 
								className='shadow w-100 main-input border-0'
							/>
						{errors.password && <p className="text-danger"> كلمة السر ضعيفة ... يجب أن تحتوي أرقام و رموز </p>}

						</div>
						{/*
						<div className='mb-5 border p-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> المواد الضعيف بها</label>
							</div>
							<input
								type='text'
								{...register('weaknesses_subjects', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
						</div>

						<div className='mb-5'>
							{certificates?.length > 0 ? (
								<div className='mb-3 d-flex align-items-center'>
									<img src={record} className='record mx-2' />
									<label className='fw-bold'> الشهادات</label>
								</div>
							) : null} 
							
							<div className='certificatesImaegs_container'>
								{certificates &&
									certificates?.map((certificate, index) => {
										return (
											<img
												title='اضغط لرؤية الصورة بشكل أوضح'
												key={index}
												src={`${process.env.REACT_APP_Video_URL}/${certificate.certificate_image}`}
												alt=''
												className='certificate_image'
												id={certificate.id}
												onClick={() =>
													window.open(
														`${process.env.REACT_APP_Video_URL}/${certificate.certificate_image}`
													)
												}
											/>
										);
									})}
							</div>

							{languages?.length > 0 ? (
								<div className='mb-3 d-flex align-items-center'>
									<img src={record} className='record mx-2' />
									<label className='fw-bold'> اللغات </label>
								</div>
							) : null}
							<div className='language_container'>
								<ul>
									{languages &&
										languages?.length > 0 &&
										languages?.map((language, index) => {
											return <li key={index}>{language.language_id}</li>;
										})}
								</ul>
							</div>
						</div>*/}

						<div className='d-flex justify-content-center mt-5'>
							{!IsLoading ? (
								<button
									className=' btn infos btn1 px-3 py-2 border-0 btn-primary'
									type='submit'>
									<h6>تعديل بيانات الطالب</h6>{' '}
								</button>
							) : (
								<div className='mt-2 d-flex justify-content-center'>
									<div class='spinner-border mx-auto' role='status'>
										<span class='visually-hidden'>Loading...</span>
									</div>
								</div>
							)}
						</div>
					</form>
				</div>
			</Layout>
		</>
	);
}
