import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function TableSubjectSubscribtion({ data, isLoading }) {
	useEffect(() => {
		console.log('data from react query', data);
	}, [data]);

	async function deletePost(id) {
		console.log('iddddd', id);

		const res = axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_subject_from_subscription`,
				{
					headers: {
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					data: {
						subscription_subject_id: id,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data delete Subscribtion', data);
					window.location.reload();
					//   handleDeleteSubject(id);
					return res;
				} else {
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	}
	// setStatus('Delete successful');
	const [idItem, setIdItem] = useState();
	const handleDelete = (id) => {
		// DELETE request using fetch with async/await
		console.log('no id', id);
		setIdItem(id);

		// handleDeleteSubject(id);
	};
	const handleDeleteItem = () => {
		deletePost(idItem);
	};

	return (
		<>
			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> اسم الماده</th>
						<th className='text-center text-white last_child'> العمليات</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={2}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : data ? (
						data.length > 0 &&
						data?.map((item, index) => {
							return (
								<tr key={index}>
									<td className='text-center'>{item.subject_name}</td>
									<td className='text-center'>
										{/* <i className='fa fa-check yes fa-2x'> </i>  */}
										{/* <Link to={`/mainsubscribtion/${item?.subscription_id}`} className="ms-3 btn  btn1">عرض</Link> */}
										<button
											onClick={() => {
												handleDelete(item?.id);
											}}
											className='ms-3 btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#staticBackdrop'>
											حذف
										</button>
										{/* <button onClick={()=>handleDelete(subscribtion?.subscription_id)} className="ms-3 btn  btn2">Delete</button>  */}
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={2} className='text-center py-5 '>
							<div>
								<h5>لايوجد مواد</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				id='staticBackdrop'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
				tabindex='-1'
				aria-labelledby='staticBackdropLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div class='modal-header'>
							<h5 className='modal-title' id='staticBackdropLabel'></h5>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div className='modal-body'>
							هل انت متاكد انك تريد حذف هذه المادة
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn  btn1'
								data-bs-dismiss='modal'>
								لا
							</button>
							<button
								type='button'
								className='btn  btn2'
								data-bs-dismiss='modal'
								onClick={handleDeleteItem}>
								نعم
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
