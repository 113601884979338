import { useEffect, useRef, useState } from 'react';
import './Image.css';
import ImageLogic from './ImageLogic';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getImage } from '../../redux-toolkit/reducers/GetImage/GetImage';
export default function Image({
	valid,
	subscribtionPage,
	register,
	id,
	AddSubscribtion,
}) {
	const { cardref, btnref, onImageChange } = ImageLogic(valid);

	const [link, setLink] = useState(null);

	const paramsId = id || 0;

	const [imgLink, setImgLink] = useState(null);

	useEffect(() => {
		if (paramsId) {
			setImgLink(subscribtionPage?.data?.subscription_image);
		}
	}, [subscribtionPage]);

	let img = null;
	if (paramsId) {
		img = `${process.env.REACT_APP_Video_URL}/${imgLink}`;
	}

	const handleImage = (e) => {
		const [file] = e.target.files;
		setLink(URL.createObjectURL(file));
	};

	useEffect(() => {
		img = null;
		setLink(null);
	}, [AddSubscribtion]);

	return (
		<>
			<div
				className='bg-white    shadow  mb-4 d-flex finals justify-content-center parent_btn'
				ref={cardref}>
				<img
					src={link || img}
					style={{ width: '16rem', height: '16rem', margin: 'auto' }}
				/>
				<div className='button  m-auto add-div btn_child' ref={btnref}>
					<i class='fa fa-plus '></i>
					<div>
						<input
							type='file'
							className='ool'
							multiple
							accept='image/*'
							{...register('subscription_image', {
								required: id ? false : true,
							})}
							onChange={handleImage}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
