import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
// import Image from '../Image/Image'
import SubSectionsImage from './SubSectionsImage';
import record from '../../assets/Home/record.png';
import { useForm } from 'react-hook-form';
import SubSectionsLogic from './SubSectionsLogic';
import Layout from '../Layout/Layout';

import './SubSections.css';

import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import { Circle } from 'rc-progress';

export default function SubSections({ handleAdd, subscribtionPage }) {
	const [success, setSuccess] = useState(false);
	const [faild, setFaild] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');

	const params = useParams();
	console.log('paramsss', params);
	const id = params?.id;

	const location = useLocation();
	const AddSubId = location?.state?.id;

	console.log('locationnn', location?.state);
	const [idTeacher, setIdTeacher] = useState(null);
	const [subSectionId, setSubSectionId] = useState(null);

	const [teachers, setTeachers] = useState([]);
	const [subSubSections, setSubSetSubSections] = useState([]);
	const [SubSelectedSection, setSelectedSubSection] = useState([]);
	const [SubSections, setSubSections] = useState([]);
	const [selectedSection, setSelectedSection] = useState(null);

	const {
		reset,
		getValues,
		formState,
		watch,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			sub_section_name: '',
			sub_section_image: null,
		},
	});

	const {
		valid,
		handleSubmitApi,
		handleUpdateApi,
		AddSubscribtion,
		progress,
		IsLoading,
	} = SubSectionsLogic(
		subscribtionPage,
		id,
		reset,
		idTeacher,
		subSectionId,
		AddSubId,
		setSuccessMessage,
		setErrorMessage,
		setSuccess,
		setFaild
	);

	// const [selectedTeacher , setSelectedTeacher]= useState(null)

	useEffect(() => {
		console.log('iddd ٍsubsections', id);
		if (id) {
			const SubSectionsFromAPI = axios
				.get(
					`${process.env.REACT_APP_API_URL}/get_sub_section_by_sub_section_id/${id}?limit=19`
				)
				.then((data) => {
					console.log('log SubSectionssssssssss', data.data);
					setSubSections(data.data.data);
					reset(data.data.data);
				});
		} else console.log('idddElse', id);
	}, []);

	// useEffect(() => {
	//   // console.log('section changed')
	//   // console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_SubSections_by_section_id/${selectedSection}`)
	//   const subSubSectionsAPI = axios.get(`${process.env.REACT_APP_API_URL}/get_sub_SubSections_by_section_id/${id}?limit=19`)
	//   .then(data => {
	//     console.log('subsection ', data.data.data);
	//     setSubSetSubSections(data.data.data.data);
	//   })
	// }, [selectedSection])

	const tempId = id || 0;
	console.log('tempIDdd', tempId);
	// console.log("Subscribtionnnnnn nameeeeeeeee", getValues().subscription_name);

	const changeColor = (progress) => {
		if (progress <= 25) {
			return '#ff0707';
		} else if (progress <= 50) {
			return '#ff6907';
		} else if (progress <= 75) {
			return '#ffdb07';
		} else {
			return '#77ff07';
		}
	};

	return (
		<Layout>
			<div className='container subject'>
				<ToastSuccessError
					successMessage={successMessage}
					ErrorMessage={ErrorMessage}
					setSuccessMessage={setSuccessMessage}
					setErrorMessage={setErrorMessage}
				/>

				{IsLoading && (
					<div className={`uploadPrgressbar`}>
						<h2>جار إضافة القسم الفرعي ... </h2>

						<div
							className='content'
							style={{
								dispaly: typeof progress != 'number' ? 'none' : 'flex',
							}}>
							{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
							<p>{progress}%</p>
							<Circle
								percent={progress}
								strokeWidth={4}
								strokeColor={changeColor(progress)}
							/>
						</div>
					</div>
				)}
				<h1
					className=' '
					style={{
						color: '#044364',
						// borderBottom: '1px solid green'
					}}>
					إضافة قسم فرعي
				</h1>
				<form onSubmit={handleSubmit(handleSubmitApi)}>
					<div className='mb-3 '>
						<div className='mb-3 d-flex align-items-center gap-5 pt-5'>
							<img src={record} className='record mx-2' />
							<label className='fw-bold'> صورة القسم </label>
						</div>
						<SubSectionsImage
							valid={valid}
							register={register}
							id={id}
							subscribtionPage={SubSections}
							AddSubscribtion={AddSubscribtion}
							errors={errors}
							getValues={getValues}
						/>
					</div>
					<div className='mb-3'>
						<div className='mb-3 d-flex align-items-center'>
							<img src={record} className='record mx-2' />
							<label className='fw-bold'> اسم القسم</label>
						</div>
						<div className='d-flex '>
							<input
								type='text'
								className='shadow w-50 py-2 border-0'
								{...register('sub_section_name', { required: true })}
							/>
							{errors.name && <p className='text-danger'>*required</p>}
						</div>
					</div>

					<div className='d-flex justify-content-center mb-5'>
						{tempId ? (
							<button
								className=' btn btn1 '
								style={{ borderRadius: '5px' }}
								//  onClick={()=>handleUpdateApi(getValues() , id)}
							>
								<h6>تعديل </h6>
							</button>
						) : (
							<button
								style={{ borderRadius: '5px' }}
								className=' info  pb-2 pt-3 px-5 border-0 btn1'>
								<h6>إضافة </h6>
							</button>
						)}
					</div>

					{/* <h1>{formState.errors?.content }</h1> */}
				</form>
			</div>
		</Layout>
	);
}
