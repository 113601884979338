import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Layout from '../Layout/Layout';
import Pagination from '../Pagination/Pagination';
import StudentsTable from '../ConfirmRequest/StudentsTable';
import fetchStudents from '../../redux-toolkit/api/fetchStudents';
import fetchUnActiveStudents from '../../redux-toolkit/api/fetchUnActiveStudents';
import fetchNewStudents from '../../redux-toolkit/api/fetchNewStudents';
import fetchActiveStudents from '../../redux-toolkit/api/fetchActiveStudents';
import group from '../../assets/AboutUs/Group 408.png';
import { useQuery } from 'react-query';
import UnActiveStudentsTable from '../ConfirmRequest/UnActiveStudentsTable';
import NewStudentsTable from '../ConfirmRequest/NewStudentsTable';
import ActiveStudentsTable from '../ConfirmRequest/ActiveStudentsTable';
import { debounce } from 'lodash';
import './Student.css';

export default function Student() {
	const [page, setPage] = useState(
		+localStorage.getItem('allstudents_pag') == null
			? 1
			: Number(+localStorage.getItem('allstudents_pag'))
	);

	const [pageUnActive, setPageUnActive] = useState(
		+localStorage.getItem('unactivestudents_pag') == null
			? 1
			: Number(+localStorage.getItem('unactivestudents_pag'))
	);

	const [pageNew, setpageNew] = useState(
		+localStorage.getItem('newstudents_pag') == null
			? 1
			: Number(+localStorage.getItem('newstudents_pag'))
	);

	const [pageActive, setPageActive] = useState(
		+localStorage.getItem('activestudents_pag') == null
			? 1
			: Number(+localStorage.getItem('activestudents_pag'))
	);

	const [typeOfTable, setTypeOfTable] = useState(
		localStorage.getItem('typeOfTable') == null
			? 1
			: localStorage.getItem('typeOfTable')
	);

	const [searchResult, setSearchResult] = useState('');
	const [selectedStudent, setSelectedStudent] = useState('');
	const inputSearchRef = useRef();

	const [query, setQuery] = useState('');
	const [query2, setQuery2] = useState('');
	const [query3, setQuery3] = useState('');
	const [query4, setQuery4] = useState('');

	React.useEffect(() => {}, [query, query2, query3, query4]);

	let shouldSetQuery = true;

	const unOrderStudentsList = useRef();
	const [stuentId, setStduentId] = useState();

	// localStorage.setItem('typeOfTable', 1);

	const { isLoading, isError, data, error } = useQuery(
		['students', page, query, typeOfTable],
		fetchStudents
	);

	const {
		isLoading: IsLoadingNew,
		isError: isErrorNew,
		data: dataNew,
		error: errorNew,
	} = useQuery(['newstudents', pageNew, query3, typeOfTable], fetchNewStudents);

	const {
		isLoading: isLoadingActive,
		isError: isErrorActive,
		data: dataActive,
		error: errorActive,
	} = useQuery(
		['activeStudents', pageActive, query4, typeOfTable],
		fetchActiveStudents
	);

	const {
		isLoading: loadingUn,
		isError: isErrorUn,
		data: dataUn,
		error: errorUn,
	} = useQuery(
		['unactivestudents', pageUnActive, query2, typeOfTable],
		fetchUnActiveStudents
	);

	useEffect(() => {
		// if(typeOfTable != 1 && typeOfTable != null){
		//   localStorage.removeItem('allstudents_pag')
		// }

		if (typeOfTable) {
			resetAllStudentsPageToZero();
		}

		// reset the query param when ever the user change the type of table wich is displayed
		setQuery('');
		setQuery2('');
		setQuery3('');
		setQuery2('');

		inputSearchRef.current.value = '';
	}, [typeOfTable]);

	const setQueryOnTablesType = (value) => {
		if (typeOfTable == 1) {
			setQuery(value);
		} else if (typeOfTable == 2) {
			setQuery2(value);
		} else if (typeOfTable == 3) {
			setQuery3(value);
		} else if (typeOfTable == 4) {
			setQuery4(value);
		}
	};

	const setQueryNotOnEveryChnage = (value) => {
		if (shouldSetQuery) {
			setQueryOnTablesType(value);
			shouldSetQuery = false;
		}

		setTimeout(() => {
			shouldSetQuery = true;
		}, 1000);
	};

	const handleSearchStudent = (e) => {
		const value = e.target.value;
		unOrderStudentsList.current.style.height = 'auto';

		setQueryNotOnEveryChnage(value);
		handleOnFucusInput();
		handleUnorderStudentsList(e.target.value);

		// setValue('student_id',e.target.value);
	};

	const devouncedFn = debounce(handleSearchStudent, 500);

	const handleUnorderStudentsList = (searchQuery) => {
		if (searchQuery == '') {
			unOrderStudentsList.current.style.height = '0px';
		} else {
			unOrderStudentsList.current.style.height = 'auto';
		}
	};

	function handleOnFucusInput() {
		if (typeOfTable == 1) {
			localStorage.setItem('allstudents_pag', 0);
			setPage(0);
		}

		if (typeOfTable == 2) {
			localStorage.setItem('unactivestudents_pag', 0);
			setPageUnActive(0);
		}

		if (typeOfTable == 3) {
			localStorage.setItem('newstudents_pag', 0);
			setpageNew(0);
		}

		if (typeOfTable == 4) {
			localStorage.setItem('activestudents_pag', 0);
			setPageActive(0);
		}
	}

	function handlePageClick(data) {
		localStorage.setItem('allstudents_pag', data.selected);
		setPage(data.selected);
	}

	function handlePageUnActiveClick(dataUn) {
		localStorage.setItem('unactivestudents_pag', dataUn.selected);
		setPageUnActive(dataUn.selected);
	}

	function handlePageNewClick(dataNew) {
		localStorage.setItem('newstudents_pag', dataNew.selected);
		setpageNew(dataNew.selected);
	}

	function handlePageActiveClick(dataActive) {
		localStorage.setItem('activestudents_pag', dataActive.selected);
		setPageActive(dataActive.selected);
	}

	const resetAllStudentsPageToZero = () => {
		localStorage.setItem('newstudents_pag', 0);
		localStorage.setItem('allstudents_pag', 0);
		localStorage.setItem('activestudents_pag', 0);
		localStorage.setItem('unactivestudents_pag', 0);
	};

	return (
		<Layout>
			<div className='container all mb-5'>
				<h1
					className='pt-0 mb-4 pb-4 pt-3'
					style={{
						color: '#044364',
						// borderBottom: '1px solid green'
					}}>
					الطلاب
				</h1>

				<div className='d-flex justify-content-center mb-3 border w-auto dot p-4 flex-column align-items-center mt-4 '>
					<h5 className='gre'> أضف طالب</h5>
					<NavLink to='/addstudent'>
						<img src={group} className='me' />
					</NavLink>
				</div>

				<div className='d-flex justify-content-evenly my-3'>
					<button
						className={`btn  colorDerk shadow border rounded  ${
							typeOfTable == 1 ? 'activeTableBtn' : ''
						} `}
						onClick={() => {
							setTypeOfTable(1);
							localStorage.setItem('typeOfTable', 1);
						}}>
						جميع الحسابات
					</button>

					<button
						className={`btn colorDerk shadow border rounded ${
							typeOfTable == 4 ? 'activeTableBtn' : ''
						} `}
						onClick={() => {
							setTypeOfTable(4);
							localStorage.setItem('typeOfTable', 4);
						}}>
						الحسابات المفعلة
					</button>

					<button
						className={`btn colorDerk shadow border rounded ${
							typeOfTable == 2 ? 'activeTableBtn' : ''
						} `}
						onClick={() => {
							setTypeOfTable(2);
							localStorage.setItem('typeOfTable', 2);
						}}>
						الحسابات الغير مفعلة
					</button>

					{/* <button
						className={`btn colorDerk shadow border rounded ${
							typeOfTable == 3 ? 'activeTableBtn' : ''
						} `}
						onClick={() => {
							setTypeOfTable(3);
							localStorage.setItem('typeOfTable', 3);
							localStorage.setItem('newstudents_pag', 0);
						}}>
						الحسابات الجديدة
					</button> */}
				</div>

				<input
					type='text'
					ref={inputSearchRef}
					placeholder='اكتب هنا للبحث عن طالب'
					onChange={(e) => devouncedFn(e)}
					className='shadow w-100 main-input border-0'
				/>

				<div className='students_select mb-2 rounded'>
					<ul className='unorder_students_list' ref={unOrderStudentsList}>
						{searchResult &&
							searchResult?.map((student, index) => {
								return (
									<li
										//  onClick={() => handleSelectStudentFromDrobDownList(student)}
										value={student.student_id}
										key={index}>
										{student.name}
									</li>
								);
							})}
					</ul>

					{/* <div className='text-center my-5'>
            <button className='btn btn-primary' onClick={handleAddStudent}>اضف طالب</button>
       </div> */}
				</div>

				{typeOfTable == 1 && (
					<div
						className='alert my-4 border shadow rounded mx-auto fs-4'
						style={{ maxWidth: '500px' }}>
						{query == ''
							? 'عدد الطلاب الإجمالي:'
							: 'عدد الطلاب المطابق لعملية البحث:'}{' '}
						<strong>{data?.data.total}</strong>
					</div>
				)}

				{typeOfTable == 4 && (
					<div
						className='alert my-4 border shadow rounded text-center mx-auto fs-4'
						style={{ maxWidth: '500px' }}>
						{query == ''
							? 'عدد الطلاب الإجمالي:'
							: 'عدد الطلاب المطابق لعملية البحث:'}{' '}
						<strong>{dataActive?.data.total}</strong>
					</div>
				)}

				{typeOfTable == 3 && (
					<div
						className='alert my-4 border shadow rounded text-center mx-auto fs-4'
						style={{ maxWidth: '500px' }}>
						{query == ''
							? 'عدد الطلاب الإجمالي:'
							: 'عدد الطلاب المطابق لعملية البحث:'}{' '}
						<strong>{dataNew?.data.total}</strong>
					</div>
				)}

				{typeOfTable == 2 && (
					<div
						className='alert my-4 border shadow rounded text-center mx-auto fs-4'
						style={{ maxWidth: '500px' }}>
						{query == ''
							? 'عدد الطلاب الإجمالي:'
							: 'عدد الطلاب المطابق لعملية البحث:'}{' '}
						<strong>{dataUn?.data.total}</strong>
					</div>
				)}

				<div className='d-flex justify-content-around mb-5'>
					{typeOfTable == 1 ? (
						<StudentsTable data={data?.data} isLoading={isLoading} />
					) : null}

					{typeOfTable == 2 ? (
						<UnActiveStudentsTable data={dataUn?.data} isLoading={loadingUn} />
					) : null}

					{/* {typeOfTable == 3 ? (
						<NewStudentsTable data={dataNew?.data} isLoading={IsLoadingNew} />
					) : null} */}

					{typeOfTable == 4 ? (
						<ActiveStudentsTable
							data={dataActive?.data}
							isLoading={isLoadingActive}
						/>
					) : null}
				</div>

				{typeOfTable == 1 ? (
					data?.data.total < 1 ? (
						<> </>
					) : (
						<Pagination
							data={data?.data}
							handlePageClick={handlePageClick}
							page={page}
						/>
					)
				) : null}

				{typeOfTable == 2 ? (
					dataUn?.data.total < 1 ? (
						<></>
					) : (
						<Pagination
							total={dataUn?.total}
							data={dataUn?.data}
							handlePageClick={handlePageUnActiveClick}
							page={pageUnActive}
						/>
					)
				) : null}

				{typeOfTable == 3 ? (
					dataNew?.data.total < 1 ? (
						<></>
					) : (
						<Pagination
							total={dataNew?.total}
							data={dataNew?.data}
							handlePageClick={handlePageNewClick}
							page={pageNew}
						/>
					)
				) : null}

				{typeOfTable == 4 ? (
					dataActive?.data.total < 1 ? (
						<></>
					) : (
						<Pagination
							total={dataActive?.total}
							data={dataActive?.data}
							handlePageClick={handlePageActiveClick}
							page={pageActive}
						/>
					)
				) : null}
			</div>
		</Layout>
	);
}
