import { useState } from 'react';
import { Link } from 'react-router-dom';
import Image from '../Image/Image';
import Layout from '../Layout/Layout';
import Select from '../Select/Select';
import './Subscribe.css';
import SubscribeLogic from './SubscribeLogic';
import group from '../../assets/AboutUs/Group 408.png';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

export default function Subscribe() {
	const { handleAdd, number, subscribtionPage, id } = SubscribeLogic();
	console.log('subscritbtion home page', subscribtionPage);

	return (
		<>
			<Layout>
				<div className='container all'>
					{number.map((e, index) => {
						return <Select subscribtionPage={subscribtionPage} id={id} />;
					})}
				</div>
			</Layout>
		</>
	);
}
