import React, { Component, useState } from 'react';
import Menu from '../../Menu';
import './Header.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import Logo from '../../assets/Home/logo.png';

export default function Header() {
	return (
		<>
			{/* <nav
				className='row  main-header  navbar navbar-expand position-fixed navbar-light'
				style={{
					width: '100% ',

					justifyContent: 'space-between',
				}}>
				<ul className='navbar-nav '>
					<li className='nav-item dropdown col-sm-8 col-lg-6 mt-3'>
						<GiHamburgerMenu
							className='fs-2'
							color
							onClick={() => {
								document.getElementById('sidebar').classList.toggle('aa');
							}}
						/>
					</li>
					<li className='nav-item dropdown  col-sm-3 col-lg-6 text-left'>
						<img
							src={Logo}
							alt='myway-logo'
							style={{
								width: '180px',
								// marginBottom: "29px",
								// marginLeft: "-30px",
								zIndex: '-1',
							}}
						/>
					</li>

				</ul>
			</nav> */}

			<div className='header_middleest_admin'>
				<GiHamburgerMenu
					className='fs-2'
					color
					style={{ cursor: 'pointer' }}
					onClick={() => {
						document.getElementById('sidebar').classList.toggle('aa');
					}}
				/>
				<a href='/'>
					<img
						src={Logo}
						alt='myway-logo'
						style={{
							width: '180px',
							// marginBottom: "29px",
							// marginLeft: "-30px",
							// zIndex: '-1',
						}}
					/>
				</a>
			</div>
		</>
	);
}
