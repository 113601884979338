
import { useLocation , Navigate , Outlet, useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const RequireAuth = ({AllowedRoles}) => {

    const location = useLocation();
    const userData = useSelector(state => state.Login)
    const navigate = useNavigate();

    // const checkIFuserIncluded =  () => {
    //     for(let i = 0 ; i < AllowedRoles.lenght; i++){
    //         if(userData.role_id == AllowedRoles[i]){
    //             console.log('print it out that condition is occured')
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    useEffect(() => {
        console.log('check if user is allowed to access this page' , AllowedRoles.some(allowRole => allowRole == userData.role_id));
    },[])
    // useEffect(() => {
    //     console.log('required auth', userData.role_id);
    // },[])

    return (
        AllowedRoles.some(allowRole => allowRole == userData.role_id) ? <Outlet /> : (

            userData?.token != ('' || null ) ? 


            <Navigate to={'/unauthorized'} state={{from: location}} replace /> 
                :
            <Navigate to={'/loginAsAdmin'} state={{from: location}} replace />
            )
        )
}

export default RequireAuth;

// checkIFuserIncluded() ? <Outlet /> : (

//     !userData?.token !== ('' || null ) ? 


//     <Navigate to={'/welcomAdmin'} state={{from: location}} replace /> 
//         :
//     <Navigate to={'/loginAsAdmin'} state={{from: location}} replace />
//     )


// react_devtools_backend.js:4026 Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect, but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render.
//     at Navigate (http://localhost:3000/static/js/bundle.js:135740:5)
//     at RequireAuth (http://localhost:3000/main.c028acc3c9b0c121acc7.hot-update.js:31:5)
//     at Routes (http://localhost:3000/static/js/bundle.js:135857:5)
//     at Router (http://localhost:3000/static/js/bundle.js:135790:15)
//     at BrowserRouter (http://localhost:3000/static/js/bundle.js:134600:5)
//     at QueryClientProvider (http://localhost:3000/static/js/bundle.js:131226:21)
//     at App (http://localhost:3000/static/js/bundle.js:93:52)
//     at Provider (http://localhost:3000/static/js/bundle.js:131923:5)