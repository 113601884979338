// subjects for subscribtionsss

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
export default function TableSubjectsForStudent({
	data,
	isLoading,
	page,
	setStatus,
	setPage,
}) {
	const params = useParams();
	const id_student = params?.id;

	console.log('dataaaaaaaa subject for student', data);

	const [dataSubject, setDataSubject] = useState(data);
	const [flag, setFlag] = useState(0);
	const [minusPage, setMinusPage] = useState(0);
	const [ErrorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	//  if(dataSubject.length===0 && flag===1){
	//     window.location.reload();
	// }

	const handleDeleteSections = (id) => {
		console.log('idddddd subject', id);
		const temp = [...dataSubject];
		const DataDelte = temp.filter((item) => item.subject_id !== id);
		console.log('Data Deleteeed', DataDelte);
		setDataSubject(DataDelte);
		if (DataDelte.length === 0 && page > 1) {
			setPage(page - 1);
			setMinusPage(0);
			setStatus(1);
		}
		setFlag(1);
	};

	useEffect(() => {
		console.log('data from react query', data);
		setDataSubject(data);
	}, [data]);

	async function deletePost(id) {
		console.log('iddddd_subject', id);
		console.log('id_student', id_student);

		const res = axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_student_subject_by_subject_id_and_student_id`,
				{
					headers: {
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					data: {
						student_id: id_student,
						subject_id: id,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data delete Subscribtion', data);
					handleDeleteSections(id);
					setSuccessMessage('تمت عملية الحذف بنجاح');
					return res;
				} else {
					console.log(res);
					setErrorMessage('غير متصل بالشبكة');
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
				setErrorMessage(error.response.data.message);
			});
	}
	// setStatus('Delete successful');
	const [idDelete, setIdDelete] = useState();
	const handleDelete = (id) => {
		// DELETE request using fetch with async/await
		console.log('no id', id);
		setIdDelete(id);
		// deletePost(id);
		// handleDeleteSections(id);
	};

	const handleDeleteItem = () => {
		deletePost(idDelete);
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> المواد</th>
						<th className='text-center text-white last_child'> العمليات</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={2}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : dataSubject ? (
						dataSubject.length > 0 &&
						dataSubject?.map((item, index) => {
							return (
								<tr key={index}>
									<td className='text-center'>{item?.subject_name}</td>
									<td className='text-center'>
										{/* <i className='fa fa-check yes fa-2x'> </i>  */}
										{/* <Link to={`/showSubSections/${item?.offer_id}`} state={{sectionId: item?.section_id}} className="ms-3 btn  btn1">عرض</Link> */}
										<button
											onClick={() => handleDelete(item?.subject_id)}
											className='ms-3 btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#staticBackdrop'>
											حذف
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={2} className='text-center py-5 '>
							<div>
								<h5>لايوجد مواد</h5>{' '}
							</div>
						</td>
					)}
				</tbody>
			</table>

			{/* <button type="button" className="btn  btn1" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  Launch static backdrop modal
</button> */}

			<div
				className='modal fade'
				id='staticBackdrop'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
				tabindex='-1'
				aria-labelledby='staticBackdropLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div class='modal-header'>
							<h5 className='modal-title' id='staticBackdropLabel'></h5>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div className='modal-body'>هل انت متاكد انك تريد الحذف</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn  btn1'
								data-bs-dismiss='modal'>
								لا
							</button>
							<button
								type='button'
								className='btn  btn2'
								data-bs-dismiss='modal'
								onClick={handleDeleteItem}>
								نعم
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
