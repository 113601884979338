import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import Layout from '../Layout/Layout';
import Pagination from '../Pagination/Pagination';
import TableSections from '../ConfirmRequest/TableSections';
import ShowSubjectLogic from './ShowSubjectsLogic';
import SubjectsSubSections from '../ConfirmRequest/SubjectsSubSections';

const ShowSubjects = () => {
	const params = useParams();
	const id = params?.id;

	console.log('YESSSSSSSSSS');
	const { listRef, isLoading, isError, data, error, handlePageClick, page } =
		ShowSubjectLogic(id);
	console.log('subjectssss Dataaaa', data);
	const dataSubject = data?.data;
	const [status, setStatus] = useState();

	const { state } = useLocation();

	useEffect(() => {
		console.log(
			'subsectinID =>',
			state.subSectionId,
			'sectionID =>',
			state.sectionId,
			'sectioname =>',
			state.sub_section_name
		);
	}, []);

	return (
		<>
			<Layout>
				<div className='container all'>
					<h1
						className='   pt-3'
						style={
							{
								// borderBottom: '1px solid green'
							}
						}>
						{state?.sub_section_name}
					</h1>
					<div className='d-flex justify-content-around mb-5'></div>

					{
						<SubjectsSubSections
							section_subSection={{
								sectionId: state.sectionId,
								subSectionId: state.subSectionId,
							}}
							data={dataSubject}
							isLoading={isLoading}
						/>
					}

					{/* {data?.total < 1  ? <> </> : <Pagination  data={data} handlePageClick={handlePageClick} page={page} />  }   */}
				</div>
			</Layout>
		</>
	);
};

export default ShowSubjects;
