import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';

export default function ConfirmRequest({ data, isLoading }) {
	const navigate = useNavigate();
	const [currentGuirdians, setCurrentGuirdians] = useState([]);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [Isloading, setIsLoading] = useState(false);
	const modalRef = useRef(null);
	const closeModalBtn = useRef();
	const [selectedGuardinId, setSelectedGuardianId] = useState();

	// http://java-course.online/api/get_all_un_active_students?limit=20

	useEffect(() => {
		console.log('data from react query', data?.data);
		setCurrentGuirdians(data?.data);
	}, [data]);

	const handleDeleteTeacher = (e, id) => {
		e.stopPropagation();
		console.log('id guardian to delete is => ', id);

		setIsLoading(true);

		axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_guardian_by_id/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				setIsLoading(false);
				console.log(data);
				const newCurrentGuirdians = currentGuirdians.filter(
					(currentGuirdian) => currentGuirdian.guardian_id != id
				);
				setCurrentGuirdians([...newCurrentGuirdians]);

				setSuccessMessage('تمت  عملية الحذف بنجاح');

				modalRef.current.style.display = 'none';
				closeModalBtn.current.click();
			})
			.catch((error) => {
				console.log(error);
				setIsLoading(false);
				setErrorMessage('حدث خطأ ما');
				modalRef.current.style.display = 'none';
				closeModalBtn.current.click();
			});
	};

	const selectedGuardianId = (e, id) => {
		e.stopPropagation();
		console.log('id guardian', id);
		setSelectedGuardianId(id);
	};

	return (
		<div className='tableContainer'>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'>
							{' '}
							اسم ولي الامر
						</th>
						<th className='text-center text-white'> اسم المستخدم</th>
						<th className='text-center text-white'> الايميل</th>
						<th className='text-center text-white'> رقم الهاتف</th>
						<th className='text-center text-white last_child'>
							العمليات المتاحة
						</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={5}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : currentGuirdians && currentGuirdians.length > 0 ? (
						currentGuirdians.map((guardian, index) => {
							return (
								<tr
									key={index}
									onClick={() => {
										navigate(`/guardian/${guardian.guardian_id}`);
									}}
									style={{
										cursor: 'pointer',
										borderBottom: '1px solid #1a1a1a1a',
									}}
									className='tableRowHover'>
									<td className='text-center'>{guardian.name}</td>
									<td className='text-center'>{guardian.username}</td>
									<td className='text-center'>{guardian.email}</td>
									<td className='text-center'>{guardian.phone_number}</td>
									<td className='text-center'>
										<button
											type='button'
											className='btn  btn1'
											onClick={(e) => {
												e.stopPropagation();
												navigate(`/sons/${guardian.guardian_id}`, {
													state: { guardianData: guardian },
												});
											}}>
											الأبناء
										</button>
										<button
											type='button'
											className='btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={(e) =>
												selectedGuardianId(e, guardian.guardian_id)
											}>
											حذف
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={5} className='text-center py-5 '>
							<div>
								<h5>ليس هنالك اولياء امور بعد</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				ref={modalRef}
				id='exampleModal'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title mx-auto' id='exampleModalLabel'>
								{Isloading ? 'جاري الحذف ...' : 'هل أنت متأكد ؟'}
							</h5>
						</div>

						{Isloading && (
							<div className='modal-body'>
								{Isloading && (
									<div className='mb-3 d-flex justify-content-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='visually-hidden'>Loading...</span>
										</div>
									</div>
								)}
							</div>
						)}

						{!Isloading && (
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									className='btn btn-secondary'
									data-bs-dismiss='modal'
									ref={closeModalBtn}>
									تجاهل
								</button>

								<button
									type='button'
									className='btn  btn2'
									onClick={(e) => handleDeleteTeacher(e, selectedGuardinId)}>
									حذف
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
