import React, { useEffect, useRef , useState } from 'react'
import axios from "axios";

import { useDispatch } from 'react-redux';
import { getImage } from '../../redux-toolkit/reducers/GetImage/GetImage';
import { useNavigate } from 'react-router-dom';



export  default function SelectLogic(subscribtionPage,id,reset,setSuccessMessage,setErrorMessage) {

//remove the image from input file
  const [AddSubscribtion , setAddSubscribtion] = useState(false);
  const navigate = useNavigate()

  const dispatch = useDispatch();
  console.log("iddd",id);
  const tempId = id || 0
  console.log("tempIDDsdfdsfjhj",tempId);
      const [valid , SetValid] = useState(false);
    const bach=useRef(null);
    const bache=useRef(null);
    const bachee=useRef(null);

  
    useEffect(()=>{
             setAddSubscribtion(false);
    },[])       

    const handleSubmitApi=(data)=>{


 
      if(id){
        console.log("Update")
      let formData = new FormData();
      // setImage(data.image[0]);
      console.log("image Select Logic",typeof data.subscription_image);
      console.log("name",data.subscription_name)

      // dispatch(getImage(data.subscription_image[0]));
      console.log("NO", data.subscription_image[0])
    
      if(typeof data.subscription_image !=="string" ){
       formData.append("image",data.subscription_image[0])
       console.log("IMAGES is string" );
      }
      formData.append("name",data.subscription_name)
      formData.append("price" , Math.round(data.subscription_price));
      formData.append("description" ,data.subscription_description );
   
      console.log("tempID");
       formData.append("subscription_id" ,id);
       const res = axios.post(`${process.env.REACT_APP_API_URL}/edit_subscription`, formData, {
         headers: {
           'content-type': 'text/json',
           "Content-Type": "multipart/form-data",
           "Accept": "application/json",
           'Content-Encoding': 'gzip , deflate, br',
           "Authorization":`Bearer ${localStorage.getItem('token')}`
         }
       }).then(res=>{
         if(res.status===200){
           const {data} = res;
           SetValid(true);
           console.log("data EDIT Subscribtion" , data);
           setSuccessMessage("تم التعديل بنجاح")
           navigate('/mainSubscribtions')
           window.location.reload();
           return res;
         }
         else
         {
           console.log(res);
           setErrorMessage("غير متصل بلشبكه");
           throw new Error('there is an error')
         }
       }).catch(error=>{
        setErrorMessage(error.response.data.message);
         console.log(error.response)
       });

      }
      else{
      console.log("submit")
      let formData = new FormData();
      // setImage(data.image[0]);
      console.log("image Select Logic", data.subscription_image);
      console.log("name",data.subscription_name)

      // dispatch(getImage(data.subscription_image[0]));
      
      formData.append("image",data.subscription_image[0])
      formData.append("name",data.subscription_name)
      formData.append("price" , Math.round(data.subscription_price));
      formData.append("description" ,data.subscription_description );

    // console.log('hello',formData)
    for(var pair of formData.entries()) { 
      console.log("form Data", pair[0]+ ', '+ pair[1]);  
 }

 const res = axios.post(`${process.env.REACT_APP_API_URL}/create_subscription` , formData, {
  headers: {
    'content-type': 'text/json',
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    'Content-Encoding': 'gzip , deflate, br',
    "Authorization":`Bearer ${localStorage.getItem('token')}`
  }
}).then(res=>{
  if(res.status===200){
    const {data} = res;
    SetValid(true);
    reset({subscription_name:'',
   subscription_price:'',
   subscription_description:'',
   subscription_image:null})
  //  window.location.reload();
   setAddSubscribtion(true);
   setSuccessMessage("تمت  التسجيل بنجاح")
   console.log("data Post Subscribtion" , data);
   navigate('/mainSubscribtions')

    window.location.reload();
    return res;
  }
  else
  {
    console.log(res);
    setErrorMessage("غير متصل بالشبكه");
    throw new Error('there is an error')
  }
}).catch(error=>{
  console.log(error.response)
  // setErrorMessage(error.message);
  setErrorMessage(error.response.data.message);
  //  setIsError(error.response.data)
});

      }

  };

  // const handleUpdateApi  = (e,data)=>{


  //   console.log("Update")
  //     let formData = new FormData();
  //     // setImage(data.image[0]);
  //     console.log("image Select Logic", data.subscription_image);
  //     console.log("name",data.subscription_name)

  //     // dispatch(getImage(data.subscription_image[0]));
  //     console.log("NO",typeof data.subscription_image[0])
  //     if(typeof data.subscription_image[0] ==="File")
  // {
  //    console.log("YESss")
  //   formData.append("image",data.subscription_image[0])
  // }
  //     formData.append("name",data.subscription_name)
  //     formData.append("price" , Math.round(data.subscription_price));
  //     formData.append("description" ,data.subscription_description );
   
  //     console.log("tempID");
  //      formData.append("subscription_id" ,id);
  //      const res = axios.post(`${process.env.REACT_APP_API_URL}/edit_subscription`, formData, {
  //        headers: {
  //          'content-type': 'text/json',
  //          "Content-Type": "multipart/form-data",
  //          "Accept": "application/json",
  //          'Content-Encoding': 'gzip , deflate, br'
  //        }
  //      }).then(res=>{
  //        if(res.status===200){
  //          const {data} = res;
  //          SetValid(true);
  //          console.log("data EDIT Subscribtion" , data);
  //          return res;
  //        }
  //        else
  //        {
  //          console.log(res);
  //          throw new Error('there is an error')
  //        }
  //      }).catch(error=>{
  //        console.log(error.response)
  //      });
     
  // }


  return {
          valid,handleSubmitApi,
          bach,bache,bachee,AddSubscribtion
  }
}
