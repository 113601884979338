import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

const TypeTransfare = () => {
    return <Layout>
        <div className=' container all'>
            <h2 className='w-100 text-center my-5'>اختر نوع الحوالة المراد إضافتها</h2>

            <div className='w-100 d-flex flex-wrap gap-5 align-items-center justify-content-center my-5'>
            
            <Link to="/addtransfer" className='btn btn-primary'>حوالة كورس</Link>
            <Link to="/addlibrarytransfer" className='btn btn-primary'>حوالة مكتبة</Link>

            </div>


        </div>

        <div className=' w-100 ' style={{height: '150px'}}></div>
    </Layout>
}

export default TypeTransfare; 