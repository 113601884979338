import React,{ useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchFaqsSubjectApi from "../../redux-toolkit/api/fetchFaqs";

const ShowFaqsSubjectsLogic = (id) => {
    const [page,setPage] =useState(1);
    console.log("nooooooo");
    const {isLoading, isError, data, error} = useQuery(['FaqsSubjects',page ,id], fetchFaqsSubjectApi);   
    
    function handlePageClick (data)
    {  
     setPage(data.selected+1);
    }
    console.log("oGGGGGGG"); 
    return {
        isLoading, isError, data, error,handlePageClick,page
    }
}

export default  ShowFaqsSubjectsLogic