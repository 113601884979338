import React, { useEffect, useRef , useState } from 'react'
import axios from "axios";

import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';



export  default function FaqsSubjectLogic(subscribtionPage,id,reset,idTeacher,subSectionId,subjectId) {


  console.log("teacher idddd" , idTeacher);
console.log("subSection iddd"  , subSectionId);
//remove the image from input file
  const [AddSubscribtion , setAddSubscribtion] = useState(false);
  const navigate = useNavigate()

  console.log("iddd",id);
  const tempId = id || 0
  console.log("tempIDDsdfdsfjhj",tempId);
      const [valid , SetValid] = useState(false);
    const bach=useRef(null);
    const bache=useRef(null);
    const bachee=useRef(null);

  
    useEffect(()=>{
             setAddSubscribtion(false);
    },[])      
    
    

    const handleSubmitApi=(data)=>{


if(id){

  console.log("Update")
  let formData = new FormData();
  // setImage(data.image[0]);
 

  // dispatch(getImage(data.subscription_image[0]));
  
  console.log("data id  id" , data.idComment)
      
  formData.append("question", data.question);
  formData.append("answer", data.answer);
  formData.append('subject_id' , id);
    formData.append('transable' , 0);
    
 

  for(var pair of formData.entries()) { 
    console.log("form Data", pair[0]+ ', '+ pair[1]);  
}

  
   const res = axios.post(`${process.env.REACT_APP_API_URL}/update_faqs/${data.idComment}`, formData, {
     headers: {
       'content-type': 'text/json',
       "Content-Type": "multipart/form-data",
       "Accept": "application/json",
       'Content-Encoding': 'gzip , deflate, br',
       'Authorization':`Bearer ${localStorage.getItem('token')}`
     }
   }).then(res=>{
     if(res.status===200){
       const {data} = res;
       SetValid(true);
       console.log("data EDIT Subscribtion" , data);
       navigate(-1)
       alert('تم التعديل بنجاح')
       return res;
     }
     else
     {
       console.log(res);
       throw new Error('there is an error')
     }
   }).catch(error=>{
     console.log(error.response)
   });
 

}else{


      // console.log("teacher idddd" , idTeacher);
      // console.log("subSection iddd"  , subSectionId);


      console.log("submit")
      let formData = new FormData();
      // setImage(data.image[0]);
      console.log("dataaaaaaa" , data);

      // dispatch(getImage(data.subscription_image[0]));
      
      formData.append("question", data.question);
      formData.append("answer", data.answer);
      formData.append('subject_id' , subjectId);
      formData.append('transable' , 0);
    

    // console.log('hello',formData)
    for(var pair of formData.entries()) { 
      console.log("form Data", pair[0]+ ', '+ pair[1]);  
 }

 const res = axios.post(`${process.env.REACT_APP_API_URL}/add_faqs` , formData, {
  headers: {
    'content-type': 'text/json',
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    'Content-Encoding': 'gzip , deflate, br',
    'Authorization':`Bearer ${localStorage.getItem('token')}`
  }
}).then(res=>{
  if(res.status===200){
    const {data} = res;
    SetValid(true);
    reset({answer:'',question:''})
    navigate(-1)
  //  window.location.reload();
  alert('تمت الاضافه بنجاح')
   setAddSubscribtion(true);
    console.log("data Post Faqs" , data);
    return res;
  }
  else
  {
    console.log(res);
    throw new Error('there is an error')
  }
}).catch(error=>{
  console.log(error.response)
  //  setIsError(error.response.data)
});

}




  };




  return {
          valid,handleSubmitApi,
          bach,bache,bachee,AddSubscribtion
  }
}
