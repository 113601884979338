import React,{ useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchOffersApi from "../../redux-toolkit/api/fetchOffers";

const ShowSectionsLogic = () => {
    const [page,setPage] =useState(1);
    console.log("nooooooo");
    const {isLoading, isError, data, error} = useQuery(['sections',page], fetchOffersApi);   
    
    function handlePageClick (data)
    {  
     setPage(data.selected+1);
    }
    console.log("oGGGGGGG"); 
    return {
        isLoading, isError, data, error,handlePageClick,page,setPage
    }
}

export default ShowSectionsLogic