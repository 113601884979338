import React, { useEffect, useRef, useState } from 'react'
export default function SubjectImageLogic(valid) {


    const btnref = useRef();
    const cardref = useRef();
    

   

    
  useEffect(()=>{
         btnref.current.style.display ='block'
  },[valid])

 
  return {
    cardref,btnref,
  }
}

