

import axios from "axios";

export default async function  fetchStudents ({queryKey})
{
          console.log("process",process.env.REACT_APP_API_URL)
          console.log("queryNOwww" , queryKey)
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_student_in_subscription_by_id/${queryKey[2]}?page=${queryKey[1]}` ,
          {params :{limit:10}, headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`} }
          );
   
          return  data;
} 


