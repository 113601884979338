import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect } from 'react';
import Home from './components/Home/Home';
import Subscribe from './components/Subscribe/Subscribe';
import SubscribtionMain from './components/SubscribtionMain/SubscribtionMain';
import SubscribtionPage from './components/SubscribtionMain/SubscribtionPage';
// import SubjectsForSubscribtion from './components/Sections/SubjectForSubscribtion';
import Subject from './components/Subject/Subject';
import Signup from './pages/signup/signup';
import Sons from './pages/Sons/Sons';

import Sections from '../src/components/Sections/Sections';
import ShowSections from './components/Sections/ShowSections';
import ShowSubSections from './components/SubSections/ShowSubSections';
import SubSections from './components/SubSections/SubSections';
import ShowSubjects from './components/Subject/ShowSubjects';

import Teachersm from './components/Teachers/Teachers';
import Student from './components/Student/Student';
import Teachers from './pages/Teachers/Teachers';
import AddLibrarySection from './pages/AddLibrarySection/AddLibrarySection';
import TeacherDetails from './pages/TeacherDetails/TeacherDetails';
import EditTeacherDetails from './pages/EditTeacherDetails/EditTeacherDetails';
import StudentDetails from './pages/StudentDetails/StudentDetails';
import EditStudentDetails from './pages/EditStudentDetails/EditStudentDetails';
import SubjectSubsecribtion from './pages/SubjectSubsecribtion/SubjectSubsecribtion';
import Admins from './pages/Admins/Admins';
import AddAdmin from './pages/AddAdmin/AddAdmin';
import EditAdmin from './pages/EditAdmin/EditAdmin';
import LoginAsAdmin from './pages/LoginAsAdmin/LoginAsAdmin';

import ShowCommentsSubjects from './components/CommentsSubject/ShowCommentsSubjects';

import CommentsSubjects from './components/CommentsSubject/CommentsSubject';
import ShowComment from './components/CommentsSubject/ShowComment';
import ShowFaqsSubjects from './components/FaqsSubject/ShowFaqsSubjects';
import FaqsSubjects from './components/FaqsSubject/FaqsSubject';
import { useSelector } from 'react-redux';
import WelcomAdmin from './pages/WelcomAdmin/WelcomAdmin';
import Missing from './pages/Missing/Missing';
import RequireAuth from './components/RequireAuth/RequireAuth';
import UnAuthorized from './pages/UnAuthorized/UnAuthorized';
import GuardianDetails from './pages/GuardianDetails/GuardianDetails';
import AddGuardian from './pages/AddGuardian/AddGuardian';
import EditGuardianDetails from './pages/EditGuardianDetails/EditGuardianDetails';
import Library from './pages/Library/Library';
import LibrarySection from './pages/LibrarySection/LibrarySection';
import EditLibrarySection from './pages/EditLibrarySection/EditLibrarySection';
import LibrarySectionStudents from './pages/LibrarySectionStudents/LibrarySectionStudents';
import AddBook from './pages/AddBook/AddBook';
import EditLibraryBook from './pages/EditLibraryBook/EditLibraryBook';
import Gurardians from './pages/Guirdians/Guirdians';
import TestVimeo from './pages/TestViemeoPlayer/TestViemeoPlayer';
import ShowOffers from './components/Offers/ShowOffers';
import Offers from './components/Offers/Offers';
import TeacherSubjects from './components/TeachersSubject/TeacherSubject';
import StudentSubscribtion from './components/StudentSubscribtion/StudentSubscribtion';
import SubjectsForStudent from './components/SubjectsForStudent/SubjectsForSutdent';
import axios from 'axios';
import UserOpinions from './pages/UserOpinions/UserOpinions';
import UserOinionDetails from './components/userOinionDetails/userOinionDetails';
// import WebsiteTexts from './pages/WebsiteTexts/WebsiteTexts';
import WebsiteTexts from './components/WebsiteTexts/WebsiteTexts';
import WebsiteFiles from './components/WebsiteFiles/WebsiteFiles';
import UpdateApplication from './components/UpdateApplication/UpdateApplication';
import Transfers from './pages/Transfers';
import EditTransfer from './pages/EditTransfer';
import AddTransfer from './pages/AddTransfer';
import TypeTransfare from './pages/TypeTransfare';
import AddTransferLibrary from './pages/AddTransferLibrary';
// import WebsiteTexts from './components/WebsiteTexts/WebsiteTexts';

function App() {
	const userData = useSelector((state) => state.Login);
	const navigate = useNavigate();

	useEffect(() => {
		if (
			userData.token == '' ||
			userData.token == null ||
			userData.token == undefined
		) {
			navigate('/loginasadmin');
		}
	}, []);

	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<Routes>
				{/* <Route path='/addteacher' element={<Teachersm/>} />
    <Route path="/" element={<Home />} />
    <Route path='/students' element={<Student/>} />
    <Route path="/teachers" element={<Teachers/>} />
    <Route path="/SubjectSubsecribtion" element={<SubjectSubsecribtion />} />
    <Route path="/addlibrarysection" element={<AddLibrarySection />} />
    <Route path="/teacher/:id" element={<TeacherDetails />} />
    <Route path="/editteacherdetails/:id" element={<EditTeacherDetails />} />
    <Route path="/student/:id" element={<StudentDetails />} />
    <Route path="/editstudentdetails/:id" element={<EditStudentDetails />} />
    <Route path="/admins" element={<Admins />} />
    <Route path="/addadmin" element={<AddAdmin />} />
    <Route path="/editadmin/:id" element={<EditAdmin />} />
    <Route path="/loginAsAdmin" element={<LoginAsAdmin />} />

    <Route path="/welcomAdmin" element={<WelcomAdmin />} /> */}

				<Route path='*' element={<Missing />} />

				{/* 
<Route element={<RequireAuth AllowedRoles={[4,5,6,7]} />} >
  <Route path="/welcomAdmin" element={<WelcomAdmin />} />
</Route> */}

				<Route path='/' element={<WelcomAdmin />} />

				<Route path='/unauthorized' element={<UnAuthorized />} />

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/homepage' element={<Home />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/websitetexts' element={<WebsiteTexts />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/websitefiles' element={<WebsiteFiles />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/updateapplication' element={<UpdateApplication />} />
				</Route>

				{/* 
  // Role_id is => 7 
  He is the main admin 
  all routes are opened for him

  */}

				{/* 
  // Role_id is => 6
  He is the Users admin 
  He can only mange the user account 
  */}

				<Route path='/testvimeo' element={<TestVimeo />} />

				{/* 
  // Role_id is => 5
  He is the finisial admin 
  He can mange the payments subs
  */}

				{/* 
  // Role_id is => 4
  He is the content admin 
  He can manage the content of website
  */}

				{/* Admins */}
				<Route element={<RequireAuth AllowedRoles={[7]} />}>
					<Route path='/admins' element={<Admins />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[7]} />}>
					<Route path='/addadmin' element={<AddAdmin />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[7]} />}>
					<Route path='/editadmin/:id' element={<EditAdmin />} />
				</Route>

				{/* Login and welcome */}

				<Route path='/loginAsAdmin' element={<LoginAsAdmin />} />

				<Route path='/welcomAdmin' element={<WelcomAdmin />} />

				{/* Guardians */}
				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/guirdians' element={<Gurardians />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/guardian/:id' element={<GuardianDetails />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/addguardian' element={<AddGuardian />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route
						path='/editguardiandetails/:id'
						element={<EditGuardianDetails />}
					/>
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/sons/:guardianID' element={<Sons />} />
				</Route>

				{/* Students */}
				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/students' element={<Student />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/addstudent' element={<Signup text={'طالب'} />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/student/:id' element={<StudentDetails />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route
						path='/editstudentdetails/:id'
						element={<EditStudentDetails />}
					/>
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/SubjectStudent/:id' element={<SubjectsForStudent />} />
				</Route>

				{/* Teachers */}
				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/addteacher' element={<Teachersm />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/teachers' element={<Teachers />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/teacher/:id' element={<TeacherDetails />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route
						path='/editteacherdetails/:id'
						element={<EditTeacherDetails />}
					/>
				</Route>

				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route
						path='/editTransfer/:id'
						element={<EditTransfer />}
					/>
				</Route>

				{/* Library */}
				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/library' element={<Library />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/userOpinions' element={<UserOpinions />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route
						path='/useropiniondetails/:opinionId'
						element={<UserOinionDetails />}
					/>
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/addlibrarysection' element={<AddLibrarySection />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/librarySection/:id' element={<LibrarySection />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route
						path='/editlibrarysection/:id'
						element={<EditLibrarySection />}
					/>
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/addbook/:id' element={<AddBook />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/editlibrarybook/:id' element={<EditLibraryBook />} />
				</Route>

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route
						path='/librarySectionStudents/:sectionId'
						element={<LibrarySectionStudents />}
					/>
				</Route>

				{/* create and show subscribtions ,subjecet */}
				<Route element={<RequireAuth AllowedRoles={[6, 7]} />}>
					<Route path='/mainSubscribtions' element={<SubscribtionMain />} />
					<Route
						path='/mainsubscribtion/:id'
						element={<SubjectSubsecribtion />}
					/>
					<Route path='/subscribe/:id' element={<Subscribe />} />
					<Route path='/subscribe' element={<Subscribe />} />
				</Route>

				{/* <Route element={<RequireAuth AllowedRoles={[6,7]} />} >
  
</Route> */}

				<Route element={<RequireAuth AllowedRoles={[4, 7]} />}>
					<Route path='/addsections' element={<Sections />} />

					<Route path='/addsections/:id' element={<Sections />} />

					<Route path='/showSections' element={<ShowSections />} />

					<Route path='/showSections/:id' element={<ShowSubSections />} />

					<Route path='/showSubSections/:id' element={<ShowSubSections />} />

					<Route path='/addSubSections' element={<SubSections />} />

					<Route path='/addSubSections/:id' element={<SubSections />} />

					<Route path='/subject' element={<Subject />} />

					<Route path='/subject/:id' element={<Subject />} />

					<Route path='/showSubjects/:id' element={<ShowSubjects />} />

					<Route path='/showComments/:id' element={<ShowCommentsSubjects />} />

					<Route path='/addComments' element={<CommentsSubjects />} />

					<Route path='/addComments/:id' element={<CommentsSubjects />} />

					<Route path='/showComment' element={<ShowComment />} />

					<Route path='/showFaqs/:id' element={<ShowFaqsSubjects />} />

					<Route path='/addFaqs' element={<FaqsSubjects />} />

					<Route path='/addFaqs/:id' element={<FaqsSubjects />} />
					
					<Route path='/transfers' element={<Transfers />} />
					<Route path="/transferType" element={<TypeTransfare />} />
					<Route path='/addtransfer' element={<AddTransfer />} />
					<Route path='/addlibrarytransfer' element={<AddTransferLibrary />} />

					<Route path='/showOffers' element={<ShowOffers />} />
					<Route path='/addOffers' element={<Offers />} />
					<Route path='/addOffers/:id' element={<Offers />} />
					<Route path='/teahcersSubject/:id' element={<TeacherSubjects />} />

					<Route
						path='/studentSubscribtion/:id'
						element={<StudentSubscribtion />}
					/>
				</Route>
			</Routes>
		</QueryClientProvider>
	);
}
export default App;
