import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../Layout/Layout';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

const UserOinionDetails = () => {
    
    const {state} = useLocation();
    const [opinionId,setOpinionId] = useState();
    const modalRef = useRef(null);
    const closeModalBtn = useRef();
    const [Isloading,setIsLoading] = useState(false);
    const [successMessage , setSuccessMessage] = useState('');
    const [ErrorMessage , setErrorMessage] = useState('');

    useEffect(() => {
    console.log('koko')
        console.log('data from state is => ' ,state);

    },[])

    const handleSelectOpinion = (event,opinionId) => {
        setOpinionId(opinionId);
    }

    const handleDeleteOpinion = (event) => {

        setIsLoading(true);

        axios.delete(`${process.env.REACT_APP_API_URL}/delete_user_opinion/${opinionId}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('modal show',document.getElementsByClassName('modal-backdrop')[0])
            if(data.status === 200){
            setSuccessMessage("تمت  عملية الحذف بنجاح")

            modalRef.current.style.display = 'none';
            // closeModalBtn.current.click();
            console.log('modal show',)
            document.getElementsByClassName('modal-backdrop')[0].remove();

            setIsLoading(false);
            console.log(data);

            // const newCurrentTeachers = currentTeachers.filter(currentTeacher => currentTeacher.teacher_id != id);

            // setCurrentTeachers([...newCurrentTeachers]);
        }
        })
        .catch(error => {


            console.log(error);
            // setErrorMessage("غير متصل بالشبكه");
            setIsLoading(false);
            setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')

            modalRef.current.style.display = 'none';
            closeModalBtn.current.click();
        })

    }

    return (
        <>
            <ToastSuccessError successMessage={successMessage} ErrorMessage={ErrorMessage} setSuccessMessage={setSuccessMessage}    setErrorMessage={setErrorMessage}    />


        <Layout>
        <div className='container text-center'>
            <h1 className='mb-5'>الطالب: {state.user_name}</h1>
            <h3 className='p-3 border rounded shadow' style={{maxWidth: '700px',lineHeight: '1.7', margin: '0px auto'}}>{state.opinion}</h3>

            <button 
                            type="button" 
                            className="btn btn-danger my-5" 
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal"
                            onClick={(e) => handleSelectOpinion(e,state.id)}>
                                    حذف
                            </button>
        </div>
        </Layout>
        <div className="modal fade"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        
            <h5 
            className="modal-title mx-auto" 
            id="exampleModalLabel"
            >
                {Isloading ? 'جاري حذف هذا الرأي ...' : "هل أنت متأكد ؟"}
            </h5>
           

        </div>

        {Isloading && <div className="modal-body">
            
        
        {Isloading && <div className='mb-3 d-flex justify-content-center' >
          
          <div class="spinner-border mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        </div>
        }

        </div>
        }

       {!Isloading &&<div className="modal-footer justify-content-between">
            <button 
            type="button" 
            className="btn btn-secondary" 
            data-bs-dismiss="modal"
            ref={closeModalBtn}
            >تجاهل</button>
           
            <button 
            type="button" 
            className="btn btn2"
            onClick={(e) => handleDeleteOpinion(e)}
            >تأكيد</button>

        </div>
        }
      
    </div>
    </div>
    </div>
        </>
    )
}

export default UserOinionDetails;