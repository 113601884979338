import React, { useRef, useState, useEffect } from 'react';
import record from '../../assets/Home/record.png';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Circle } from 'rc-progress';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import eye from '../../assets/Home/eye.svg';
import eyehide from '../../assets/Home/eyehide.svg';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import Layout from '../../components/Layout/Layout';

export default function () {
	const userData = useSelector((state) => state.Login);
	const [mainVideoFile, setMainVideoFile] = useState('');
	const [aboutUsImage, setAboutUsImage] = useState('');
	const [allSiteInfo, setAllSiteInfo] = useState(null);
	const [progress, setProgress] = useState(0);
	const [IsLoading, setIsLoading] = useState(false);
	const [isUploadSuccessfuly, setIsUploadSuccessfuly] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [countries, setCountries] = useState([]);

    
	const [teachers, setTeachers] = useState([]);
	const [subSections, setSubSetSections] = useState([]);
	const [SubSelectedSection, setSelectedSubSection] = useState([]);
	const [sections, setSections] = useState([]);

	const [defaultSelectSection, setDefaultSelectSection] = useState();
	const [defaultSelectSubSection, setDefaultSelectSubSection] = useState();
	const [defaultteacherSubSection, setDefaultteacherSubSection] = useState();

    const [idTeacher, setIdTeacher] = useState(null);
	const [subSectionId, setSubSectionId] = useState(null);
	const [selectedSection, setSelectedSection] = useState(null);
    const [searchResult,setSearchResult] = useState('');


    
    const unOrderStudentsList = useRef();
    const inputSearchRef = useRef();

	const {
		reset,
		getValues,
		watch,
		register,
		handleSubmit,
        setValue,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			balance: '',
			payment_image: '',
            subject_id: '',
            student_id: '',
            payment_code: '',
			type: 'COURSES'
		},
	});

    
	useEffect(() => {
		// console.log('section_subSection', state?.sectionId);
		// console.log('Subsection_subSection', state?.subSectionId);
		// console.log('state insdie subject',)
		// setSubSectionId(state?.sectionId);
		// setSubSectionId(state?.subSectionId);

		const sectionsFromAPI = axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_section_by_section_id/${selectedSection}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('selected sections', data.data.data);
				// setDefaultSelectSection(data.data.data.section_name);
			});

		const subSectionsAPI = axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}?limit=19`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('selected subsectionsssssssssss  ', data.data.data.data);
				// setDefaultSelectSubSection(data.data.data.sub_section_name);
                setSubSetSections(data.data.data.data)
			});
	}, [selectedSection]);

    const handleSectionChange = (e) => {
		console.log('selected sections id', e.target.value);
		setSelectedSection(e.target.value);
		// setSubSetSections([]);
	};

    const handleTeacherChange = (e) => {
		console.log('selected teacer  id sections', e.target.value);
		setIdTeacher(e.target.value);
	};

    const handleSubSectionChange = (e) => {
		// console.log(e.target.value);
		console.log('selected  Sub Sections id', e.target.value);
		// e.target.value =
		setSelectedSubSection(e.target.value);
		setSubSectionId(e.target.value);
		// setSubject([]);
	};

    useEffect(() => {
		const sectionsFromAPI = axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_sections?limit=19`)
			.then((data) => {
				console.log('log', data.data.data);
				setSections(data.data.data);
			});
	}, []);

    useEffect(() => {
		// console.log('section changed')
		// console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
		const teachers = axios
			.get(`${process.env.REACT_APP_API_URL}/get_subjects_by_sub_section?sub_section_id=${subSectionId}&limit=19`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				console.log('teachers DATA', data?.data?.data);
				setTeachers(data?.data?.data);
			});
	}, [subSectionId]);

	const handleOnSubmit = (data) => {
		// e.preventDefault();
		console.log('data', data);
		console.log('submitting data to server');
		setIsLoading(true);
		const ourFormData = new FormData();

		ourFormData.append('balance', data.balance);
		ourFormData.append('subject_id', idTeacher);
		ourFormData.append('student_id', data.student_id);
		ourFormData.append('payment_image', data.payment_image[0]);
		ourFormData.append('payment_code', data.payment_code);
		ourFormData.append('type', data.type);


		for (var pair of ourFormData.entries()) {
			console.log(pair[0] + ', ' + pair[1]);
			// console.log('pair',pair);
		}

		axios
			.post(`${process.env.REACT_APP_API_URL}/add_payment`, ourFormData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json',
					'Content-Encoding': 'gzip , deflate, br',
					'content-type': 'text/json',
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${userData.token}`,
				},

				onUploadProgress: (progressEvent) => {
					const progress = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					console.log(progress);
					setProgress(progress);
					setIsLoading(true);
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data from post api', data);
					setIsLoading(false);
					setIsUploadSuccessfuly(true);
					setSuccessMessage('تم إنشاء الحوالة بنجاج');

					setTimeout(() => {
						navigate(-1);
					}, 1000);

					reset();

					return res;
				} else {
					setIsLoading(false);
					console.log('res inside else statment', res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log('res inside catch statment', error.response);
				setErrorMessage(error.response.data.message);
				//  setIsError(error.response.data)
			});
	};


	const changeColor = (progress) => {
		if (progress <= 25) {
			return '#ff0707';
		} else if (progress <= 50) {
			return '#ff6907';
		} else if (progress <= 75) {
			return '#ffdb07';
		} else {
			return '#77ff07';
		}
	};

    const handleSearchStudent = (e) => {
        
        unOrderStudentsList.current.style.height = 'auto';

        handleUnorderStudentsList(e.target.value);
        setValue('student_id',e.target.value);
        
        axios.get(`${process.env.REACT_APP_API_URL}/students_search?q=${e.target.value}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(response => {
            console.log('search response',response.data.data);
            setSearchResult(response.data.data)
        })
        .catch(error => {
            console.log('error while fetching search students', error)
        });

    }

    const handleSelectStudentFromDrobDownList = (student) => {
        setValue('student_id',student.student_id);
        console.log('student which was selected by user => ' , student)
        unOrderStudentsList.current.style.height = '0';
        inputSearchRef.current.value = student.name;
    }

    const handleUnorderStudentsList = (searchQuery) => {
        if(searchQuery == '') {
            unOrderStudentsList.current.style.height = '0px';
        }else {
            unOrderStudentsList.current.style.height = 'auto';
        }
    }

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>
			{IsLoading && (
				<div className={`uploadPrgressbar`}>
					<h2>جار إنشاء  الحوالة ... </h2>

					<div className='content'>
						{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
						<p>{progress}%</p>
						<Circle
							percent={progress}
							strokeWidth={4}
							strokeColor={changeColor(progress)}
						/>
					</div>
				</div>
			)}
			<Layout>
				<div className='container all subject'>
					<h1
						className='pt-0 mb-4 pb-4 pt-3'
						style={{
							color: '#044364',
							// borderBottom: '1px solid green'
						}}>
						إضافة حوالة كورس
					</h1>
					<form onSubmit={handleSubmit(handleOnSubmit)}>
						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> قيمة الحوالة</label>
							</div>
							<input
								type='text'
								{...register('balance', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.balance && (
								<p className='text-danger text-right'>يجب إدخال قيمة الحوالة</p>
							)}
						</div>

                        <div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> رقم الحوالة</label>
							</div>
							<input
								type='text'
								{...register('payment_code', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.payment_code && (
								<p className='text-danger text-right'>يجب إدخال رقم الحوالة</p>
							)}
						</div>








						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> صورة الحوالة</label>
							</div>
							<RegisterImage
								register={register}
								isUploadSuccessfuly={isUploadSuccessfuly}
								registerWhat={'payment_image'}
							/>

							{/* <input type='text' {...register("name", { required: true })} className='shadow w-100 main-input border-0'/> */}
							{errors.payment_image && (
								<p className='text-danger text-right'>يجب إدخال صورة الحوالة </p>
							)}
						</div>



                        <div className='mb-3 d-flex align-items-center mt-4'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اختر المادة </label>
							</div>


                        <form className='subject'>
						<div className='selects__container'>
							<div className='d-flex flex-column justify-content-center align-items-center'>
								<p>اختر قسم</p>
								<select
									onChange={(e) => handleSectionChange(e)}
									className='form-select customSelect'
									aria-label='Default select example'>
									<option selected>الرجاء اختيار القسم</option>
									{sections &&
										sections?.map((section, index) => (
											<option key={index} value={section.section_id}>
												{section.section_name}
											</option>
										))}
								</select>
							</div>

							<div className='d-flex flex-column justify-content-center align-items-center'>
								<p>اختر دوره</p>
								<select
									onChange={(e) => handleSubSectionChange(e)}
									className='form-select  customSelect'
									aria-label='Default select example'>
									<option selected>الرجاء اختيار الدورة</option>
									{subSections &&
										subSections?.map((subsection, index) => (
											<option key={index} value={subsection.sub_section_id}>
												{subsection.sub_section_name}
											</option>
										))}
								</select>
							</div>

							<div className='d-flex flex-column justify-content-center align-items-center'>
								<p>اختر مادة</p>

								<select
									onChange={(e) => handleTeacherChange(e)}
									className='form-select  customSelect'
									aria-label='Default select example'>
									<option selected>الرجاء اختيار المادة</option>
									{teachers &&
										teachers.length > 0 &&
										teachers?.map((teacher, index) => (
											<option key={index} value={teacher.subject_id}>
												{teacher.subject_name}
											</option>
										))}
								</select>
							</div>
						</div>
						{/* <button type='submit' className='btn infos px-3 py-2' onClick={handleAddSubject}>إضافة</button> */}
					</form>



                    <div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> الطالب </label>
							</div>

                    <input type='text' ref={inputSearchRef} placeholder='اكتب هنا للبحث عن طالب عن طريق الاسم' onChange={(e) => handleSearchStudent(e)} className='shadow w-100 main-input border-0'/>
           {/* <input type='number' {...register("student_id", { required: true })} className='shadow w-100 main-input border-0'/> */}
     
           {/* <select type='text' {...register("student_id", { required: true })} ref={studentsSelect}  className='shadow w-100 p-2 border-0'> */}
            <div className='students_select'>   
                <ul className='unorder_students_list' ref={unOrderStudentsList} >
                        {
                            searchResult && searchResult?.map((student,index) => {
                                return   <li 
                                        onClick={() => handleSelectStudentFromDrobDownList(student)} 
                                        value={student.student_id} 
                                        key={index}>
                                            {student.name}
                                        </li>
                            })
                        }
                    </ul>
            </div>





                        

						<div className='d-flex justify-content-center mt-5'>
							{!IsLoading ? (
								<button
									className=' btn infos px-3 py-2 my-5 border-0 btn1'
									type='submit'>
									<h6>إنشاء الحوالة </h6>{' '}
								</button>
							) : (
								<div className='mt-2 d-flex justify-content-center'>
									<div class='spinner-border mx-auto' role='status'>
										<span class='visually-hidden'>Loading...</span>
									</div>
								</div>
							)}
						</div>
					</form>
				</div>
			</Layout>
		</>
	);
}
