import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import ModalImage from "react-modal-image-responsive";

import './styleTable.css';

export default function TransfersTable({ data, isLoading ,refetch}) {
	const navigate = useNavigate();
	const [currentTeachers, setCurrentTeachers] = useState([]);
	const [selectedteacherId, setSelectedTeacherId] = useState();
	const modalRef = useRef(null);
	const closeModalBtn = useRef();
	const [Isloading, setIsLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [isLogining, setIslogining] = useState(false);

    const deletemodalRef = useRef(null);
	const closeDeleteModalBtn = useRef();

    const [PaymentCode,setPaymentCode] = useState([]);
    const [isApproved,setIsApproved] = useState(false);
    const [imagePayment,setImagePayment] = useState('');

	useEffect(() => {
		console.log('data from react query', data?.data);
		setCurrentTeachers(data?.data);
	}, [data]);

	const handleDeleteTeacher = (e, id) => {
		setIsLoading(true);

		axios
			.post(`${process.env.REACT_APP_API_URL}/approve_student_payment/${id}?payment_code=${PaymentCode}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				console.log(
					'modal show',
					document.getElementsByClassName('modal-backdrop')[0]
				);
				if (data.status === 200) {

                    setPaymentCode('');

                    if(isApproved){
                        setSuccessMessage('تمت  عملية رفض طلب الدفع بنجاح');

                    }else {
                        setSuccessMessage('تمت  عملية قبول طلب الدفع بنجاح');

                    }
                    refetch();
					modalRef.current.style.display = 'none';
					// closeModalBtn.current.click();
					console.log('modal show');
					document.getElementsByClassName('modal-backdrop')[0].remove();

					setIsLoading(false);
					console.log(data);

					const newCurrentTeachers = currentTeachers.filter(
						(currentTeacher) => currentTeacher.teacher_id != id
					);

					setCurrentTeachers([...newCurrentTeachers]);
				}
			})
			.catch((error) => {
				console.log(error);
				// setErrorMessage("غير متصل بالشبكه");
				setIsLoading(false);
				// setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')

                setErrorMessage(error.response.data.message);

				modalRef.current.style.display = 'none';
				closeModalBtn.current.click();
			});
	};


    const  handleDeleteTransfer = (e, id) => {
		setIsLoading(true);

		axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_payment/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				console.log(
					'modal show',
					document.getElementsByClassName('modal-backdrop')[0]
				);
				if (data.status === 200) {
					setSuccessMessage('تمت  عملية حذف الطلب بنجاح  ');
                    refetch();
					deletemodalRef.current.style.display = 'none';
					// closeModalBtn.current.click();
					console.log('modal show');
					document.getElementsByClassName('modal-backdrop')[0].remove();

					setIsLoading(false);
					console.log(data);

					const newCurrentTeachers = currentTeachers.filter(
						(currentTeacher) => currentTeacher.teacher_id != id
					);

					setCurrentTeachers([...newCurrentTeachers]);
				}
			})
			.catch((error) => {
				console.log(error);
				// setErrorMessage("غير متصل بالشبكه");
				setIsLoading(false);
				// setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا')

				deletemodalRef.current.style.display = 'none';
				closeDeleteModalBtn.current.click();
			});
	};

	const selectedTeacherId = (id) => {
		console.log('id', id);
		setSelectedTeacherId(id);
	};



	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> اسم المستخدم</th>
						<th className='text-center text-white'> اسم المادة</th>
						<th className='text-center text-white'> قيمة الحوالة</th>
						<th className='text-center text-white'> صورة الحوالة </th>
						<th className='text-center text-white  last_child'>
							العمليات المتاحة
						</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={5}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : currentTeachers && currentTeachers.length > 0 ? (
						currentTeachers.map((teacher, index) => {
							return (
								<tr
									key={index}
									// onClick={() => {
									// 	navigate(`/teacher/${teacher.student_id}`);
									// }}
									style={{
										cursor: 'pointer',
										borderBottom: '1px solid #1a1a1a1a',
									}}
									className='tableRowHover'>
									<td className='text-center'>{teacher.student_username}</td>
									<td className='text-center'>{teacher.subject_name}</td>
									<td className='text-center'>{teacher.payment_balance}</td>
									<td className='text-center'>
                                        <ModalImage 
                                        small={process.env.REACT_APP_Video_URL + teacher.payment_image}
                                        large={process.env.REACT_APP_Video_URL + teacher.payment_image}
                                        hideDownload
                                        showRotate
                                        className="paymentImage"
                                        />
                                        {/* <img src={ process.env.REACT_APP_Video_URL + teacher.payment_image} className="paymentImage" /> */}
                                    </td>
									<td className='text-center'>
										{/* <button
											type='button'
											className='btn  btn1'
											disabled={isLogining}
											// data-bs-toggle="modal"
											// data-bs-target="#exampleModal"
											onClick={(e) => {
												e.stopPropagation();
												handleloginAsTeacher(teacher.teacher_id);
											}}>
											تسجيل الدخول بحساب الاستاذ
										</button> */}

                                        <div className='d-flex flex-column gap-2 h-100 align-items-center justify-content-center'>
                                         
										<button
											type='button'
											className={`btn  ${ teacher.is_aproved == 1 ? 'btn1' : 'btn-success'}`}
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={(e) => {
												e.stopPropagation();
												selectedTeacherId(teacher.id);
                                                if(teacher.is_aproved == 1){
                                                    setIsApproved(true);
                                                }else {
                                                    setIsApproved(false);
                                                }

                                                setImagePayment(teacher.payment_image)
											}}>
                                                {teacher.is_aproved == 0 ? 'قبول طلب الدفع' : 'رفض طلب الدفع'}
										</button>


                                        <button
											type='button'
											className='btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModaldelete'
											onClick={(e) => {
												e.stopPropagation();
												selectedTeacherId(teacher.id);
											}}>
                                                حذف الطلب
										</button>
                                        

                                        <button
											type='button'
											className='btn  btn-primary'
											data-bs-toggle='modal'
											// data-bs-target='#exampleModal'
                                            onClick={() => {
                                                navigate(`/editTransfer/${teacher.id}`);
                                            }}
                                            >
											  تعديل معلومات الطلب
										</button>


                                        


                                        </div>


                                        {/* <button
											type='button'
											className='btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={(e) => {
												e.stopPropagation();
												selectedTeacherId(teacher.teacher_id);
											}}>
											حذف
										</button> */}

									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={5} className='text-center py-5 '>
							<div>
								<h5>ليس هنالك اي حوالات</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				ref={modalRef}
				id='exampleModal'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title mx-auto' id='exampleModalLabel'>
								{Isloading ? 'جاري إتمام العملية ...' : 'إتمام عملية تعديل حالة الطلب'}
							</h5>
						</div>

						 
							<div className='modal-body'>
								{Isloading ?  
									<div className='mb-3 d-flex justify-content-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='visually-hidden'>Loading...</span>
										</div>
									</div>
								 : 
                                 (!isApproved && 
                                    
                                <div>
                                    <div className='mx-auto w-100 text-center py-3'>
                                        <h5 className='mb-3'>صورة الحوالة المالية</h5>
                                        <ModalImage 
                                        small={process.env.REACT_APP_Video_URL + imagePayment}
                                        large={process.env.REACT_APP_Video_URL + imagePayment}
                                        hideDownload
                                        showRotate
                                        className="paymentImage"
                                        />

                                        
                                    </div>
                                <h5 className='mb-3 text-center '>الرجاء إدخال رقم الحوالة المالية قبل تأكيد عملية القبول</h5>
                                    <input 
                                    value={PaymentCode} 
                                    placeholder="رقم الحوالة ... " 
                                    onChange={(e) => setPaymentCode(e.target.value)} 
                                    className='shadow w-100 main-input border rounded' />
                                </div>
                                    )
                                }
							</div>
						

						{!Isloading && (
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									className='btn btn-secondary'
									data-bs-dismiss='modal'
									ref={closeModalBtn}>
									تجاهل
								</button>

								<button
                                disabled={PaymentCode == '' && !isApproved}
									type='button'
									className='btn  btn-success'
									data-bs-dismiss={Isloading ? '' : 'exampleModal'}
									onClick={(e) => handleDeleteTeacher(e, selectedteacherId)}>
									إتمام
								</button>
							</div>
						)}
					</div>
				</div>
			</div>


            <div
				className='modal fade'
				ref={deletemodalRef}
				id='exampleModaldelete'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title mx-auto' id='exampleModalLabel'>
								{Isloading ? 'جاري حذف الطلب ...' : 'هل أنت متأكد ؟'}
							</h5>
						</div>

						{Isloading && (
							<div className='modal-body'>
								{Isloading && (
									<div className='mb-3 d-flex justify-content-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='visually-hidden'>Loading...</span>
										</div>
									</div>
								)}
							</div>
						)}

						{!Isloading && (
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									className='btn btn-secondary'
									data-bs-dismiss='modal'
									ref={closeDeleteModalBtn}>
									تجاهل
								</button>

								<button
									type='button'
									className='btn  btn2'
									data-bs-dismiss={Isloading ? '' : 'exampleModal'}
									onClick={(e) => handleDeleteTransfer(e, selectedteacherId)}>
									حذف
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
