import React, { useEffect, useReducer, useRef, useState } from 'react';
import Video from '../Video/Video';
import Image from '../Image/Image';
import Layout from '../Layout/Layout';
import img from '../../assets/Home/chalks (1).png';
import record from '../../assets/Home/record.png';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Circle } from 'rc-progress';
import CustomTable from '../CustomTable/CustomTable';
import RegisterImage from '../RegisterImage/RegisterImage';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import { Editor } from '@tinymce/tinymce-react';

const WebsiteFiles = () => {
	const [mainVideoFile, setMainVideoFile] = useState('');
	const [aboutUsImage, setAboutUsImage] = useState('');
	const [allSiteInfo, setAllSiteInfo] = useState(null);
	const [progress, setProgress] = useState(0);
	const [IsLoading, setIsLoading] = useState(false);

	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [isReal, setIsReal] = useState(false);

	const noReal = useRef();
	const yesReal = useRef();

	const {
		reset,
		getValues,
		watch,
		setValue,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			explainer_video: '',
			main_video: '',
			about_us_image: '',
			website_library_image: '',
		},
	});

	const handleOnSubmit = (data) => {
		const ourFormData = new FormData();

		if (typeof data.main_video[0] != 'string') {
			ourFormData.append('main_video', data.main_video[0]);
		}

		if (typeof data.explainer_video[0] != 'string') {
			ourFormData.append('explainer_video', data.explainer_video[0]);
		}

		if (typeof data.about_us_image[0] != 'string') {
			ourFormData.append('about_us_image', data.about_us_image[0]);
		}

		if (typeof data.website_library_image[0] != 'string') {
			ourFormData.append(
				'website_library_image',
				data.website_library_image[0]
			);
		}

		for (var pair of ourFormData.entries()) {
			console.log('dataaaaaaaaaaaaaaaaa', pair[0] + ', ' + pair[1]);
			// console.log('pair',pair);
		}

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/edit_file_site_infos`,
				ourFormData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},

					onUploadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded / progressEvent.total) * 100
						);
						console.log(progress);
						setProgress(progress);
						setIsLoading(true);
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data from post api', data);
					setIsLoading(false);
					setSuccessMessage('تمت عملية التعديل بنجاح');

					setTimeout(() => {
						// window.location.reload();
					}, 1000);
					return res;
				} else {
					console.log(res);
					//   throw new Error('there is an error')
				}
			})
			.catch((error) => {
				console.log(error.response);
				setErrorMessage(error.response.data.message);
				//  setIsError(error.response.data)
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_site_infos`)
			.then((data) => {
				setAllSiteInfo(data.data.data);
				reset(data.data.data);
				console.log('get values', getValues());
				console.log('all site infor', data);
			})
			.catch((error) => console.log(error));
	}, []);

	useEffect(() => {
		if (progress == 100) {
			setIsLoading(false);
		}
	}, [progress]);

	useEffect(() => {
		console.log(getValues());
	}, [watch()]);

	const changeColor = (progress) => {
		if (progress <= 25) {
			return '#ff0707';
		} else if (progress <= 50) {
			return '#ff6907';
		} else if (progress <= 75) {
			return '#ffdb07';
		} else {
			return '#77ff07';
		}
	};

	const handleIsRealChange = (e) => {
		console.log('target is => ', e.target.getAttribute('id'));
		console.log('checked => ', e.target.value);
		// noReal
		//yesReal

		if (e.target.getAttribute('id') == 'noReal') {
			yesReal.current.checked = false;
			// setValue('is_real',0);
			console.log('noReal checked');
			setIsReal(false);
		} else {
			// setValue('is_real',1);
			console.log('yesReal checked');
			setIsReal(true);

			axios
				.get(`${process.env.REACT_APP_API_URL}/get_all_site_infos`)
				.then((data) => {
					// setValue('number_of_students',data.data.data.number_of_students)
					// console.log('number_of_studentsRef =>',number_of_studentsRef.current)
					// number_of_studentsRef.current.value = data.data.data.number_of_students;
					// number_of_subjectsRef.current.value = data.data.data.number_of_subjects;
					// number_of_lessonsRef.current.value = data.data.data.number_of_lessons;
					reset(data.data.data);
				})
				.catch((error) => console.log(error));

			noReal.current.checked = false;
		}
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			{IsLoading && (
				<div className={`uploadPrgressbar`}>
					<h2>جار رفع الملفات الرجاء الانتظار ... </h2>

					<div className='content'>
						{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
						<p>{progress}%</p>
						<Circle
							percent={progress}
							strokeWidth={4}
							strokeColor={changeColor(progress)}
						/>
					</div>
				</div>
			)}
			<Layout>
				<form onSubmit={handleSubmit(handleOnSubmit)}>
					<div className='container all'>
						<h1
							className='pt-0 mb-4 pb-4 pt-3'
							style={{
								// borderBottom: '1px solid green'
								color: '#044364',
							}}>
							صور وفيديوهات الموقع
						</h1>

						<div className='mb-3 mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'> فيديو الصفحة الرئيسية </label>
							</div>

							<Video
								getvalues={getValues}
								isRequired={false}
								setvideo={setMainVideoFile}
								register={register}
								registerWhat='main_video'
							/>
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>
									الفيديو التعريفي لطريقة الاستخدام
								</label>
							</div>
							{/* <input type='text' {...register("explainer_video", { required: true })  } className='shadow w-100 main-input border-0'/> */}
							{/* {errors.explainer_video && <p className='text-danger'>*required</p>} */}

							<Video
								getvalues={getValues}
								isRequired={false}
								setvideo={setMainVideoFile}
								register={register}
								registerWhat='explainer_video'
							/>
						</div>

						<div className=' mt-5'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>صورة نبذة عنا</label>
							</div>

							<RegisterImage
								register={register}
								isRequired={false}
								imageSource={
									process.env.REACT_APP_Video_URL + allSiteInfo?.about_us_image
								}
								registerWhat={'about_us_image'}
							/>
						</div>
					</div>

					<div className='container '>
						<div className=' '>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold h3'>صورة مكتبة الموقع</label>
							</div>

							<RegisterImage
								register={register}
								isRequired={false}
								imageSource={
									process.env.REACT_APP_Video_URL +
									allSiteInfo?.website_library_image
								}
								registerWhat={'website_library_image'}
							/>
						</div>
					</div>

					<div className='d-flex justify-content-center'>
						<button
							className=' btn infos mt-5 mb-5 px-3 py-2 btn1'
							type='submit'>
							<h6>حفظ التعديلات</h6>{' '}
						</button>
					</div>
				</form>
			</Layout>
		</>
	);
};

export default WebsiteFiles;
