import React from 'react';

import './Home.css';
import Layout from '../Layout/Layout';

import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<>
			<Layout>
				<div className='container mt-5 ' style={{ height: '54vh' }}>
					<div className='w-100 pt-5' style={{ height: '100%' }}>
						<div
							className='row pt-5'
							style={{ height: '80%', alignItems: 'center' }}>
							<div className='col-md-4 col-12 text-center'>
								<Link to='/websitetexts' className='btn btn1 shadow'>
									نصوص الموقع
								</Link>
							</div>
							<div className='col-md-4  col-12 text-center'>
								<Link to='/websitefiles' className='btn btn1 shadow'>
									صور وفيديوهات الموقع
								</Link>
							</div>
							<div className='col-md-4  col-12 text-center'>
								<Link to='/updateapplication' className='btn btn1 shadow'>
									معلومات تحديث تطبيق الموبايل
								</Link>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

export default Home;
