

import axios from "axios";
// https://backend.mid-east.icrcompany.net/api/get_all_payments?limit=19
export default async function fetchTransfers({ queryKey }) {
    console.log("process", process.env.REACT_APP_API_URL)
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_payments?page=${queryKey[1]}&type=${queryKey[2]}`,
        { params: { limit: 10 }, headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } }
    );
    return data;
}


