import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import './Missing.css';
import logo from '../../assets/Home/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import nofound from '../../assets/Home/notfound.jpg';
import {useNavigate} from 'react-router-dom';

const Missing = (props) => {

    const userInfos = useSelector((state) => state.Login);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
      console.log('user',userInfos);


      setTimeout(() => {
        const sidebar = document.getElementById('sidebar');
        sidebar.style.transition = 'all 800ms linear';
            console.log(sidebar);
        sidebar.classList.remove('aa');
        sidebar.style.transition = 'all 500ms ease-in';
      }, 3000);
    },[])

    const goBack = () => {
        navigate(-1);
    }

    return <div className='missingPage' style={{backgroundImage: `url(${nofound})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    }}>
 
    
      
        <h3> هذه الصفحة غير موجودة  ☹</h3>
           
        <button className="btn btn-primary px-4 gobackbtn" onClick={() => goBack()}>رجوع</button>
        </div>

}

export default Missing;