
import { useEffect, useRef, useState  } from "react";
import'./Video.css'
import VideoLogic from "./VideoLogic";


export default function Video({setvideo,register,registerWhat,getvalues,isRequired=true}){
  // const [s,sets] = useState(true);
  const[imagesURL,setImagesURL]=useState(null);
  const [remoteVideo,setRemoteVideo] = useState(false);
  const [validVideoLink,setValidVideoLink] = useState('');

  useEffect(() => {
    console.log('get get ' , getvalues()[`${registerWhat}`])
    setRemoteVideo(true);
    const vid = getvalues()[`${registerWhat}`];
    console.log('void', vid)
    // const NewURl = window.URL.createObjectURL();
    // setValidVideoLink(NewURl);

    if(typeof vid == 'object') {
      console.log('in the object type',vid);
    // const NewURl = URL.createObjectURL(vid);
    // setValidVideoLink(NewURl);
    }else if (typeof vid == 'string') {
    const NewURl = vid;
    setValidVideoLink(NewURl);
    }

  },[getvalues()[`${registerWhat}`]])

    const{
      cardref,btnref,imagesURLs,onImageChange}=VideoLogic(setvideo,getvalues,setImagesURL);

    return(
      <>
      
      <div className="bg-white position-relative min-vh-200 shadow  mb-3 d-flex final justify-content-center mb-5 video_container" ref={cardref}>
      {/* {
      getvalues().explainer_video != '' ? <video  controls src={getvalues().explainer_video} style={{'width':'100%','height':'100%','margin':'auto'}}/> 
        : <video  controls src={imagesURL} style={{'width':'100%','height':'100%','margin':'auto'}}/>
      } */}

      {
        remoteVideo && !imagesURL &&  <video  controls src={`${process.env.REACT_APP_Video_URL}${validVideoLink}`} style={{'width':'100%','height':'100%','margin':'auto'}}/>
      }
      {
        imagesURL && <video  controls src={imagesURL} style={{'width':'100%','height':'100%','margin':'auto'}}/>
      }
     
      
        <div className="button ii m-auto add-div position-absolute top-50 left-50" ref={btnref}>
          <i class="fa fa-plus "></i>
        <div >

        <input 
        type='file' 
        className="ool" 
        // multiple accept="video/*" 
        {...register(`${registerWhat}`, { required: isRequired })  } 
        onChange={onImageChange}
        />

        </div></div>
      </div>
      </>
    )
  }