import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function SideBarLogic(){
    const [open,setOpen] = useState(true);
  const location = useLocation();

    return{
        setOpen,open
    }
}