import { useEffect, useRef, useState } from 'react';
import './SubjectImage.css';
import SubjectImageLogic from './SubjectImageLogic';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getImage } from '../../redux-toolkit/reducers/GetImage/GetImage';
export default function SubjectImage({
	valid,
	subscribtionPage,
	register,
	id,
	AddSubscribtion,
	errors,
}) {
	const { cardref, btnref, onImageChange } = SubjectImageLogic(valid);

	const [link, setLink] = useState(null);

	const paramsId = id || 0;

	const [imgLink, setImgLink] = useState(null);

	useEffect(() => {
		console.log(
			'subscribtionsPageeeeeeeeee image',
			subscribtionPage?.subject_image
		);

		if (paramsId) {
			setImgLink(subscribtionPage?.subject_image);
		}
	}, [subscribtionPage]);

	let img = null;
	if (paramsId) {
		img = `${process.env.REACT_APP_Video_URL}${imgLink}`;
	}

	const handleImage = (e) => {
		const [file] = e.target.files;
		setLink(URL.createObjectURL(file));
	};

	useEffect(() => {
		img = null;
		setLink(null);
	}, [AddSubscribtion]);

	return (
		<>
			<div
				className='bg-white    shadow  mb-4 d-flex finals justify-content-center parent_btn'
				ref={cardref}>
				<img
					src={link || img}
					style={{ width: '16rem', height: '16rem', margin: 'auto' }}
				/>
				<div className='button  m-auto add-div btn_child' ref={btnref}>
					<i className='fa fa-plus '></i>
					<div>
						<input
							type='file'
							className='ool'
							multiple
							accept='image/*'
							{...register('subject_image', { required: id ? false : true })}
							onChange={handleImage}
						/>
						{errors.subject_image && (
							<p className='text-danger'>*required image</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
