import React, { useRef, useState,useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import record from'../../assets/Home/record.png'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Circle } from 'rc-progress';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';


export default function () {
        const userData = useSelector(state => state.Login);
        const [mainVideoFile,setMainVideoFile] = useState('');
       const [aboutUsImage,setAboutUsImage] = useState('');
       const [allSiteInfo,setAllSiteInfo] = useState(null);
       const [progress,setProgress] = useState(0);
       const [IsLoading,setIsLoading] = useState(false);
       const [isUploadSuccessfuly,setIsUploadSuccessfuly] = useState(false);
       const [imageChanged,setImageChnaged] = useState(false);
       const [successMessage , setSuccessMessage] = useState('');
       const [ErrorMessage , setErrorMessage] = useState('');
       const [teacherImage,setTeacherImage] = useState(null);
       const navigate = useNavigate();

       const [isSuccess,setIsSuccess] = useState(false);

       const [defauluseFormVlaues,setDefauluseFormValues] = useState({
        payment_balance: '' ,
        payment_image:  '',
        student_username: '',
    });

    const params = useParams();

    
    const { reset,getValues,watch,setValue ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = 
    useForm({mode: "onChange",defaultValues: defauluseFormVlaues});


    useEffect(() => {
        setImageChnaged(true);
    },[watch('image')]);

    useEffect(() => { 
        axios.get(`${process.env.REACT_APP_API_URL}/get_payment_by_id/${params.id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {

            console.log('teacher data',data.data.data)

            const teacherdetails = data.data.data;

            // setValue('student_username',teacherdetails.student_username);
            setValue('balance',teacherdetails.payment_balance);
            // setValue('payment_image',teacherdetails.payment_image);


            setTeacherImage(teacherdetails.payment_image);

        })
        .catch(error => console.log(error));
   },[]);

    const handleOnSubmit = (data) => {
        // e.preventDefault();
        console.log('data',data)
        console.log("submitting data to server");
        setIsLoading(true);

        const ourFormData = new FormData();


        // ourFormData.append('student_username',data.student_username);
        // ourFormData.append('username',data.username);
        ourFormData.append('balance',data.balance);
        // ourFormData.append('password',data.password);
        // ourFormData.append('description',data.description);
        if(typeof getValues().payment_image != 'string'){
            ourFormData.append('payment_image',data.payment_image[0]);
        }



        for(var pair of ourFormData.entries()) {
            console.log(pair[0]+ ', '+  pair[1]); 
            // console.log('pair',pair);
       }

        axios.post(`${process.env.REACT_APP_API_URL}/edit_payment/${params.id}`, ourFormData, {

        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          'Content-Encoding': 'gzip , deflate, br',
          'content-type': 'text/json',
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${userData.token}`
        },
    
  
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          console.log(progress);
          setProgress(progress);
          setIsLoading(true);
        }

      }).then(res=>{
        if(res.status === 200)
        {
          const {data} = res;
          console.log('data from post api' , data);
        setIsLoading(false)
        setIsUploadSuccessfuly(true);
        setIsSuccess(true);

        
        setSuccessMessage("تم تعديل معلومات الدفع بنجاح")

        setTimeout(() => {
            navigate(-1);
        }, 1200);

        setTimeout(() => {
            setIsSuccess(false);    
        }, 4000);

          return res;
        }
        else
        {
            setErrorMessage('غير متصل بالشبكة');
            setIsLoading(false);
          console.log(res);
          throw new Error('there is an error');
        }
      }).catch(error=>{
         setIsLoading(false);
         setErrorMessage('حدث خطأ ما');
        console.log(error.response);

      }
        );
      }

      useEffect(() => {
        if(progress == 100) {
            setIsLoading(false);
        }
      },[progress]);

      
      const changeColor = (progress) => {
        if(progress <= 25){
            return '#ff0707';
        }else if(progress <= 50){
            return '#ff6907';
        }else if(progress <= 75){
            return '#ffdb07';
        }else {
            return '#77ff07';
        }
      }


  return (
   
        <>

        
        <Layout>
            <div className='container all'>
            <ToastSuccessError 
            successMessage={successMessage}
            ErrorMessage={ErrorMessage} 
            setSuccessMessage={setSuccessMessage}    
            setErrorMessage={setErrorMessage}    
            />


            
			{IsLoading && (
				<div className={`uploadPrgressbar`}>
					<h2>جار تعديل معلومات الدفع  ... </h2>

					<div
						className='content'
						style={{ dispaly: typeof progress != 'number' ? 'none' : 'flex' }}>
						{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
						<p>{progress}%</p>
						<Circle
							percent={progress}
							strokeWidth={4}
							strokeColor={changeColor(progress)}
						/>
					</div>
				</div>
			)}

        <form onSubmit={handleSubmit(handleOnSubmit)}>



                
        {/* <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> اسم الطالب</label>
            </div>
           <input type='text' {...register("student_username", { required: true })} className='shadow w-100 main-input border-0'/>
        </div> */}


                


                
        {/* <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'> صورة الحوالة</label>
            </div>
            <RegisterImage  
            register={register} 
            isUploadSuccessfuly={isUploadSuccessfuly}  
            registerWhat={"payment_image"} 
            isRequired={false}
            teacherImage={process.env.REACT_APP_Video_URL + teacherImage}
            />

        </div> */}






        

                <div className='mb-3'>
            <div className='mb-3 d-flex align-items-center'>
                <img src={record} className='record mx-2'/>
                <label className='fw-bold'>قيمة الحوالة </label>
            </div>
           <input type='number' {...register("balance", { required: true })} className='shadow w-100 main-input border-0'/>
           {/* {errors.the_vision && <p className='text-danger'>*required</p>} */}
        </div>







            <div className='d-flex justify-content-center mt-5'>

           { !IsLoading ?
            <button className=' btn infos px-3 py-2 border-0 btn1' type='submit'><h6>تعديل بيانات الحوالة </h6> </button>
            : 
            <div className='mt-2 d-flex justify-content-center' >
              <div class="spinner-border mx-auto" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
           </div>
           }     
        
         </div>

   
        </form>
        </div>
        </Layout>
        </>
    
  )
}
