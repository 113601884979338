import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function TableTeacherSubjects({ data, isLoading, subjectId }) {
	const [dataSubject, setDataSubject] = useState(data);
	const [flag, setFlag] = useState(0);

	//  if(dataSubject.length===0 && flag===1){
	//     window.location.reload();
	// }

	const handleDeleteSubject = (id) => {
		console.log('idddddd subject', id);
		const temp = [...dataSubject];
		const DataDelte = temp.filter((item) => item.teacher_id !== id);
		console.log('Data Deleteeed', DataDelte);
		setDataSubject(DataDelte);
		setFlag(1);
	};

	useEffect(() => {
		console.log('data from react query', data);
		setDataSubject(data);
	}, [data]);

	async function deletePost(id) {
		console.log('iddddd', id);

		// let Data={
		//     "subject_id":subjectId,
		//     "teacher_id":id
		// }
		// console.log("dataaaa",Data);

		const res = axios
			.delete(
				`${process.env.REACT_APP_API_URL}/unlink_subject_with_a_teacher`,
				{
					headers: {
						'content-type': 'text/json',
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						'Content-Encoding': 'gzip , deflate, br',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					data: {
						subject_id: subjectId,
						teacher_id: id,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data delete Subscribtion', data);
					handleDeleteSubject(id);
					alert('تم الحذف بنجاح');
					return res;
				} else {
					console.log(res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	}
	// setStatus('Delete successful');
	const handleDelete = (id) => {
		// DELETE request using fetch with async/await
		console.log('no id', id);
		deletePost(id);
		// handleDeleteSubject(id);
	};

	return (
		<>
			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> اسم الاستاذ</th>
						<th className='text-center text-white firdt_child'> الأرباح </th>
						<th className='text-center text-white last_child'> العمليات</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={2}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : dataSubject ? (
						dataSubject.length > 0 &&
						dataSubject?.map((item, index) => {
							return (
								<tr key={index}>
									<td className='text-center'>{item?.teacher_name}</td>
									<td className='text-center'>{item?.balance}</td>
									<td className='text-center'>
										{/* <i className='fa fa-check yes fa-2x'> </i>  */}
										{/* <Link to={`/mainsubscribtion/${item?.subscription_id}`} className="ms-3 btn  btn1">show</Link> */}
										{/* <Link to={`/subscribe/${item?.subscription_id}`} className="ms-3 btn btn-success">edit</Link> */}
										<button
											onClick={() => handleDelete(item?.teacher_id)}
											className='ms-3 btn  btn2'>
											حذف
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={2} className='text-center py-5 '>
							<div>
								<h5>لايوجد اساتذه</h5>{' '}
							</div>
						</td>
					)}
				</tbody>
			</table>
		</>
	);
}
