import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
// import Image from '../Image/Image'
import SectionsImage from './SectionsImage';
import record from '../../assets/Home/record.png';
import { useForm } from 'react-hook-form';
import SectionsLogic from './SectionsLogic';
import Layout from '../Layout/Layout';

import './Sections.css';

import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import { Circle } from 'rc-progress';

export default function Sections({ handleAdd, subscribtionPage }) {
	const [success, setSuccess] = useState(false);
	const [faild, setFaild] = useState(false);

	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');

	const params = useParams();
	const id = params?.id;

	const [idTeacher, setIdTeacher] = useState(null);
	const [subSectionId, setSubSectionId] = useState(null);

	const [teachers, setTeachers] = useState([]);
	const [subSections, setSubSetSections] = useState([]);
	const [SubSelectedSection, setSelectedSubSection] = useState([]);
	const [sections, setSections] = useState([]);
	const [selectedSection, setSelectedSection] = useState(null);

	const {
		reset,
		getValues,
		formState,
		watch,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			section_name: '',
			section_image: null,
		},
	});

	const {
		valid,
		handleSubmitApi,
		handleUpdateApi,
		AddSubscribtion,
		progress,
		IsLoading,
	} = SectionsLogic(
		subscribtionPage,
		id,
		reset,
		idTeacher,
		subSectionId,
		setSuccessMessage,
		setErrorMessage,
		setSuccess,
		setFaild
	);

	useEffect(() => {
		console.log('iddd', id);
		if (id) {
			const sectionsFromAPI = axios
				.get(
					`${process.env.REACT_APP_API_URL}/get_section_by_section_id/${id}?limit=19`,
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					}
				)
				.then((data) => {
					console.log('log sectionssssssssss', data.data.data);
					setSections(data.data.data);
					reset(data.data.data);
				});
		} else console.log('idddElse', id);
	}, []);

	const tempId = id || 0;
	console.log('tempIDdd', tempId);
	console.log('Subscribtionnnnnn nameeeeeeeee', getValues().subscription_name);

	const changeColor = (progress) => {
		if (progress <= 25) {
			return '#ff0707';
		} else if (progress <= 50) {
			return '#ff6907';
		} else if (progress <= 75) {
			return '#ffdb07';
		} else {
			return '#77ff07';
		}
	};

	return (
		<Layout>
			<div className='container subject'>
				{IsLoading && (
					<div className={`uploadPrgressbar`}>
						<h2>جار رفع الملفات الرجاء الانتظار ... </h2>

						<div className='content'>
							{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
							<p>{progress}%</p>
							<Circle
								percent={progress}
								strokeWidth={4}
								strokeColor={changeColor(progress)}
							/>
						</div>
					</div>
				)}

				<ToastSuccessError
					successMessage={successMessage}
					ErrorMessage={ErrorMessage}
					setSuccessMessage={setSuccessMessage}
					setErrorMessage={setErrorMessage}
				/>

				{console.log('success message', successMessage)}
				<h1
					className=' '
					style={{
						color: '#044364',
						// borderBottom: '1px solid green'
					}}>
					إضافة قسم
				</h1>
				<form onSubmit={handleSubmit(handleSubmitApi)}>
					<div className='mb-3 '>
						<div className='mb-3 d-flex align-items-center gap-5 pt-5'>
							<img src={record} className='record mx-2' />
							<label className='fw-bold '> صورة القسم </label>
						</div>
						<SectionsImage
							valid={valid}
							register={register}
							id={id}
							subscribtionPage={sections}
							AddSubscribtion={AddSubscribtion}
							errors={errors}
						/>
					</div>
					<div className='mb-3'>
						<div className='mb-3 d-flex align-items-center'>
							<img src={record} className='record mx-2' />
							<label className='fw-bold'> اسم القسم</label>
						</div>
						<div className='d-flex '>
							<input
								type='text'
								className='shadow w-50 py-2 border-0'
								{...register('section_name', { required: true })}
							/>
							{errors.section_name && <p className='text-danger'>*required</p>}
						</div>
					</div>

					<div className='d-flex justify-content-center mb-5'>
						{!IsLoading && tempId ? (
							<button
								className=' btn1 info  pt-3 pb-2 px-5 '
								style={{ borderRadius: '5px' }}>
								<h6>تعديل </h6>
							</button>
						) : null}

						{!IsLoading && !tempId ? (
							<button
								className=' btn1 info  pt-3 pb-2 px-5 '
								style={{ borderRadius: '5px' }}>
								<h6>إضافة </h6>
							</button>
						) : null}

						{IsLoading && (
							<div className='mb-3 d-flex justify-content-center'>
								<div class='spinner-border mx-auto' role='status'>
									<span class='visually-hidden'>Loading...</span>
								</div>
							</div>
						)}
					</div>

					{/* <h1>{formState.errors?.content }</h1> */}

					{
						// IsLoading ? <div className='mb-3 d-flex justify-content-center' >
						//         <div class="spinner-border mx-auto" role="status">
						//         <span class="visually-hidden">Loading...</span>
						//       </div>
						//       </div>
						//     :
						// <button className=' btn infos px-3 py-2 mt-5 mb-5 border-0 btn-primary' type='submit'><h6>إنشاء حساب ولي أمر </h6> </button>
					}
				</form>
			</div>
		</Layout>
	);
}
