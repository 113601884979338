import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import Pagination from '../Pagination/Pagination';
import TableSections from '../ConfirmRequest/TableSections';
import ShowSectionsLogic from './ShowSectionsLogic';

const ShowSections = () => {
	console.log('YESSSSSSSSSS');
	const {
		listRef,
		isLoading,
		isError,
		data,
		error,
		handlePageClick,
		page,
		setPage,
	} = ShowSectionsLogic();
	console.log('sections Data', data);
	console.log('current page', page);
	const dataSections = data?.data;
	const [status, setStatus] = useState(0);

	return (
		<>
			<Layout>
				<div className='container all'>
					<h1
						className=' pt-3'
						style={{
							color: '#044364',
							// borderBottom: '1px solid green'
						}}>
						الأقسام الرئيسية
					</h1>
					<div className='d-flex justify-content-around mb-5'></div>

					{
						<TableSections
							data={dataSections}
							isLoading={isLoading}
							page={page}
							setPage={setPage}
							setStatus={setStatus}
						/>
					}

					{data?.total < 10 ? (
						<> </>
					) : (
						<Pagination
							data={data}
							handlePageClick={handlePageClick}
							page={page}
						/>
					)}
				</div>
			</Layout>
		</>
	);
};

export default ShowSections;
