import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import observable from "../../components/Observer/Observer";

function logger(data) {
  console.log(`${Date.now()} ${data}`);
}

function toastify(data) {
  toast(data, {
    position: toast.POSITION.BOTTOM_RIGHT,
    closeButton: false,
    autoClose: 3000
  });
}

observable.subscribe(logger);
observable.subscribe(toastify);

export default function ToastNotify({showMessage,setIsError,message}) {

    useEffect(() => {

        if(showMessage){
        observable.notify(message);
        }

    },[showMessage]);

  return (<ToastContainer />);
}
