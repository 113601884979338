import React , {useState,useRef , useEffect} from 'react'
import { Link, useParams } from 'react-router-dom';

import Layout from '../Layout/Layout'
import Pagination from '../Pagination/Pagination';
import SubjectsForStudentLogic from './SubjectsForStudentsLogic';
import TableStudenSubscribtion from '../ConfirmRequest/TableStudenSubscribtion';
import fetchStudentSubscribtion from "../../redux-toolkit/api/fetchStudentSubscribtion";


import {
  QueryClient,
} from 'react-query'
import axios from 'axios'
import TableSubjectsForStudent from '../ConfirmRequest/TableSubjectsForStudent';

const SubjectsForStudent = () => {


const params = useParams()
const id =params?.id; 

  console.log("YESSSSSSSSSS");
    const  {listRef,isLoading, isError, data, error,handlePageClick,page ,setPage} = SubjectsForStudentLogic(id);
    console.log("sections Data" ,data);
    console.log("current page" , page);
    // const dataSections = data?.data
    const [status,setStatus] = useState(0);
    const [ErrorMessage,setErrorMessage] = useState()




    const [sections,setSections] = useState([]);
    const [subSections,setSubSetSections] = useState([]);
    const [subjects,setSubject] = useState([]);


    const [selectedSection,setSelectedSection] = useState(null);
    const [selectedSubSection,setSelectedSubSection] = useState(null);
    const [selectedSubject,setSelectedSubject] = useState(null);



  

    



  

    // const handleSearchStudent = (e) => {
        

    //     // setValue('student_id',e.target.value);
        
    //     axios.get(`${process.env.REACT_APP_API_URL}/students_search?q=${e.target.value}`,
    //     {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    //     .then(response => {
    //         console.log('search response',response.data.data);
    //     })
    //     .catch(error => {
    //         console.log('error while fetching search students', error)
    //     });

    // }


    //---------------------------------get the subjects======================//
    useEffect( () => {
      const sectionsFromAPI =  axios.get(`${process.env.REACT_APP_API_URL}/get_all_sections?limit=19`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
      .then(data => {
        console.log('log',data.data.data)
        setSections(data.data.data);
      });

      // console.log('sections from api',sectionsFromAPi);
    },[])

    useEffect(() => {
      // console.log('section changed')
      // console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
      const subSectionsAPI = axios.get(`${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}?limit=19`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
      .then(data => {
        console.log('subsection ', data.data.data);
        setSubSetSections(data.data.data.data);
      })
    }, [selectedSection])


    useEffect(() => {
      // console.log('section changed')
      // console.log('api url', `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${selectedSection}`)
      const subSectionsAPI = axios.get(`${process.env.REACT_APP_API_URL}/get_subjects_by_sub_section?sub_section_id=${selectedSubSection}&limit=19`,
    {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
      .then(data => {
        console.log('subjects ', data.data.data);
        setSubject(data.data.data);
      })
    }, [selectedSubSection])



    const handleSectionChange = (e) => {
      // console.log(e.target.value);
      setSelectedSection(e.target.value);
      setSubSetSections([]);
    }
    
    const handleSubSectionChange = (e) => {
      // console.log(e.target.value);
      setSelectedSubSection(e.target.value);
      setSubject([]);
    }
    const handleSubjectChange = (e) => {
      // console.log(e.target.value);
      setSelectedSubject(e.target.value);
    }


    // -----------------------end get the subjects ------------ //
   


      const handleAddSubject  = (e)=>{
    
      e.preventDefault()
      // let formData = new FormData();
      // setImage(data.image[0]);
      // formData.append("student_id",stuentId)
      // formData.append("subscription_id",id)
      //  formData.append("subscription_id" ,id);
      const Data= {
        "student_id" : id,
        "subject_id":selectedSubject
      }
      console.log("object send " , Data);
       const res = axios.post(`${process.env.REACT_APP_API_URL}/add_student_subject_by_subject_id_and_student_id`, Data, {
         headers: {
           'content-type': 'text/json',
           "Content-Type": "multipart/form-data",
           "Accept": "application/json",
           'Content-Encoding': 'gzip , deflate, br',
           "Authorization":`Bearer ${localStorage.getItem('token')}`
         }
       }).then(res=>{
         if(res.status===200){
           const {data} = res;
           console.log('data from add student',data);
          //  alert('added subject is success')
           window.location.reload();
          //  prefetchTodos()
        //    SetValid(true);
           return res;
         }
         else
         {
           console.log(res);
           throw new Error('there is an error')
         }
       }).catch(error=>{
         console.log(error.response.data.message);
         setErrorMessage(error.response.data.message);
       });
     
  }




    return (
      <>
      <Layout>
          <div className='container all'>

          {/* {ErrorMessage && <div  className='alert alert-danger' dangerouslySetInnerHTML={{__html:ErrorMessage}}  />
          } */}

          <h1 
          className='pt-0 mb-4 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >المواد
          </h1>
              <div className='d-flex justify-content-around mb-5'>           
              </div>
  
              {
                       <TableSubjectsForStudent data={data?.data} isLoading={isLoading} page={page} setPage={setPage} setStatus={setStatus}/>
              }

            
  
                   {/* {data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }   */}
              </div>





              <form>
              <div className='selects__container'>
              <div className='d-flex flex-column align-items-center justify-centent-center'>
                <p>اختر القسم</p>
                      <select onChange={(e) => handleSectionChange(e)} className="form-select customSelect" aria-label="Default select example">
                    <option selected>اختر القسم</option>
                    {
                      sections && sections?.map((section,index) => <option key={index} value={section.section_id}>{section.section_name}</option>)
                    }
                    
                  </select>
               </div>

               <div className='d-flex flex-column align-items-center justify-centent-center'>
                <p>اخترالبرنامج</p>
          <select onChange={(e) => handleSubSectionChange(e)}  className="form-select  customSelect" aria-label="Default select example">
          <option selected>Open this select menu</option>
            {
             subSections && subSections?.map((subsection,index) => <option key={index} value={subsection.sub_section_id}>{subsection.sub_section_name}</option>)
            }
        </select>
        </div>

        <div className='d-flex flex-column align-items-center justify-centent-center'>
                <p>اختر الماده</p>

      <select onChange={(e) => handleSubjectChange(e)}  className="form-select  customSelect" aria-label="Default select example">
        <option selected>Open this select menu</option>
        {
          subjects && subjects?.map((subject,index) => <option key={index} value={subject.subject_id}>{subject.subject_name}</option>)
        }
      </select>

      </div>

      </div>

               <button type='submit' className='btn infos px-3 py-2' onClick={handleAddSubject}>إضافة</button>
      
              </form>

             
           

      </Layout>

      </>
    )
}

export default SubjectsForStudent ;
              
              
    