import React, { useRef, useState, useEffect } from 'react';
import Layout from '../Layout/Layout';
import record from '../../assets/Home/record.png';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Circle } from 'rc-progress';
import RegisterImage from '../../components/RegisterImage/RegisterImage';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import eye from '../../assets/Home/eye.svg';
import eyehide from '../../assets/Home/eyehide.svg';

export default function () {
	const userData = useSelector((state) => state.Login);
	const [mainVideoFile, setMainVideoFile] = useState('');
	const [aboutUsImage, setAboutUsImage] = useState('');
	const [allSiteInfo, setAllSiteInfo] = useState(null);
	const [progress, setProgress] = useState(0);
	const [IsLoading, setIsLoading] = useState(false);
	const [isUploadSuccessfuly, setIsUploadSuccessfuly] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [countries, setCountries] = useState([]);

	const {
		reset,
		getValues,
		watch,
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			name: '',
			username: '',
			email: '',
			password: '',
			gender: '',
			image: '',
			description: '',
			phone_number: '',
			transable: 0,
			telegram_url: '',
			country_id: '',
			balance: ''
		},
	});

	const handleOnSubmit = (data) => {
		// e.preventDefault();
		console.log('data', data);
		console.log('submitting data to server');
		setIsLoading(true);
		const ourFormData = new FormData();

		ourFormData.append('name', data.name);
		ourFormData.append('username', data.username);
		ourFormData.append('email', data.email);
		ourFormData.append('password', data.password);
		ourFormData.append('description', data.description);
		ourFormData.append('image', data.image[0]);
		ourFormData.append('phone_number', data.phone_number);
		ourFormData.append('gender', data.gender);
		ourFormData.append('transable', data.transable);
		ourFormData.append('telegram_url', data.telegram_url);
		ourFormData.append('country_id', data.country_id);
		ourFormData.append('balance', data.balance);

		for (var pair of ourFormData.entries()) {
			console.log(pair[0] + ', ' + pair[1]);
			// console.log('pair',pair);
		}

		axios
			.post(`${process.env.REACT_APP_API_URL}/add_teacher`, ourFormData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json',
					'Content-Encoding': 'gzip , deflate, br',
					'content-type': 'text/json',
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${userData.token}`,
				},

				onUploadProgress: (progressEvent) => {
					const progress = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					console.log(progress);
					setProgress(progress);
					setIsLoading(true);
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data from post api', data);
					setIsLoading(false);
					setIsUploadSuccessfuly(true);
					setSuccessMessage('تم إنشاء حساب الاستاذ بنجاج');

					setTimeout(() => {
						navigate(-1);
					}, 1000);

					reset();

					return res;
				} else {
					setIsLoading(false);
					console.log('res inside else statment', res);
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log('res inside catch statment', error.response);
				setErrorMessage(error.response.data.message);
				//  setIsError(error.response.data)
			});
	};

	useEffect(() => {
		if (progress == 100) {
			setIsLoading(false);
		}

		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_countries`)
			.then((data) => {
				console.log('countries', data.data.data);
				setCountries(data.data.data);
			})
			.catch((error) => {
				console.log('error inside countries', error);
			});
	}, [progress]);

	const changeColor = (progress) => {
		if (progress <= 25) {
			return '#ff0707';
		} else if (progress <= 50) {
			return '#ff6907';
		} else if (progress <= 75) {
			return '#ffdb07';
		} else {
			return '#77ff07';
		}
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>
			{IsLoading && (
				<div className={`uploadPrgressbar`}>
					<h2>جار إنشاء حساب الاستاذ ... </h2>

					<div className='content'>
						{/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
						<p>{progress}%</p>
						<Circle
							percent={progress}
							strokeWidth={4}
							strokeColor={changeColor(progress)}
						/>
					</div>
				</div>
			)}
			<Layout>
				<div className='container all subject'>
					<h1
						className='pt-0 mb-4 pb-4 pt-3'
						style={{
							color: '#044364',
							// borderBottom: '1px solid green'
						}}>
						إضافة استاذ
					</h1>
					<form onSubmit={handleSubmit(handleOnSubmit)}>
						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اسم الاستاذ</label>
							</div>
							<input
								type='text'
								{...register('name', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.name && (
								<p className='text-danger text-right'>*required</p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> اسم المستخدم</label>
							</div>
							<input
								type='text'
								{...register('username', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.username && (
								<p className='text-danger text-right'>هذا الحقل مطلوب</p>
							)}
						</div>
						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> الأرباح </label>
							</div>
							<input
								type='number'
								{...register('balance', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.balance && (
								<p className='text-danger text-right'>هذا الحقل مطلوب</p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> الايميل</label>
							</div>
							<input
								type='text'
								{...register('email', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.email && (
								<p className='text-danger text-right'>هذا الحقل مطلوب</p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> كلمة السر</label>
							</div>
							<div className='wrapper_input_password '>
								<input
									type={showPassword ? 'text' : 'password'}
									{...register('password', { required: true })}
									className='shadow w-100 main-input border-0'
								/>
								<div className='overlay_password_icon'>
									<img
										src={!showPassword ? eye : eyehide}
										onClick={() => setShowPassword(!showPassword)}
									/>
								</div>
								{errors.the_vision && (
									<p className='text-danger text-right'>هذا الحقل مطلوب</p>
								)}
							</div>
						</div>

						<div className='m-3'>
							<div className='mb-3  d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> الجنس</label>
							</div>
							<select
								type='text'
								{...register('gender', { required: true })}
								className='shadow w-100 border-0 py-2'>
								<option value='MALE'>ذكر</option>
								<option value='FEMALE'>أنثى</option>
							</select>
							{errors.gender && (
								<p className='text-danger text-right'>هذا الحقل مطلوب</p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> صورة الاستاذ</label>
							</div>
							<RegisterImage
								register={register}
								isUploadSuccessfuly={isUploadSuccessfuly}
								registerWhat={'image'}
							/>

							{/* <input type='text' {...register("name", { required: true })} className='shadow w-100 main-input border-0'/> */}
							{errors.image && (
								<p className='text-danger text-right'>يجب ادخال </p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'> وصف عن الاستاذ</label>
							</div>
							<input
								type='text'
								{...register('description', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.description && (
								<p className='text-danger text-right'>*required</p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'>رقم الهاتف</label>
							</div>
							<input
								type='number'
								{...register('phone_number', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.phone_number && (
								<p className='text-danger text-right'>*required</p>
							)}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'>البلد</label>
							</div>
							<select
								{...register('country_id', { require: true })}
								className='shadow w-100 main-input border-0'>
								{countries.map((country, index) => {
									return (
										<option key={country.id} value={country.id}>
											{country.name}
										</option>
									);
								})}
							</select>
							{/* <input type='number' {...register("phone_number", { required: true })} className='shadow w-100 main-input border-0'/> */}
							{/* {errors.phone_number && <p className='text-danger text-right'>*required</p>} */}
						</div>

						<div className='mb-3'>
							<div className='mb-3 d-flex align-items-center'>
								<img src={record} className='record mx-2' />
								<label className='fw-bold'>
									رابط حساب الاستاذ على تيليجرام
								</label>
							</div>
							<input
								type='text'
								{...register('telegram_url', { required: true })}
								className='shadow w-100 main-input border-0'
							/>
							{errors.telegram_url && (
								<p className='text-danger text-right'>هذا الحقل مطلوب</p>
							)}
						</div>

						<div className='d-flex justify-content-center mt-5'>
							{!IsLoading ? (
								<button
									className=' btn infos px-3 py-2 my-5 border-0 btn1'
									type='submit'>
									<h6>إنشاء حساب أستاذ </h6>{' '}
								</button>
							) : (
								<div className='mt-2 d-flex justify-content-center'>
									<div class='spinner-border mx-auto' role='status'>
										<span class='visually-hidden'>Loading...</span>
									</div>
								</div>
							)}
						</div>
					</form>
				</div>
			</Layout>
		</>
	);
}
