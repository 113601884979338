// subjects for subscribtionsss

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
export default function TableSections({
	data,
	isLoading,
	page,
	setStatus,
	setPage,
}) {
	const [dataSubject, setDataSubject] = useState(data);
	const [flag, setFlag] = useState(0);
	const [minusPage, setMinusPage] = useState(0);
	const [ErrorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	//  if(dataSubject.length===0 && flag===1){
	//     window.location.reload();
	// }

	const handleDeleteSections = (id) => {
		console.log('idddddd subject', id);
		const temp = [...dataSubject];
		const DataDelte = temp.filter((item) => item.section_id !== id);
		console.log('Data Deleteeed', DataDelte);
		setDataSubject(DataDelte);
		if (DataDelte.length === 0 && page > 1) {
			setPage(page - 1);
			setMinusPage(0);
			setStatus(1);
		}
		setFlag(1);
	};

	useEffect(() => {
		console.log('data from react query', data);
		setDataSubject(data);
	}, [data]);

	async function deletePost(id) {
		console.log('iddddd', id);
		const res = axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_section_by_id/${id}`, {
				headers: {
					'content-type': 'text/json',
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json',
					'Content-Encoding': 'gzip , deflate, br',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('data delete Subscribtion', data);
					handleDeleteSections(id);
					setSuccessMessage('تمت عملية الحذف بنجاح');
					return res;
				} else {
					console.log(res);
					setErrorMessage('غير متصل بالشبكة');
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				console.log(error.response);
				setErrorMessage(error.response.data.message);
			});
	}
	// setStatus('Delete successful');
	const [idDelete, setIdDelete] = useState();
	const handleDelete = (id) => {
		// DELETE request using fetch with async/await
		console.log('no id', id);
		setIdDelete(id);
		// deletePost(id);
		// handleDeleteSections(id);
	};

	const handleDeleteItem = () => {
		deletePost(idDelete);
	};

	return (
		<div style={{ minHeight: '400px', width: '100%' }}>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>
			<div className='text-center my-5'>
				<Link to='/addSections'>
					<button className='btn  btn1 '>إضافة قسم</button>
				</Link>
			</div>

			<table className='table table_style  mt-3   text-center  table-bordered  table-striped'>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> القسم </th>
						<th className='text-center text-white last_child'> العمليات</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={2}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : dataSubject ? (
						dataSubject.length > 0 &&
						dataSubject?.map((item, index) => {
							return (
								<tr key={index}>
									<td className='text-center'>{item?.section_name}</td>
									<td className='text-center'>
										{/* <i className='fa fa-check yes fa-2x'> </i>  */}
										<Link
											to={`/showSubSections/${item?.section_id}`}
											state={{ sectionId: item?.section_id }}
											className='ms-3 btn  btn1'>
											عرض
										</Link>
										<Link
											to={`/addSections/${item?.section_id}`}
											state={{ sectionId: item?.section_id }}
											className='ms-3 btn btn-success'>
											تعديل
										</Link>
										<button
											onClick={() => handleDelete(item?.section_id)}
											className='ms-3 btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#staticBackdrop'>
											حذف
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={2} className='text-center py-5 '>
							<div>
								<h5>لايوجد اقسام</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				id='staticBackdrop'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
				tabindex='-1'
				aria-labelledby='staticBackdropLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div class='modal-header'>
							<h5 className='modal-title' id='staticBackdropLabel'></h5>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div className='modal-body'>هل انت متاكد انك تريد الحذف</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn  btn1'
								data-bs-dismiss='modal'>
								لا
							</button>
							<button
								type='button'
								className='btn  btn2'
								data-bs-dismiss='modal'
								onClick={handleDeleteItem}>
								نعم
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
