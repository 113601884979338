import React, {useEffect, useRef, useState} from 'react';
import { useQuery } from 'react-query';
import ConfirmRequest from '../../components/ConfirmRequest/ConfirmRequest';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import fetchLibraryStudents from '../../redux-toolkit/api/fetchLibraryStudents';
import LibrarySectionsTable from '../../components/ConfirmRequest/LibrarySectionsTable';
// import './Library.css';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import group from'../../assets/AboutUs/Group 408.png'
import LibraryStudents from '../../components/ConfirmRequest/LibraryStudents';
import fetchStudents from '../../redux-toolkit/api/fetchStudents';
import axios from 'axios';

const Guirdians = (props)=> {

  const [page,setPage] =useState(1);
  const params = useParams();
  const {state} = useLocation();
//   console.log('params '.repeat(20),params)
  const {isLoading, isError, data, error} = useQuery(['library',page,params.sectionId], fetchLibraryStudents);

    function handlePageClick (data)
    {
      setPage(data.selected+1);
    }

    const [searchResult,setSearchResult] = useState('');
    // const [selectedStudent,setSelectedStudent] = useState('');
    const inputSearchRef = useRef();
    const [stuentId , setStduentId] = useState()
    const [errorMessage,setErrorMessage] = useState();
    const [selectedStudent,setSelectedStudent] = useState(null);

    // const { isLoading , isError, data, error } = useQuery(['students',page , query], fetchStudents);
  
    // const [query,setQuery] = useState('');
  
    const unOrderStudentsList = useRef();

    
    const handleAddStudent  = (e)=>{
    
        e.preventDefault()
          let formData = new FormData();
          // setImage(data.image[0]);
          formData.append("student_id",stuentId)
          formData.append("library_section_id",params.sectionId)
          //  formData.append("subscription_id" ,id);
           const res = axios.post(`${process.env.REACT_APP_API_URL}/add_student_library_section`, formData, {
             headers: {
               'content-type': 'text/json',
               "Content-Type": "multipart/form-data",
               "Accept": "application/json",
               'Content-Encoding': 'gzip , deflate, br',
               "Authorization":`Bearer ${localStorage.getItem('token')}`
             }
           }).then(res=>{
             if(res.status===200){
               const {data} = res;
               console.log('data from add student',data);
               window.location.reload();
              //  prefetchTodos()
            //    SetValid(true);
               return res;
             }
             else
             {
               console.log(res);
               throw new Error('there is an error')
             }
           }).catch(error=>{
             console.log(error.response.data.message);
             setErrorMessage(error.response.data.message);
           });
         
      }

    const handleSelectStudentFromDrobDownList = (student) => {
        // setValue('student_id',student.student_id);
        setSelectedStudent(student);
        setStduentId(student.student_id)
        console.log('student which was selected by user => ' , student)
        unOrderStudentsList.current.style.height = '0';
        inputSearchRef.current.value = student.name;
    }

    const handleSearchStudent = (e) => {
        
        unOrderStudentsList.current.style.height = 'auto';

        handleUnorderStudentsList(e.target.value);
        // setValue('student_id',e.target.value);
        
        axios.get(`${process.env.REACT_APP_API_URL}/students_search?q=${e.target.value}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(response => {
            console.log('search response',response.data.data);
            setSearchResult(response.data.data)
        })
        .catch(error => {
            console.log('error while fetching search students', error)
        });

    }
    const handleUnorderStudentsList = (searchQuery) => {
      if(searchQuery == '') {
          unOrderStudentsList.current.style.height = '0px';
      }else {
          unOrderStudentsList.current.style.height = 'auto';
      }
    }


    

    useEffect(() => {
        console.log('data inside library section students', data);
    },[data])
    return <Layout>
          <div className='container all'> 
          <h1 
          className='pt-0 mb-1 pb-4 pt-3' 
          style={{
              // borderBottom: '1px solid green'
          }}
          >{state?.sectionName}
          </h1>


          <div className='border shadow rounded p-4 mb-5'>
            <h2 className='text-primary text-center w-100 mb-5'>يمكنك البحث عن طالب عن طريق الاسم لاضافته للقسم  ({state?.sectionName}) </h2>
          <input 
          type='text' 
          ref={inputSearchRef} 
          placeholder='اكتب هنا للبحث عن طالب' 
          onChange={(e) => handleSearchStudent(e)} 
          className='shadow main-input border w-100' 
          style={{width: '80%',margin: '0px auto !important'}}
          />
           {/* <input type='number' {...register("student_id", { required: true })} className='shadow w-100 main-input border-0'/> */}
     
           {/* <select type='text' {...register("student_id", { required: true })} ref={studentsSelect}  className='shadow w-100 p-2 border-0'> */}
            <div className='students_select'>   
                <ul className='unorder_students_list' ref={unOrderStudentsList} >
                        {
                            searchResult && searchResult?.map((student,index) => {
                                return   <li 
                                        onClick={() => handleSelectStudentFromDrobDownList(student)} 
                                        value={student.student_id} 
                                        key={index}
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        >
                                            {student.name}
                                        </li>
                            })
                        }
                    </ul>

                    
        {
            selectedStudent && <div className='text-center mt-4'>
            <button className='btn btn-primary' onClick={handleAddStudent}>أضف {selectedStudent.name} للقسم {state?.sectionName}</button>
       </div>
        }
        
            </div>

        </div>

          {/* <div className='d-flex mb-3 justify-content-center border w-auto dot p-4 flex-column align-items-center mt-4 '>
               <h5 className='gre'>أضف قسم للمكتبة</h5> 
               <NavLink to='/addlibrarySection'>
               <img src={group} className="me" />
               </NavLink>
        </div>  */}


        <div className='d-flex justify-content-around mb-1'>



        {
          <LibraryStudents data={data?.data} isLoading={isLoading}/>
        }

          </div>
          {data?.data.length <= 15  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }  
          
            


        </div>

  
    </Layout>
}

export default Guirdians;