import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import './styleTable.css';

export default function LibraryStudents({ data, isLoading }) {
	const navigate = useNavigate();
	const [currentSections, setCurrentSections] = useState([]);
	const [selectedteacherId, setSelectedTeacherId] = useState();
	const modalRef = useRef(null);
	const closeModalBtn = useRef();
	const [Isloading, setIsLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const params = useParams();

	useEffect(() => {

		// console.log('data from react query',data?.data);
		setCurrentSections(data?.data);

	}, [data]);

	const handleDeleteTeacher = (e, id) => {
		e.stopPropagation();
		// console.log("id guardian to delete is => " , id);

		setIsLoading(true);
		// modalRef.current.style.display = 'none';
		// closeModalBtn.current.click();
		const parmasToPost = {
			library_section_id: params.sectionId,
			student_id: id,
		};
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/delete_student_library_section`,
				parmasToPost,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log(data);

				if (data.status == 200) {
					setSuccessMessage('تمت  عملية الحذف بنجاح');

					setTimeout(() => {
						window.location.reload();
					}, 1000);

					// modalRef.current.style.display = 'none';
					// closeModalBtn?.current?.click();

					setIsLoading(false);

					const newSections = currentSections.filter(
						(currentSection) => currentSection.id != id
					);

					setCurrentSections([...newSections]);
				}
			})
			.catch((error) => {
				// modalRef.current.style.display = 'none';
				// closeModalBtn.current.click();

				setErrorMessage('حدث خطأ ما, يرجى المحاولة لاحقا');
				setIsLoading(false);
				console.log(error);
			})
			.finally(() => {
				modalRef.current.style.display = 'none';
				closeModalBtn?.current?.click();
			});
	};

	const handleEditLibrarySection = (e, id) => {
		e.stopPropagation();

		navigate(`/editlibrarysection/${id}`);
	};

	const selectedTeacherId = (id) => {
		console.log('id', id);
		setSelectedTeacherId(id);
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='  text-white fw-bold'>
						<th className='text-center text-white firdt_child'> اسم الطالب</th>
						{/* <th className='text-center text-white'> السعر</th> 
                <th className='text-center text-white'> مدفوع</th>  */}
						{/* <th className='text-center text-white'> رقم الهاتف</th>  */}
						<th className='text-center text-white last_child'>
							العمليات المتاحة
						</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={2}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : currentSections && currentSections.length > 0 ? (
						currentSections.map((section, index) => {
							return (
								<tr
									key={index}
									onClick={() => {
										navigate(`/librarySection/${section.id}`);
									}}
									style={{
										cursor: 'pointer',
										borderBottom: '1px solid #1a1a1a1a',
									}}
									className='tableRowHover'>
									<td className='text-center'>{section.student_name}</td>
									{/* <td  className='text-center'>{section.price}</td>                       
                        <td  className='text-center'>{section.isfree != 0 ? 'نعم' : "لا"}</td>                        */}
									{/* <td  className='text-center'>{section.phone_number}</td>    */}
									<td className='text-center'>
										<button
											type='button'
											className='btn  btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={(e) => {
												e.stopPropagation();
												selectedTeacherId(section.student_id);
											}}>
											حذف
										</button>

										{/* <button 
                            onClick={(e) => handleEditLibrarySection(e,section.id)} 
                            className='btn  btn1'>
                                تعديل  
                            </button> */}

										{/* <Link 
                            to={`/librarySectionStudents/${section.id}`} 
                            className='btn btn-success'>
                                الطلاب  
                            </Link> */}
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={2} className='text-center py-5 '>
							<div>
								<h5>لا يوجد طلاب مشتركين في هذا القسم</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				ref={modalRef}
				id='exampleModal'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title mx-auto' id='exampleModalLabel'>
								{Isloading ? 'جاري الحذف ...' : 'هل أنت متأكد ؟'}
							</h5>
						</div>

						{Isloading && (
							<div className='modal-body'>
								{Isloading && (
									<div className='mb-3 d-flex justify-content-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='visually-hidden'>Loading...</span>
										</div>
									</div>
								)}
							</div>
						)}

						{!Isloading && (
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									className='btn btn-secondary'
									data-bs-dismiss='modal'
									ref={closeModalBtn}>
									تجاهل
								</button>

								<button
									type='button'
									className='btn  btn2'
									data-bs-dismiss={Isloading ? '' : 'exampleModal'}
									onClick={(e) => handleDeleteTeacher(e, selectedteacherId)}>
									حذف
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
