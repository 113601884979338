import React, { useEffect, useRef , useState } from 'react'
import axios from "axios";

import { useDispatch } from 'react-redux';
import { getImage } from '../../redux-toolkit/reducers/GetImage/GetImage';
import { useNavigate } from 'react-router-dom';



export  default function SubjectLogic(subscribtionPage,id,reset,idTeacher,subSectionId, setSuccessMessage,setErrorMessage ,setSuccess , setFaild) {


  console.log("teacher idddd" , idTeacher);
console.log("subSection iddd"  , subSectionId);
//remove the image from input file
  const [AddSubscribtion , setAddSubscribtion] = useState(false);
  const [progress,setProgress] = useState(0);
  const [IsLoading,setIsLoading] = useState(false);

  const navigate = useNavigate()

  console.log("iddd",id);
  const tempId = id || 0
  console.log("tempIDDsdfdsfjhj",tempId);
      const [valid , SetValid] = useState(false);
    const bach=useRef(null);
    const bache=useRef(null);
    const bachee=useRef(null);

  
    useEffect(()=>{
             setAddSubscribtion(false);
    },[])      
    
    

    const handleSubmitApi=(data)=>{


if(id){

  console.log("Update")
  let formData = new FormData();
  setIsLoading(true);
  // setImage(data.image[0]);
  // console.log("image Select Logic",data.cover[0]);
  // console.log("video",data.introductory_video[0])

  // dispatch(getImage(data.subscription_image[0]));
  
  if(typeof data.subject_image !=="string")
  {
  console.log("hereer image push");
  formData.append("cover",data.subject_image[0])
  
  }
  console.log('type of video', data.introductory_video[0])
  if(data.introductory_video[0] ){
    formData.append("introductory_video" , data.introductory_video[0]);
  }
  console.log('points poisnt', data.points)
  formData.append("name",data.subject_name)
  if(data.points){
    
    formData.append("points" , data.points);
  }
  formData.append("points" , 10);
  formData.append("requirements" , data.subject_req);
  formData.append("sub_section_id" , subSectionId);
  formData.append("transable" , 0);
  formData.append('teacher_percentage', data.teacher_percentage);
  if(idTeacher){

    formData.append("teacher_id", idTeacher)
  }
  formData.append("price" , data.price);
  formData.append("description" ,data.subject_description);


  for(var pair of formData.entries()) { 
    console.log("form Data", pair[0]+ ', '+ pair[1]);  
}

  console.log("tempIDdddddddd",id);
  //  formData.append("subscription_id" ,id);
   const res = axios.post(`${process.env.REACT_APP_API_URL}/update_subject_by_subject_id/${id}`, formData, {
     headers: {
       'content-type': 'text/json',
       "Content-Type": "multipart/form-data",
       "Accept": "application/json",
       'Content-Encoding': 'gzip , deflate, br',
       "Authorization": `Bearer ${localStorage.getItem('token')}`
     },
  
     onUploadProgress: (progressEvent) => {
       const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
       console.log(progress);
       setProgress(progress);
     },
   }).then(res=>{
     if(res.status===200){
       const {data} = res;
       SetValid(true);
       setIsLoading(false);
       console.log("data EDIT Subscribtion" , data);
       setSuccessMessage("تم تعديل معلومات الكورس بنجاح")
       navigate(-1)
       
      //  window.location.reload();
       return res;
     }
     else
     {
       console.log(res);
       setIsLoading(false);
       setErrorMessage("غير متصل بالشبكه");
       throw new Error('there is an error')
     }
   }).catch(error=>{
    // setErrorMessage(error.message);
    setIsLoading(false);
    setErrorMessage(error.response.data.message);
     console.log(error.response)
   });
 

}else{


      console.log("teacher idddd" , idTeacher);
      console.log("subSection iddd"  , subSectionId);


      console.log("submit")
      let formData = new FormData();
      setIsLoading(true);
      // setImage(data.image[0]);
      console.log("dataaaaaaa" , data);
      console.log("image Select Logic", data.subject_image[0]);
      console.log("video introductory video", data.introductory_video[0]);
      console.log("name",data.subject_name)

      // dispatch(getImage(data.subscription_image[0]));
      
      formData.append("cover", data.subject_image[0])
      formData.append("name",data.subject_name)
      formData.append('teacher_percentage', data.teacher_percentage)
      formData.append('discount_percentage', data.discount_percentage)
      formData.append('previous_price', data.previous_price)
      if(data.points) {

        formData.append("points" , data.points);
      }
      formData.append("points" , 10);

      console.log('type of video', data.introductory_video[0])
      if(data.introductory_video[0] ){
        formData.append("introductory_video" , data.introductory_video[0]);

      }
      formData.append("requirements" , data.subject_req);
      formData.append("sub_section_id" , subSectionId);
      formData.append("transable" , 0);
      formData.append("teacher_id", idTeacher)
      formData.append("price" , data.price);
      formData.append("description" ,data.subject_description );

    // console.log('hello',formData)
    for(var pair of formData.entries()) { 
      console.log("form Data", pair[0]+ ', '+ pair[1]);  
 }

 const res = axios.post(`${process.env.REACT_APP_API_URL}/add_subject` , formData, {
  headers: {
    'content-type': 'text/json',
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    'Content-Encoding': 'gzip , deflate, br',
    "Authorization": `Bearer ${localStorage.getItem('token')}`
  },
  
  onUploadProgress: (progressEvent) => {
    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    console.log(progress);
    setProgress(progress);
    setIsLoading(true);
  },
}).then(res=>{
  if(res.status===200){
    const {data} = res;
    SetValid(true);
    reset({subscription_name:'',
   subscription_price:'',
   subscription_description:'',
   subscription_image:null})


  setIsLoading(false);

   setAddSubscribtion(true);
   setSuccessMessage("تم إضافة الكورس بنجاح")
    console.log("data Post Subscribtion" , data);
    navigate(-1)

    return res;
  }
  else
  {
    setIsLoading(false);
    console.log(res);
    setErrorMessage("غير متصل بالشبكه");
    throw new Error('there is an error')
  }
}).catch(error=>{
  setIsLoading(false);
  console.log(error.response)
  // setErrorMessage(error.message);
  setErrorMessage(error.response.data.message);
  //  setIsError(error.response.data)
});

}


  };

  const changeColor = (progress) => {
    if(progress <= 25){
        return '#ff0707';
    }else if(progress <= 50){
        return '#ff6907';
    }else if(progress <= 75){
        return '#ffdb07';
    }else {
        return '#77ff07';
    }
  }


  return {
          valid,handleSubmitApi,
          bach,bache,bachee,AddSubscribtion,progress,IsLoading,changeColor
  }
}
