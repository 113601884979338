import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './styleTable.css';
import right from '../../assets/students/right.png';
import wrong from '../../assets/students/wrong.jpg';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

export default function UnActiveStudentsTable({ data, isLoading }) {
	const navigate = useNavigate();
	const [currentTeachers, setCurrentTeachers] = useState([]);
	const [Isloading, setIsLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [selectedStudentId, setSelectedStudentId] = useState();
	const modalRef = useRef(null);
	const closeModalBtn = useRef();
	const [currentUnActiveAccounts, setCurrentUnActiveAccounts] = useState([]);

	useEffect(() => {
		console.log('data from react query', data?.data);
		setCurrentUnActiveAccounts(data?.data);
	}, [data]);

	const handleActiveStudentAccount = (e, id) => {
		e.stopPropagation();

		setSelectedStudentId(id);
	};

	const activateStudentAccount = () => {
		setIsLoading(true);

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/active_student_account_by_id/${selectedStudentId}`,
				{},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((response) => {
				if (response.status == 200) {
					setSuccessMessage('تم تفعيل الحساب بنجاح');

					// modalRef.current.style.display = 'none';
					// closeModalBtn.current.click();

					const newCurrentUnActiveAccounts = currentUnActiveAccounts.filter(
						(currentStudent) => currentStudent.id != selectedStudentId
					);
					setCurrentUnActiveAccounts([...newCurrentUnActiveAccounts]);
					setIsLoading(false);
					console.log('data after activate student account', response.data);
				}
			})
			.catch((error) => {
				setErrorMessage(error.response.data.message);
				setIsLoading(false);
				console.log('error white activating students', error);
			})
			.finally(() => {
				modalRef.current.style.display = 'none';
				closeModalBtn.current.click();
			});
	};

	const handleLoginAsStudent = (event, student) => {
		setIsLoading(true);
		event.stopPropagation();

		const dataToSend = {
			id: student.student_id,
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/login_with_student_id`,
				dataToSend,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('token is data ', data?.data.data.token);

				setSuccessMessage(
					'تمت العملية بنجاح .. سيتم توجيهك لحساب الطالب في غضون ثوان'
				);

				setTimeout(() => {
					window.open(
						`https://mid-east.icrcompany.net/loginasstudent/${data?.data.data.token}/${student.is_active}`
					);
				}, 15000);
			})
			.catch((error) => {
				console.log('error login as teacher', error);
				setErrorMessage('حدثت مشكلة ما .. يرجى المحاولة مرة اخرى');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={ErrorMessage}
				setSuccessMessage={setSuccessMessage}
				setErrorMessage={setErrorMessage}
			/>

			<table
				className='table table_style  mt-3   text-center  table-bordered  table-striped'
				style={{ minHeight: '200px' }}>
				<thead>
					<tr className='text-white fw-bold firdt_child'>
						<th className='text-center text-white'> الاسم</th>
						<th className='text-center text-white'> اسم المستخدم</th>
						<th className='text-center text-white'> النقاط</th>
						<th className='text-center text-white'>المدرسة</th>
						<th className='text-center text-white'> الصف</th>
						<th className='text-center text-white'> الايميل</th>
						<th className='text-center text-white last_child'>
							العمليات المتاحة
						</th>
					</tr>
				</thead>
				<tbody style={{ minHeight: '200px' }}>
					{isLoading ? (
						<td className='py-5' colSpan={7}>
							<div
								class='spinner-border '
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 'auto',
								}}
								role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</td>
					) : currentUnActiveAccounts && currentUnActiveAccounts.length > 0 ? (
						currentUnActiveAccounts.map((student, index) => {
							return (
								<tr
									key={index}
									onClick={() => {
										navigate(`/student/${student.student_id}`);
									}}
									style={{
										height: '50px',
										cursor: 'pointer',
										borderBottom: '1px solid #1a1a1a1a',
									}}
									className='tableRowHover'>
									<td className='text-center py-5'>{student.name}</td>
									<td className='text-center py-5'>{student.username}</td>
									<td className='text-center py-5'>{student.points}</td>
									<td className='text-center py-5'>{student.school}</td>
									<td className='text-center py-5'>{student.class}</td>
									<td className='text-center py-5'>{student.email}</td>
									{/* <td  className='text-center pt-4'>{student.phone_number}</td>    */}
									<td className='text-center  pb-2  activatOrNot'>
										<button
											type='button'
											className='btn btn2 shadow'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={(e) =>
												handleActiveStudentAccount(e, student.student_id)
											}>
											تفعيل الحساب
										</button>

										<div className='d-flex flex-column ' style={{ gap: '8px' }}>
											<button
												className='btn btn1 shadow'
												onClick={(e) => {
													e.stopPropagation();
													navigate(`/editstudentdetails/${student.student_id}`);
												}}>
												تعديل
											</button>

											<button
												className='btn btn-success shadow'
												disabled={Isloading}
												onClick={(e) => handleLoginAsStudent(e, student)}>
												تسجيل الدخول بحساب الطالب
											</button>
										</div>
									</td>
								</tr>
							);
						})
					) : (
						<td colSpan={7} className='text-center py-5 '>
							<div>
								<h5>ليس هنالك اي حسابات غير مفعلة</h5>
							</div>
						</td>
					)}
				</tbody>
			</table>

			<div
				className='modal fade'
				id='exampleModal'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'
				ref={modalRef}>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title mx-auto' id='exampleModalLabel'>
								{Isloading ? 'جاري تفعيل الحساب ...' : 'هل أنت متأكد ؟'}
							</h5>
						</div>

						{Isloading && (
							<div className='modal-body'>
								{Isloading && (
									<div className='mb-3 d-flex justify-content-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='visually-hidden'>Loading...</span>
										</div>
									</div>
								)}
							</div>
						)}

						{!Isloading && (
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									className='btn btn1'
									data-bs-dismiss='modal'
									ref={closeModalBtn}>
									تجاهل
								</button>

								<button
									type='button'
									className='btn btn2'
									onClick={(e) => activateStudentAccount(e, selectedStudentId)}>
									تفعيل
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
