import React, { Component } from 'react';
// import ICR from '../assets/icr/';
import ICR from './assets/icr/icr.png';
function Footer() {
	const getClickableLink = (link) => {
		return link.startsWith('http://') || link.startsWith('https://')
			? link
			: `http://${link}`;
	};

	return (
		<div className='row mx-0 mt-5 mb-3' style={{ justifyContent: 'center' }}>
			<div className='col-12  col-sm-12 col-md-12  col-lg-6 text-center'>
				<div className=' mb-1 d-flex justify-content-center align-items-center '>
					<a
						style={{ textDecoration: 'none' }}
						className='d-flex justify-content-center align-items-center text-center'
						href='https://www.facebook.com/ICRCompanyPro'>
						<strong className='text-center fs-5 mt-2 '>
							<p className='mt-2 text-center' style={{ color: '#003154' }}>
								تم تصميم وتطوير المنصة بواسطة
							</p>
						</strong>
						<img src={ICR} className='' with='80' height='80' />
					</a>
				</div>
				<h5>
					<a
						href={getClickableLink('ideacodereality.icr@gmail.com')}
						className='f-bold text-black fs-5  '
						style={{ textDecoration: 'none' }}>
						<p className='my-2'>للتواصل :</p> IdeaCodeReality.ICR@gmail.com
					</a>
				</h5>
				<div className='row justify-content-center mt-4  mr-2 text-center text-dark'>
					<span className=' col-12  ' style={{ fontSize: '1.1rem' }}>
						All rights reserved Copyright © 2022 @ ICR
					</span>
				</div>
			</div>
		</div>

		// <div
		// 	className='d-flex justify-content-center align-items-center'
		// 	style={{
		// 		marginTop: '200px' /* Height of the footer */,
		// 	}}>
		// 	<footer
		// 		className='main-footer d-flex align-items-center justify-content-center flex-column'
		// 		style={{ borderTop: '0', background: 'transparent', color: 'white' }}>
		// 		<strong className='text-center fs-2 '>
		// 			Copyright © 2022{' '}
		// 			<a
		// 				href=''
		// 				className='f-bold text-black fs-2 '
		// 				style={{ textDecoration: 'none' }}>
		// 				ICR
		// 			</a>{' '}
		// 		</strong>
		// 		<div className='fs-3'>All rights reserved</div>
		// 	</footer>
		// </div>
	);
}

export default Footer;
